import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { refreshToken } from "../functions";

const initialRideState = {
  allRides: [],
  ongoingRides: [],
  completedRides: [],
  reportedRides: [],
  selectedRideId: "",
  userRides: [],
  selectedRideObject: {},
  fecthAllRideStatus: "idle",
  fetchAllRidesByUserStatus: "idle",
  rideImagesBefore: [],
  fetchBeforeImagesStatus: "idle",
  rideById: {},
  fetchRideByIdStatus: "idle",
  rideTransactionDetails: [],
  rideTransactionDetailsStatus: "idle",
  reportedRides2: [],
  fetchReportedRideStatus: "idle",
  reportedRideById: [],
  fetchReportedRideByIdStatus: "idle",
  selectedReportId: "",
  selectedCoords: "",
  reportedRideImages: "",
  fetchReportedRideImagesStatus: "idle",
};

export const fetchReportedRide = createAsyncThunk(
  "rideManagement/fetchReportedRide",
  async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(
      `https://api.symboldrive.com/report-issue/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.status === true) {
          return res;
        }

        if (
          res.status === "error" ||
          res.error.code === "auth/id-token-expired"
        ) {
          refreshToken();
        }
      });
  }
);

export const fetchReportedRideImages = createAsyncThunk(
  "rideManagement/fetchReportedRideImages",
  async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(
      `https://api.symboldrive.com/report-issue?bookingId=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.status === true) {
          return res;
        }

        if (
          res.status === "error" ||
          res.error.code === "auth/id-token-expired"
        ) {
          refreshToken();
        }
      });
  }
);

export const fetchRideById = createAsyncThunk(
  "rideManagement/fetchRideById",
  async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(`https://api.symboldrive.com/booking/${id}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.status === true) {
          return res;
        }

        if (
          res.status === "error" ||
          res.error.code === "auth/id-token-expired"
        ) {
          refreshToken();
        }
      });
  }
);

export const fetchReportedRides = createAsyncThunk(
  "rideManagement/fetchReportedRides",
  async (filter) => {
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(`https://api.symboldrive.com/report-issue`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.status === true) {
          return res;
        }

        if (
          res.status === "error" ||
          res.error.code === "auth/id-token-expired"
        ) {
          refreshToken();
        }
      });
  }
);

export const fetchAllRidesbyUser = createAsyncThunk(
  "rideManagement/getAllRidesbyUser",
  async (id) => {
    var myHeaders = new Headers();

    myHeaders.append("token", Cookies.get("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(
      `https://api.symboldrive.com/booking/all/admin?user=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.status === true) {
          return res;
        }
        if (
          // res.status === "error" ||
          res.error.code === "auth/id-token-expired"
        ) {
          refreshToken();
        }
      });
  }
);

export const fetchTransactionDetails = createAsyncThunk(
  "rideManagement/fetchTransactionDetails",
  async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(
      `https://api.symboldrive.com/transactions?booking=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.status === true) {
          return res;
        }

        if (
          res.status === "error" ||
          res.error.code === "auth/id-token-expired"
        ) {
          refreshToken();
        }
      });
  }
);

export const fetchBeforeImages = createAsyncThunk(
  "rideManagement/fetchBeforeImages",
  async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(
      `https://api.symboldrive.com/booking/all/admin?user=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.status === true) {
          return res;
        }

        if (
          // res.status === "error" ||
          res.error.code === "auth/id-token-expired"
        ) {
          refreshToken();
        }
      });
  }
);

export const fetchAllRides = createAsyncThunk(
  "rideManagement/fetchAllRides",
  async (filter, pageNumber) => {
    var myHeaders = new Headers();

    myHeaders.append("token", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    if (filter.toString() !== "ndefined") {
      return fetch(
        "https://api.symboldrive.com/booking/all/admin?status=" + filter,
        requestOptions
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.status === true) {
            return res;
          }

          if (
            res.status === "error" ||
            res.error.code === "auth/id-token-expired" ||
            res.error.code === "auth/argument-error"
          ) {
            refreshToken();
          }
        });
    } else if (filter.toString() === "ndefined") {
      return fetch(
        // "https://api.symboldrive.com/booking/all/admin?page=1&limit=10",
        `https://api.symboldrive.com/booking/all/admin?page=${pageNumber}&limit=10`,

        requestOptions
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.status === true) {
            return res;
          }

          if (
            res.status === "error" ||
            res.error.code === "auth/id-token-expired" ||
            res.error.code === "auth/argument-error"
          ) {
            refreshToken();
          }
        });
    }
  }
);

const rideSlice = createSlice({
  name: "rideSlice",
  initialState: initialRideState,
  reducers: {
    setAllRides(state, action) {
      state.allRides = action.payload;
    },
    setOnGoingRides(state, action) {
      state.ongoingRides = action.payload;
    },
    setCompletedRides(state, action) {
      state.completedRides = action.payload;
    },
    setReportedRides(state, action) {
      state.reportedRides = action.payload;
    },
    setSelectedRideObject(state, action) {
      state.selectedRideObject = action.payload;
    },
    setSelectedRideId(state, action) {
      state.selectedRideId = action.payload;
      state.selectedRideObject = state.allRides.filter(
        (item) => item._id === action.payload
      );
    },

    setSelectedReportedId(state, action) {
      state.selectedReportId = action.payload;
    },
    setAllRidesByUser(state, action) {
      state.userRides = action.payload;
    },
    setRideTransactionDetails(state, action) {
      state.rideTransactionDetails = action.payload;
    },
    setSelectedCoords(state, action) {
      state.selectedCoords = action.payload;
    },
  },
  extraReducers: {
    [fetchAllRides.pending]: (state, action) => {
      state.fecthAllRideStatus = "loading";
      state.allRides = [];
    },
    [fetchAllRides.fulfilled]: (state, { payload }) => {
      state.fecthAllRideStatus = "succeeded";
      state.allRides = payload.data;

      state.ongoingRides = payload.data.filter(
        (booking) =>
          booking.status === "ongoing" || booking.status === "inprogress"
      );

      state.completedRides = payload.data.filter(
        (booking) => booking.status === "completed"
      );
      state.reportedRides = payload.data.filter(
        (booking) => booking.status === "cancelled"
      );
    },
    [fetchAllRides.rejected]: (state, action) => {
      state.fecthAllRideStatus = "failed";
      state.allRides = [];
    },
    [fetchAllRidesbyUser.pending]: (state, action) => {
      state.fetchAllRidesByUserStatus = "loading";
    },
    [fetchAllRidesbyUser.fulfilled]: (state, { payload }) => {
      state.fetchAllRidesByUserStatus = "succeeded";
      state.userRides = payload.data;
    },
    [fetchAllRidesbyUser.rejected]: (state, action) => {
      state.fetchAllRidesByUserStatus = "failed";
    },
    [fetchBeforeImages.pending]: (state, action) => {
      state.fetchBeforeImagesStatus = "loading";
    },
    [fetchBeforeImages.fulfilled]: (state, { payload }) => {
      state.fetchBeforeImagesStatus = "succeeded";
      state.rideImagesBefore = payload?.data;
    },
    [fetchBeforeImages.rejected]: (state, action) => {
      state.fetchBeforeImagesStatus = "failed";
    },
    [fetchRideById.pending]: (state, action) => {
      state.fetchRideByIdStatus = "loading";
    },
    [fetchRideById.fulfilled]: (state, { payload }) => {
      state.fetchRideByIdStatus = "succeeded";
      state.selectedRideObject = [payload?.data];
    },
    [fetchRideById.rejected]: (state, action) => {
      state.fetchRideByIdStatus = "failed";
    },
    [fetchTransactionDetails.pending]: (state, action) => {
      state.rideTransactionDetailsStatus = "loading";
    },
    [fetchTransactionDetails.fulfilled]: (state, { payload }) => {
      state.rideTransactionDetailsStatus = "succeeded";
      state.rideTransactionDetails = payload?.data;
    },
    [fetchTransactionDetails.rejected]: (state, action) => {
      state.rideTransactionDetailsStatus = "failed";
    },
    [fetchReportedRides.pending]: (state, action) => {
      state.fetchReportedRideStatus = "loading";
    },
    [fetchReportedRides.fulfilled]: (state, { payload }) => {
      state.fetchReportedRideStatus = "succeeded";
      state.reportedRides2 = payload?.data;
    },
    [fetchReportedRides.rejected]: (state, action) => {
      state.fetchReportedRideStatus = "failed";
    },
    [fetchReportedRide.pending]: (state, action) => {
      state.fetchReportedRideByIdStatus = "loading";
    },
    [fetchReportedRide.fulfilled]: (state, { payload }) => {
      state.fetchReportedRideByIdStatus = "succeeded";
      state.reportedRideById = payload?.data;
    },
    [fetchReportedRide.rejected]: (state, action) => {
      state.fetchReportedRideByIdStatus = "failed";
    },

    [fetchReportedRideImages.pending]: (state, action) => {
      state.fetchReportedRideImagesStatus = "loading";
    },
    [fetchReportedRideImages.fulfilled]: (state, { payload }) => {
      state.fetchReportedRideImagesStatus = "succeeded";
      state.reportedRideImages = payload?.data;
    },
    [fetchReportedRideImages.rejected]: (state, action) => {
      state.fetchReportedRideImagesStatus = "failed";
    },

    fetchReportedRideImages,
  },
});

export const rideActions = rideSlice.actions;

export default rideSlice;
