import React from "react";
import NotificationsTable from "../../components/Notifications/table";
import { useDispatch, useSelector } from "react-redux";
import animate from "../../assets/images/animate.gif";
import { fetchAllNotifications } from "../../store/notifications";
import { refreshToken } from "../../functions";
import Cookies from "js-cookie";

const Notifications = () => {
  const dispatch = useDispatch();

  const status = useSelector(
    (state) => state.notifications.notificationFetchStatus
  );

  const reduceCount = async () => {
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      // body: raw,
      redirect: "follow",
    };

    fetch(`https://api.symboldrive.com/notification/mark/read`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (
          res.status === "false" &&
          res.error.code === "auth/id-token-expired"
        ) {
          refreshToken();
        }
      });
  };

  React.useEffect(() => {
    dispatch(fetchAllNotifications());
    reduceCount();
  }, [dispatch]);

  return (
    <>
      <>
        {status === "loading" ? (
          <div className="loader-container">
            <img alt="loading" src={animate} />
          </div>
        ) : (
          <>
            <NotificationsTable />
          </>
        )}
      </>
    </>
  );
};

export default Notifications;
