import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import NewCustomers from "./pages/User Management/NewCustomers";
import SuspendedCustomers from "./pages/User Management/SuspendedCustomers";
import SuspendedCustomer from "./pages/User Management/SuspendedCustomer";
import ActiveCustomers from "./pages/User Management/ActiveCustomers";
import ListedVehicles from "./pages/Car Management/ListedVehicles";
import DamagedVehicles from "./pages/Car Management/DamagedVehicles";
import AllRides from "./pages/Ride Management/AllRides";
import CompletedRide from "./pages/Ride Management/CompletedRide";
import ReportedRide from "./pages/Ride Management/ReportedRide";
import CompletedPayment from "./pages/Payments/CompletedPayment";
import ActiveCustomer from "./pages/User Management/ActiveCustomer";
import NewCustomer from "./pages/User Management/NewCustomer";
import ListedVehicle from "./pages/Car Management/ListedVehicle";
import DamagedVehicle from "./pages/Car Management/DamagedVehicle";
import OnGoingRideDetails from "./pages/Ride Management/OnGoingRideDetails";
import CompletedRideDetails from "./pages/Ride Management/CompletedRideDetails";
import ReportedRideDetails from "./pages/Ride Management/ReportedRideDetails";
import Sidebar from "./components/sidebar/Sidebar";
import AddVehicle from "./pages/Car Management/AddVehicle";
import AddCategory from "./pages/Car Management/AddCategory";
import GeoFencingContainer from "./pages/Geo Fencing/GeoFencingContainer";
import BirdEyeContainer from "./pages/Geo Fencing/BirdEyeContainer";
import ParkingContainer from "./pages/Geo Fencing/ParkingContainer";
import Notifications from "./pages/Notifications/Notifications";
import CompletedPaymentIndividual from "./pages/Payments/CompletedPaymentIndividual";
import OnGoingRides from "./pages/Ride Management/OnGoingRides.js";
import UserRideDetails from "./pages/Ride Management/UserRideDetails";
import FilteredCars from "./pages/Dashboard/FilteredCars";
import NotificationIndividual from "./pages/Notifications/NotificationIndividual.js";

function RouteEnum() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/">
          <Route
            index
            element={
              <Sidebar>
                <Dashboard />{" "}
              </Sidebar>
            }
          />
        </Route>

        <Route path="/notifications">
          <Route
            index
            element={
              <Sidebar>
                <Notifications />
              </Sidebar>
            }
          />
        </Route>

        <Route path="/notification/:notificationId">
          <Route
            index
            element={
              <Sidebar>
                <NotificationIndividual />
              </Sidebar>
            }
          />
        </Route>

        <Route path="/users">
          <Route
            index
            element={
              <Sidebar>
                <NewCustomers />
              </Sidebar>
            }
          />
          {/* <Route path="users:userId" element={<NewCustomer />} /> */}
          <Route
            path="newUsers"
            element={
              <Sidebar>
                <NewCustomers />
              </Sidebar>
            }
          />

          <Route
            path="newUsers/:userId"
            element={
              <Sidebar>
                <NewCustomer />
              </Sidebar>
            }
          />
          <Route
            path="newUsers"
            element={
              <Sidebar>
                <NewCustomers />
              </Sidebar>
            }
          />
          <Route
            path="activeUsers/:userId"
            element={
              <Sidebar>
                <ActiveCustomer />
              </Sidebar>
            }
          />
          <Route
            path="activeUsers"
            element={
              <Sidebar>
                <ActiveCustomers />
              </Sidebar>
            }
          />
          <Route
            path="suspendedUsers"
            element={
              <Sidebar>
                <SuspendedCustomers />
              </Sidebar>
            }
          />
          <Route
            path="suspendedUsers/:userId"
            element={
              <Sidebar>
                <SuspendedCustomer />
              </Sidebar>
            }
          />
        </Route>

        <Route path="/carManagement">
          <Route
            index
            element={
              <Sidebar>
                <AddCategory />
              </Sidebar>
            }
          />

          <Route
            path=":filter"
            element={
              <Sidebar>
                <FilteredCars />
              </Sidebar>
            }
          />
          <Route
            path="addCategory"
            element={
              <Sidebar>
                <AddCategory />
              </Sidebar>
            }
          />

          <Route
            path="listedVehicles"
            element={
              <Sidebar>
                <ListedVehicles />
              </Sidebar>
            }
          />

          {/* <Route
            path="filteredCars:filter"
            element={
              <Sidebar>
                <FilteredCars />
              </Sidebar>
            }
          /> */}
          <Route
            path="listedVehicle/:carId"
            element={
              <Sidebar>
                <ListedVehicle />
              </Sidebar>
            }
          />
          <Route
            path="addVehicle"
            element={
              <Sidebar>
                <AddVehicle />
              </Sidebar>
            }
          />

          <Route
            path="listedVehicles/:vehicleId"
            element={
              <Sidebar>
                <ListedVehicle />
              </Sidebar>
            }
          />

          <Route
            path="damagedVehicle/:vehicleId"
            element={
              <Sidebar>
                <DamagedVehicle />
              </Sidebar>
            }
          />
          <Route
            path="damagedVehicles"
            element={
              <Sidebar>
                <DamagedVehicles />
              </Sidebar>
            }
          />
        </Route>

        <Route path="/rideManagement">
          <Route
            index
            element={
              <Sidebar>
                <AllRides />
              </Sidebar>
            }
          />

          <Route
            path=":filter"
            element={
              <Sidebar>
                <AllRides />
              </Sidebar>
            }
          />
          {/* 
          <Route
            path="allRides"
            element={
              <Sidebar>
                <AllRides />
              </Sidebar>
            }
          /> */}

          <Route
            path="ongoingRides"
            element={
              <Sidebar>
                <OnGoingRides />
              </Sidebar>
            }
          />

          <Route
            path="rideDetails/:rideId"
            element={
              <Sidebar>
                <UserRideDetails />
              </Sidebar>
            }
          />

          <Route
            path="ongoingRide/:rideId"
            element={
              <Sidebar>
                <OnGoingRideDetails />
              </Sidebar>
            }
          />

          <Route
            path="completedRide"
            element={
              <Sidebar>
                <CompletedRide />
              </Sidebar>
            }
          />
          <Route
            path="completedRide/:rideId"
            element={
              <Sidebar>
                <CompletedRideDetails />
              </Sidebar>
            }
          />

          <Route
            path="reportedRide"
            element={
              <Sidebar>
                <ReportedRide />
              </Sidebar>
            }
          />
          <Route
            path="reportedRide/:rideId"
            element={
              <Sidebar>
                <ReportedRideDetails />
              </Sidebar>
            }
          />
        </Route>
        <Route path="/payments">
          <Route
            index
            element={
              <Sidebar>
                <CompletedPayment />
              </Sidebar>
            }
          />
          <Route
            path="payments/:paymentId"
            element={
              <Sidebar>
                <CompletedPaymentIndividual />
                {/* <CompletedPayment /> */}
              </Sidebar>
            }
          />
          {/* <Route
            path="disputedPayment"
            element={
              <Sidebar>
                <DisputedPayment />
              </Sidebar>
            }
          /> */}
        </Route>
        <Route path="/geoFencing">
          <Route
            index
            element={
              <Sidebar>
                <GeoFencingContainer />
              </Sidebar>
            }
          />
          <Route
            path="geoFencingList"
            element={
              <Sidebar>
                <GeoFencingContainer />
              </Sidebar>
            }
          />
          <Route
            path="addParking"
            element={
              <Sidebar>
                <BirdEyeContainer />
              </Sidebar>
            }
          />

          <Route
            path="parking"
            element={
              <Sidebar>
                <ParkingContainer />
              </Sidebar>
            }
          />
        </Route>
      </Routes>
      {/* )} */}
    </Router>
  );
}

export default RouteEnum;
