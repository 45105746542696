import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { refreshToken } from "../functions";

const intialPaymentState = {
  allPayments: [],
  fetchAllPaymentResponse: "idle",
  selectedPaymentId: [],
  selectedPaymentObject: [],
  getPaymentById: [],
  getPaymentByIdResponse: "idle",
};

export const fetchAllPayments = createAsyncThunk(
  "payments/fetchAllPayments",
  async () => {
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));

    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    return fetch("https://api.symboldrive.com/transactions", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.status === true) {
          return res;
        }
        if (
          res.status === "error" ||
          res.error.code === "auth/id-token-expired"
        ) {
          refreshToken();
        }
      });
  }
);

export const fetchPaymentById = createAsyncThunk(
  "payments/fetchPaymentById",
  async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));

    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch(
      `https://api.symboldrive.com/transactions?_id=${id}`,
      requestOptions
    ).then((response) => response.json());
  }
);

const paymentSlice = createSlice({
  name: "payments",
  initialState: intialPaymentState,
  reducers: {
    setAllPayments(state, action) {
      state.allPayments = action.payload;
    },
    setSelectedPaymentId(state, action) {
      state.selectedPaymentId = action.payload;
      state.selectedPaymentObject = state.allPayments.filter(
        (item) => item._id === action.payload
      );
    },
  },
  extraReducers: {
    [fetchAllPayments.pending]: (state, action) => {
      state.fetchAllPaymentResponse = "loading";
    },
    [fetchAllPayments.fulfilled]: (state, { payload }) => {
      state.fetchAllPaymentResponse = "succeeded";
      state.allPayments = payload.data;
    },
    [fetchAllPayments.failed]: (state, action) => {
      state.fetchAllPaymentResponse = "failed";
    },
    [fetchPaymentById.pending]: (state, action) => {
      state.getPaymentByIdResponse = "loading";
    },
    [fetchPaymentById.fulfilled]: (state, action) => {
      state.getPaymentByIdResponse = "succeeded";
      state.selectedPaymentObject = [action.payload.data];
    },
    [fetchPaymentById.failed]: (state, action) => {
      state.getPaymentByIdResponse = "failed";
    },
  },
});

export const paymentActions = paymentSlice.actions;

export default paymentSlice;
