import React, { useMemo, useState } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import { GlobalFilter } from "./filter";
import chevRightRed from "../../../assets/icons/chevRightBlue.png";
import chevLeftRed from "../../../assets/icons/chevLeftBlue.png";
import eye from "../../../assets/icons/eye2.png";
import suspend from "../../../assets/icons/cancelCross.png";
import styles from "./style.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../../store/users";
import userAlt from "../../../assets/images/user.svg";

const ActiveCustomerTable = ({ openModal, setOpenModal }) => {
  const activeUserData = useSelector((state) => state.users.activeUsers);
  const dispatch = useDispatch();

  function capitalizeName(name) {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  const checkEmptySpace = (string) => {
    if (string === "") {
      return "--";
    } else {
      return string;
    }
  };

  const onImageError = (ev) => {
    ev.target.src = userAlt;
  };

  const navigate = useNavigate();
  const data = useMemo(() => activeUserData, []);

  const onViewClick = (id) => {
    dispatch(userActions.setSelectedUserId(id));
    navigate(`/users/activeUsers/:${id}`);
  };

  const onSuspendClick = (id) => {
    dispatch(userActions.setSelectedUserId(id));
    setOpenModal(true);
  };

  const COLUMNS_NEWCUSTOMER = [
    {
      Header: () => <p className={styles.headerID}>User ID</p>,
      accessor: "_id",
      Cell: (tableProps) => (
        <p className={styles.id}>
          {String(tableProps.row.original._id).slice(-4)}
        </p>
      ),
    },
    {
      Header: "User name",
      accessor: "name",
      Cell: (tableProps) => (
        <div className="nameAndImage">
          <img onError={onImageError} src={tableProps.row.original.image} />
          <p
            className="nameBox"
            // onClick={() => onRowClick(tableProps.row.original.name)}
          >
            {capitalizeName(tableProps.row.original.name)}
          </p>
        </div>
      ),
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: (tableProps) => (
        <p className={styles.id}>
          {tableProps.row.original.phone
            ? checkEmptySpace(tableProps.row.original.phone)
            : "Not provided"}
        </p>
      ),
    },

    {
      Header: "No. of rides",
      accessor: "numOfRides",
      Cell: (tableProps) => (
        <p>{checkEmptySpace(tableProps.row.original.noOfRides)}</p>
      ),
    },
    {
      Header: "Address",
      accessor: "address",
      Cell: (tableProps) => (
        <p className="address">
          {checkEmptySpace(tableProps.row.original.address)}
        </p>
      ),
    },

    {
      Header: "Actions",
      accessor: "actions",
      Cell: (tableProps) => (
        <div className="actionsCategories">
          <button
            className="btn-edit"
            onClick={() => onViewClick(tableProps.row.original._id)}
          >
            <img src={eye} alt="View" />
          </button>

          <button
            className="btn-edit"
            onClick={() => onSuspendClick(tableProps.row.original._id)}
          >
            <img className="red-cross" src={suspend} alt="Suspend" />
          </button>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS_NEWCUSTOMER, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  return (
    <div className="containerSubRight">
      <div className="heading-container">
        <h1>Active Users</h1>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        {/* <ToastContainer />

        <ConfirmDeleteAdModal
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          id={selectedID}
          notifySuccess={notifySuccess}
          notifyError={notifyError}
        /> */}
      </div>

      {!activeUserData.length ? (
        <h1>No active users at the moment.</h1>
      ) : (
        <>
          <div className="tableCont">
            <table {...getTableProps()}>
              <thead className="tableHeader">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="table-row" {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <>
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}{" "}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="tableFooters">
            {/* <div className="pageInfo">
      {"\u00a0"} Go to page number : {"\u00a0"}
      <input
        type="number"
        default={pageIndex + 1}
        onChange={(e) => {
          const pageNumber = e.target.value
            ? Number(e.target.value) - 1
            : 0;
          gotoPage(pageNumber);
        }}
      />
    </div> */}
            <div className="tableButtons">
              {/* <button
        className="jumpButton"
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
      >
        {" "}
        Go to first page
      </button> */}
              <button
                className="tableNav"
                disabled={!canPreviousPage}
                onClick={() => previousPage()}
              >
                <img src={chevLeftRed} />
              </button>
              <p className="pageNumber">
                {" "}
                {"\u00a0"}
                Showing page {pageIndex + 1}
                {"\u00a0"}of {pageOptions.length}
              </p>
              <button
                className="tableNav"
                disabled={!canNextPage}
                onClick={() => nextPage()}
              >
                <img src={chevRightRed} />
              </button>
              {/* <button
        className="jumpButton"
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      >
        Go to last page
      </button> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ActiveCustomerTable;
