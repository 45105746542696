import React, { useEffect } from "react";
import markDamage from "../../assets/icons/markDamage.png";
import styles from "../Car Management/carManage.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { fetchCarByid } from "../../store/carManagement";
import { fetchAllCategories } from "../../store/carManagement";
import chev from "../../assets/icons/chevBlue.png";
import { BarLoader } from "react-spinners";
import { fetchAllPayments, fetchPaymentById } from "../../store/payments";

const CompletedPaymentIndividual = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const id = useParams();
  const paymentId = String(id.paymentId).slice(1);
  const selectedPaymenObject = useSelector(
    (state) => state.payments.selectedPaymentObject
  );

  const fetchAllPaymentsResponse = useSelector(
    (state) => state.payments.fetchAllPaymentResponse
  );

  useEffect(() => {
    if (!selectedPaymenObject.length) {
      dispatch(fetchPaymentById(paymentId));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAllPayments());
  }, [dispatch]);

  function capitalizeName(name) {
    return name?.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  const dataBlock = (
    <div className={styles.listedVehicles}>
      <div className={styles.otherCarDetails}>
        <div className={styles.column}>
          <div className={styles.detail}>
            <p>Invoice Breakdown</p>
            <p className="blackText"></p>
          </div>
          <div className={styles.detail}>
            <p className="grayText">Payment step</p>
            <p className="blackText">{selectedPaymenObject[0].paymentStep}</p>
          </div>

          <div className={styles.detail}>
            <p className="grayText">Miles </p>
            <p className="blackText">
              {selectedPaymenObject[0].bookingPaymentObject.miles
                ? `${selectedPaymenObject[0].bookingPaymentObject.miles} miles`
                : "--"}
            </p>
          </div>

          <div className={styles.detail}>
            <p className="grayText">Tax </p>
            <p className="blackText">
              $ {selectedPaymenObject[0].bookingPaymentObject.tax}
            </p>
          </div>

          <div className={styles.detail}>
            <p className="grayText">Time </p>
            <p className="blackText">
              {selectedPaymenObject[0].bookingPaymentObject.time
                ? `${selectedPaymenObject[0].bookingPaymentObject.time} h`
                : "--"}
            </p>
          </div>

          <div className={styles.detail}>
            <p className="grayText">Booking Plan </p>
            <p className="blackText">{selectedPaymenObject[0].bookingPlan}</p>
          </div>

          <div className={styles.detail}>
            <p className="grayText">Refund Status </p>
            <p className="blackText">
              {" "}
              {String(selectedPaymenObject[0].refunded)}
            </p>
          </div>

          <div className={styles.detail}>
            <p className="grayText">Main payment </p>
            <p className="blackText">
              $ {selectedPaymenObject[0].stripePaymentDetail.amount / 100}
            </p>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.detail}>
            <p className="grayText">Ride Id</p>
            <p className="blackText">{selectedPaymenObject[0]?._id}</p>
          </div>
          <div className={styles.detail}>
            <p className="grayText">Customer Name</p>
            <p className="blackText">
              {capitalizeName(selectedPaymenObject[0]?.user?.name)}
            </p>
          </div>

          {/* 
          </div>
          
          <div className={styles.detail}>
            <p className="grayText">Central Lock ( Last Command)</p>
            <p className="blackText">
              {capitalizeName(selectedCarObject[0]?.central_lock_last_command)}
            </p>
          </div>
          <div className={styles.detail}>
            <p className="grayText">Immobilizer</p>
            <p className="blackText">
              {capitalizeName(selectedCarObject[0]?.immobilizer)}
            </p>
          </div>
          <div className={styles.detail}>
            <p className="grayText">Mileage</p>
            <p className="blackText">{selectedCarObject[0]?.mileage}</p>
          </div>
          <div className={styles.detail}>
            <p className="grayText">Mileage Since immobilizer unlock</p>
            <p className="blackText">
              {selectedCarObject[0]?.mileage_since_immobilizer_unlock}
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );

  const checkRefresh = () => {
    if (selectedPaymenObject.length > 0) {
      return dataBlock;
    }

    if (!selectedPaymenObject.length) {
      if (fetchAllPaymentsResponse === "loading") {
        return <BarLoader color="#031569" />;
      }

      if (fetchAllPaymentsResponse === "succeeded") {
        return dataBlock;
      }
    }
  };

  return (
    <div className="containerSubRight">
      <div className="subRight-boundary">
        <div className="heading-container border-bottom2">
          <div className="back-button">
            <img onClick={() => navigate(-1)} src={chev} />
            <h1>Payment Details</h1>
          </div>

          <div className="listed-buttons">
            {/* <button className="btn-revoke" onClick={() => setOpenModal(true)}>
            <img src={add} alt="Add" />
            Create Subscription
          </button> */}
            {/* <button
              className="btn-suspend"
            //   onClick={() => setOpenMarkDamage(true)}
            >
              <img src={markDamage} alt="Add" />
              Mark Damage
            </button> */}
          </div>
        </div>
        <div className={styles.listedContainer}>
          {/* <div className={styles.accordion}>
          <h2 onClick={() => setSelectedTab("Listed")}>Listed Vehicles</h2>
          <h2 onClick={() => setSelectedTab("Subscription")}>Subscription</h2>
        </div> */}
          {checkRefresh()}
        </div>
      </div>
    </div>
  );
};

export default CompletedPaymentIndividual;
