import React, { useEffect, useState } from "react";
import { Button } from "@chakra-ui/react";
import markDamage from "../../assets/icons/markDamage.png";
import styles from "./carManage.module.css";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import CreateSubscriptionModal from "./CreateSubscription";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchAllCars, fetchCarByid } from "../../store/carManagement";
import { Select } from "@chakra-ui/react";
import { css } from "glamor";
import { fetchAllCategories } from "../../store/carManagement";
import ConfirmMarkDamage from "./ConfirmMarkDamage";
import { UploadEdit } from "./EditUploader";
import chev from "../../assets/icons/chevBlue.png";
import { refreshToken } from "../../functions";
import Cookies from "js-cookie";
import { BarLoader } from "react-spinners";
import ViewQNR from "./ViewQRCode";
import QRCode from "react-qr-code";
import suspend from "../../assets/icons/cancelCross.png";

const ListedVehicle = () => {
  const [openMarkDamage, setOpenMarkDamage] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [markDamageLoad, setMarkDamageLoad] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedCarId = useSelector((state) => state.cars.selectedListedCar);
  const fetchCarStatus = useSelector((state) => state.cars.fetchCarStatus);

  const selectedCarObject = useSelector(
    (state) => state.cars.selectedCarObject
  );

  const id = useParams();
  const carID = String(id.vehicleId).slice(1);

  useEffect(() => {
    if (!selectedCarObject?.length) {
      dispatch(fetchCarByid(carID));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAllCategories);
  }, [dispatch]);

  const allCategories = useSelector((state) => state.cars.allCategories);

  const availableCategories = allCategories?.filter(
    (item) => item?.name !== selectedCarObject?.category
  );

  const schema = Yup.object().shape({
    carID: Yup.string().required("Vehicle ID is required"),
    carBrand: Yup.string().required("Vehicle Brand is required"),
    carModel: Yup.string().required("Vehicle Model is required"),
    seatingCapacity: Yup.string().required("Seating Capacity is required"),
    color: Yup.string().required("Vehicle Brand is required"),
    category: Yup.string().required("Category is required"),
    fuelType: Yup.string().required("Fuel type is required"),
  });

  const schemaHourlyPricing = Yup.object().shape({
    carHours: Yup.string().required("Hours is a required field"),
    hourlyPricing: Yup.string().required("Price  is a required field"),
  });

  const schemaMileagePricing = Yup.object().shape({
    miles: Yup.string().required("Miles is a required field"),
    pricePerMile: Yup.string().required("Price is a required field"),
  });

  const extraMileagePricing = Yup.object().shape({
    extraMileRate: Yup.string().required("This is a required field"),
  });

  const extraMinutePricing = Yup.object().shape({
    extraMinuteRate: Yup.string().required("This is a required field"),
  });

  const notifySuccess = () =>
    toast.success("Vehicle Updated", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const [uploadedFileURL, setUploadedFileUrl] = useState([]);

  const UpdateVehicle = (values) => {
    setUpdateLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = {};

    if (
      selectedCarObject[0]?.status === "inactive" &&
      selectedCarObject[0]?.isDamaged === true
    ) {
      raw = JSON.stringify({
        brand: values.values.carBrand,
        color: values.values.color,
        model: values.values.carModel,
        seatCapacity: values.values.seatingCapacity,
        category: values.values.category,
        images: !uploadedFileURL?.length
          ? selectedCarObject[0]?.images
          : uploadedFileURL,
        fuelType: values.values.fuelType,
      });
    }

    if (
      selectedCarObject[0].status === "inactive" &&
      selectedCarObject[0]?.isDamaged === false
    ) {
      raw = JSON.stringify({
        status: "active",
        brand: values.values.carBrand,
        color: values.values.color,
        model: values.values.carModel,
        seatCapacity: values.values.seatingCapacity,
        category: values.values.category,
        images: !uploadedFileURL?.length
          ? selectedCarObject[0]?.images
          : uploadedFileURL,
        fuelType: values.values.fuelType,
      });
    }

    if (selectedCarObject[0]?.status === "active") {
      raw = JSON.stringify({
        // status: "active",
        brand: values.values.carBrand,
        color: values.values.color,
        model: values.values.carModel,
        seatCapacity: values.values.seatingCapacity,
        category: values.values.category,
        images: !uploadedFileURL?.length
          ? selectedCarObject[0]?.images
          : uploadedFileURL,
        fuelType: values.values.fuelType,
      });
    }

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`https://api.symboldrive.com/car/${carID}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (
          res.status === "false" &&
          res.message === "Please pass firebase auth token"
        ) {
          setMarkDamageLoad(false);
          refreshToken();
        }

        if (res.status === true) {
          setUpdateLoading(false);
          notifySuccess();
          dispatch(fetchAllCars());
          setTimeout(() => {
            navigate("/carManagement/listedVehicles");
          }, 500);
        }
      });
  };

  const onMarkDamage = () => {
    setMarkDamageLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      isDamaged: true,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(
      `https://api.symboldrive.com/car/${selectedCarId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.status === true) {
          notifySuccess();
          setOpenMarkDamage(false);
          dispatch(fetchAllCars());
          setMarkDamageLoad(false);
        }

        if (
          res.status === "false" &&
          res.error.code === "auth/id-token-expired"
        ) {
          setMarkDamageLoad(false);
          refreshToken();
        }
      });
  };

  const onFileDrop = (files) => {
    if (files[0] === undefined) {
      return;
    }

    var formdata = new FormData();
    formdata.append("type", "dl");
    formdata.append("file", files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.symboldrive.com/file-upload", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setUploadedFileUrl(result.urls);
        // setImageUploadLoader(false);
      });
  };

  function capitalizeName(name) {
    return name?.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  const [showQNR, setShowQNR] = useState(false);

  const QNR = selectedCarObject[0]?.qrCodeImage;

  const [price, setPrice] = useState({});

  const fetchCarPrice = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`https://api.symboldrive.com/car-pricing/car/${id}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.status === true) {
          setPrice(res.carPricing);
        }

        if (
          res.status === "error" ||
          res?.error?.code === "auth/id-token-expired"
        ) {
          refreshToken();
        }
      });
  };

  useEffect(() => {
    fetchCarPrice(carID);
  }, []);

  const createDummmyPricing = () => {
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      carId: carID,
      pricingRules: [
        {
          hours: 1,
          price: 10,
        },
        {
          hours: 2,
          price: 18,
        },
        {
          hours: 3,
          price: 26,
        },
      ],
      mileageRates: [
        {
          miles: 10,
          price: 3,
        },
        {
          miles: 20,
          price: 5,
        },
      ],
      extraMinuteRate: 0.3,
      extraMileRate: 0.4,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.symboldrive.com/car-pricing/add", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          fetchCarPrice(carID);
          notifySuccess();
        }
      });
  };

  const [hourlyPriceLoad, setHourlyPriceLoad] = useState(false);

  const updateHourlyRates = (filtered) => {
    setHourlyPriceLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      pricingRules: [...price?.pricingRules, filtered[0]],
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(
      `https://api.symboldrive.com/car-pricing/car/${carID}/update`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          fetchCarPrice(carID);
          notifySuccess();
          setHourlyPriceLoad(false);
        }
      });
  };

  const [mileageRateLoad, setMileageRateLoad] = useState(false);

  const updateMileageRates = (filtered) => {
    setMileageRateLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mileageRates: [...price.mileageRates, filtered[0]],
      // mileageRates: filtered,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `https://api.symboldrive.com/car-pricing/car/${carID}/update`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          fetchCarPrice(carID);
          notifySuccess();
          setMileageRateLoad(false);
        }
      });
  };

  const [milePricingLoad, setMilePricingLoad] = useState(false);

  const updateMileagePricing = (value) => {
    setMilePricingLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      extraMileRate: value,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `https://api.symboldrive.com/car-pricing/car/${carID}/update`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          fetchCarPrice(carID);
          notifySuccess();
        }
        setMilePricingLoad(false);
      });
  };

  const [minutePricingLoad, setMinutePricingLoad] = useState(false);

  const updateMinutePricing = (value) => {
    setMinutePricingLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      extraMinuteRate: value,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `https://api.symboldrive.com/car-pricing/car/${carID}/update`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          fetchCarPrice(carID);
          notifySuccess();
          setMinutePricingLoad(false);
        }
      });
  };

  const [deleteMiles, setDeleteMiles] = useState(false);
  const [deleteHours, setDeleteHours] = useState(false);

  const deletePriceById = (type, id) => {
    const priceCopy = price;

    if (type === "mileage") {
      setDeleteMiles(true);
      const filtered = priceCopy.mileageRates.filter((item) => item._id !== id);

      priceCopy.mileageRates = filtered;

      var myHeaders = new Headers();
      myHeaders.append("token", Cookies.get("token"));
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        mileageRates: filtered,
      });

      var requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `https://api.symboldrive.com/car-pricing/car/${carID}/update`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            fetchCarPrice(carID);
            notifySuccess();
            setDeleteMiles(false);
          }
        });
    }

    if (type === "hourly") {
      setDeleteHours(true);
      const filtered = priceCopy.pricingRules.filter((item) => item._id !== id);
      priceCopy.hourlyRates = filtered;

      var myHeaders = new Headers();
      myHeaders.append("token", Cookies.get("token"));
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        pricingRules: filtered,
      });

      var requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      return fetch(
        `https://api.symboldrive.com/car-pricing/car/${carID}/update`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            fetchCarPrice(carID);
            notifySuccess();
            setDeleteHours(false);
          }
        });

      // updateHourlyRates(filtered);
    }
  };

  const dataBlock = (
    <div className={styles.listedVehicles}>
      <div className={styles.carImageAndQNR}>
        <UploadEdit
          onDrop={(files) => onFileDrop(files)}
          img={selectedCarObject[0]?.images[0]}
        />

        <div className={styles.dragAndDrop}>
          <QRCode
            style={{ height: "auto", maxWidth: "50%", width: "100%" }}
            value={selectedCarObject[0]?.qnr}
            onClick={() => setShowQNR(true)}
          />

          <h4 className="blueText">
            Symboldrive QNR : {selectedCarObject[0]?.qnr}{" "}
          </h4>
        </div>
      </div>
      <div className={styles.otherCarDetails}>
        <div className={styles.column}>
          <div className={styles.detail}>
            <p className="grayText">Booking Status</p>
            <p className="blackText">
              {selectedCarObject[0]?.bookingStatus === "booked"
                ? "Booked"
                : "Available for booking"}
            </p>
          </div>
          <div className={styles.detail}>
            <p className="grayText">Fuel Level</p>
            <p className="blackText">{selectedCarObject[0]?.fuel_level}%</p>
          </div>
          <div className={styles.detail}>
            <p className="grayText">Position</p>
            <p className="blackText">
              {selectedCarObject[0]?.position?.coordinates[1]},{" "}
              {selectedCarObject[0]?.position?.coordinates[0]}
            </p>
          </div>
          <div className={styles.detail}>
            <p className="grayText">Alarm Input</p>
            <p className="blackText">
              {selectedCarObject[0]?.alarm_input === "off" ? "False" : "True"}
            </p>
          </div>
          <div className={styles.detail}>
            <p className="grayText">Alarm Input 2</p>
            <p className="blackText">
              {selectedCarObject[0]?.alarm_input_2 === "off" ? "Off" : "On"}
            </p>
          </div>
          <div className={styles.detail}>
            <p className="grayText">Low level battery alarm</p>
            <p className="blackText">
              {selectedCarObject[0]?.low_battery_level_alarm ? "On" : "Off"}
            </p>
          </div>
          <div className={styles.detail}>
            <p className="grayText">Relay Value</p>
            <p className="blackText">{selectedCarObject[0]?.relay_value}</p>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.detail}>
            <p className="grayText">Bluetooth Connection</p>
            <p className="blackText">
              {capitalizeName(selectedCarObject[0]?.bluetooth_connection)}
            </p>
          </div>
          <div className={styles.detail}>
            <p className="grayText">Board Voltage</p>
            <p className="blackText">
              {capitalizeName(selectedCarObject[0]?.bluetooth_connection)}
            </p>
          </div>
          <div className={styles.detail}>
            <p className="grayText">Central Lock</p>
            <p className="blackText">
              {capitalizeName(selectedCarObject[0]?.central_lock)}
            </p>
          </div>
          <div className={styles.detail}>
            <p className="grayText">Central Lock ( Last Command)</p>
            <p className="blackText">
              {capitalizeName(selectedCarObject[0]?.central_lock_last_command)}
            </p>
          </div>
          <div className={styles.detail}>
            <p className="grayText">Immobilizer</p>
            <p className="blackText">
              {capitalizeName(selectedCarObject[0]?.immobilizer)}
            </p>
          </div>
          <div className={styles.detail}>
            <p className="grayText">Miles</p>
            <p className="blackText">{selectedCarObject[0]?.mileage}</p>
          </div>
          <div className={styles.detail}>
            <p className="grayText">Mileage Since immobilizer unlock</p>
            <p className="blackText">
              {selectedCarObject[0]?.mileage_since_immobilizer_unlock}
            </p>
          </div>
        </div>
      </div>
      <label className="labelCar">Vehicle Pricing</label>

      {price === null && (
        <button className="addButton" onClick={() => createDummmyPricing()}>
          Add Dummy price to get started
        </button>
      )}

      <div className={styles.otherCarDetails}>
        <div className="carPriceDetails">
          <table className="height">
            <tr className="">
              <th className="color-black smallGrey">Number of miles</th>
              <th className="color-black smallGrey">Price per mile</th>
              <th className="color-black smallGrey">Actions</th>
            </tr>
            {price?.mileageRates?.map((item) => {
              return (
                <tr>
                  <td className="smallBlack">{item?.miles} miles</td>
                  <td className="smallBlack"> $ {item?.price}</td>
                  <td>
                    <button
                      className="smallBlack"
                      onClick={(e) => deletePriceById("mileage", item?._id)}
                    >
                      {/* {deleteMiles ? (
                        <div className="loader"></div>
                      ) : (
                        <img
                          className="red-cross"
                          src={suspend}
                          alt="Suspend"
                        />
                      )} */}

                      <img className="red-cross" src={suspend} alt="Suspend" />
                    </button>
                  </td>
                </tr>
              );
            })}
          </table>

          <table className="height">
            <tr>
              <th className="color-black smallGrey">Number of hours</th>
              <th className="color-black smallGrey">Price per hour</th>
              <th className="color-black smallGrey">Actions</th>
            </tr>

            {price?.pricingRules?.map((item) => {
              return (
                <tr>
                  <td className="smallBlack">{item?.hours} hrs</td>
                  <td className="smallBlack">$ {item?.price} </td>
                  <td>
                    <button
                      onClick={() => deletePriceById("hourly", item?._id)}
                      className="smallBlack"
                      type="submit"
                    >
                      {/* {deleteHours ? (
                        <div className="loader"></div>
                      ) : (
                        <img
                          className="red-cross"
                          src={suspend}
                          alt="Suspend"
                        />
                      )} */}

                      <img className="red-cross" src={suspend} alt="Suspend" />
                    </button>
                  </td>
                </tr>
              );
            })}
          </table>

          <table className="height">
            <tr>
              <th className="color-black smallGrey">Extra mile rate </th>
              <th className="color-black smallGrey">Extra minute rate</th>
            </tr>
            {/* {!price.length > 0 && <p>No data</p>} */}

            <tr>
              <td className="smallBlack">$ {price?.extraMileRate}</td>
              <td className="smallBlack">$ {price?.extraMinuteRate} </td>
            </tr>
          </table>
        </div>
      </div>

      <label className="labelCar">Pricing Controls</label>

      <Formik
        validationSchema={schemaHourlyPricing}
        initialValues={{
          carHours: "",
          hourlyPricing: "",
        }}
        onSubmit={(values) => {
          updateHourlyRates([
            {
              hours: values.carHours,
              price: values.hourlyPricing,
            },
          ]);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="changeCarPrice">
              <div className={styles.flexRow}>
                <div className={styles.carID}>
                  <label>Hours</label>
                  <input
                    type="number"
                    name="carHours"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.carHours}
                    id="carHours"
                    placeholder="Enter hours"
                  />

                  <p className="red">
                    {errors.carHours && touched.carHours && errors.carHours}
                  </p>
                </div>

                <div className={styles.carID}>
                  <label>Price</label>
                  <input
                    type="number"
                    name="hourlyPricing"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.hourlyPricing}
                    id="hourlyPricing"
                    placeholder="Enter price"
                  />

                  <p className="red">
                    {errors.hourlyPricing &&
                      touched.hourlyPricing &&
                      errors.hourlyPricing}
                  </p>
                </div>
                <Button
                  padding="10px"
                  background="#031569"
                  colorScheme="blue"
                  size="md"
                  variant="solid"
                  borderRadius="10px"
                  type="submit"
                  isLoading={hourlyPriceLoad}
                  marginTop={"30px"}
                >
                  Add
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>

      <Formik
        validationSchema={schemaMileagePricing}
        initialValues={{
          miles: "",
          pricePerMile: "",
        }}
        onSubmit={(values) => {
          updateMileageRates([
            {
              miles: values.miles,
              price: values.pricePerMile,
            },
          ]);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="changeCarPrice">
              <div className={styles.flexRow}>
                <div className={styles.carID}>
                  <label>Mileage</label>
                  <input
                    type="number"
                    name="miles"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.miles}
                    id="miles"
                    placeholder="Enter miles"
                  />

                  <p className="red">
                    {errors.miles && touched.miles && errors.miles}
                  </p>
                </div>
                <div className={styles.carID}>
                  <label>Price</label>
                  <input
                    type="number"
                    name="pricePerMile"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.pricePerMile}
                    id="pricePerMile"
                    placeholder="Enter price"
                  />
                  <p className="red">
                    {errors.pricePerMile &&
                      touched.pricePerMile &&
                      errors.pricePerMile}
                  </p>
                </div>
                <Button
                  padding="10px"
                  background="#031569"
                  colorScheme="blue"
                  size="md"
                  variant="solid"
                  borderRadius="10px"
                  type="submit"
                  isLoading={mileageRateLoad}
                  marginTop={"30px"}
                >
                  Add
                </Button>{" "}
              </div>
            </div>
          </form>
        )}
      </Formik>

      <Formik
        validationSchema={extraMileagePricing}
        initialValues={{
          extraMileRate: "",
        }}
        onSubmit={(values) => updateMileagePricing(values.extraMileRate)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="changeCarPrice">
              <div className={styles.flexRow}>
                <div className={styles.carID}>
                  <label>Extra mile rate</label>
                  <input
                    type="number"
                    name="extraMileRate"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.extraMileRate}
                    id="extraMileRate"
                    placeholder="Enter extra mile charges"
                  />

                  <p className="red">
                    {errors.extraMileRate &&
                      touched.extraMileRate &&
                      errors.extraMileRate}
                  </p>
                </div>
                <Button
                  padding="10px"
                  background="#031569"
                  colorScheme="blue"
                  size="md"
                  variant="solid"
                  borderRadius="10px"
                  type="submit"
                  isLoading={milePricingLoad}
                  marginTop={"30px"}
                >
                  Add
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>

      <Formik
        validationSchema={extraMinutePricing}
        initialValues={{
          extraMinuteRate: "",
        }}
        onSubmit={(values) => updateMinutePricing(values.extraMinuteRate)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="changeCarPrice">
              <div className={styles.flexRow}>
                <div className={styles.carID}>
                  <label>Extra minute rate</label>
                  <input
                    type="number"
                    name="extraMinuteRate"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.extraMinuteRate}
                    id="extraMinuteRate"
                    placeholder="Enter extra minute charges"
                  />

                  <p className="red">
                    {errors.extraMinuteRate &&
                      touched.extraMinuteRate &&
                      errors.extraMinuteRate}
                  </p>
                </div>

                <Button
                  padding="10px"
                  background="#031569"
                  colorScheme="blue"
                  size="md"
                  variant="solid"
                  borderRadius="10px"
                  type="submit"
                  isLoading={minutePricingLoad}
                  marginTop={"30px"}
                >
                  Add
                </Button>

                {/* <button className="addButton">Add</button> */}
              </div>
            </div>
          </form>
        )}
      </Formik>

      <Formik
        validationSchema={schema}
        initialValues={{
          carID: selectedCarObject[0]?.qnr,
          carBrand: selectedCarObject[0]?.brand,
          carModel: selectedCarObject[0]?.model,
          seatingCapacity: selectedCarObject[0]?.seatCapacity,
          color: selectedCarObject[0]?.color,
          category: selectedCarObject[0]?.category,
          fuelType: selectedCarObject[0]?.fuelType,
        }}
        onSubmit={(values) => {
          UpdateVehicle({ values });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className={styles.carID}>
              <label>Vehicle ID</label>
              <input
                type="carID"
                name="carID"
                onChange={handleChange}
                onBlur={handleBlur}
                value={selectedCarObject[0]?._id}
                id="carID"
                placeholder="SASDSAF-YA1246"
                disabled
              />

              <p className="red">
                {errors.carID && touched.carID && errors.carID}
              </p>
            </div>
            <div className={styles.flexRow}>
              <div className={styles.carID}>
                <label>Vehicle Brand</label>
                <input
                  type="carBrand"
                  name="carBrand"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.carBrand}
                  id="carBrand"
                  placeholder="Tesla"
                />

                <p className="red">
                  {errors.carBrand && touched.carBrand && errors.carBrand}
                </p>
              </div>

              <div className={styles.carID}>
                <label>Vehicle Model</label>
                <input
                  type="carModel"
                  name="carModel"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.carModel}
                  id="carModel"
                  placeholder="Tesla"
                />

                <p className="red">
                  {errors.carModel && touched.carModel && errors.carModel}
                </p>
              </div>
            </div>

            <div className={styles.flexRow}>
              {/* <div className={styles.carID}>
            <label>Tracker Key</label>
            <input
              type="password"
              name="trackerKey"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.trackerKey}
              id="trackerKey"
              placeholder="Tesla"
            />

            <p className="red">
              {errors.trackerKey &&
                touched.trackerKey &&
                errors.trackerKey}
            </p>
          </div> */}

              {/* <div className={styles.carID}> */}
              {/* <label>Tracker Key</label>
                <input type="password" value={"None"} disabled /> */}

              {/* <p className="red">
                  {errors.fuelType && touched.fuelType && errors.fuelType}
                </p> */}
              {/* </div> */}

              <div className={styles.carID}>
                <label>Seating Capacity</label>
                <input
                  type="text"
                  name="seatingCapacity"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.seatingCapacity}
                  id="seatCapacity"
                  placeholder="7 Seater"
                />

                <p className="red">
                  {errors.seatingCapacity &&
                    touched.seatingCapacity &&
                    errors.seatingCapacity}
                </p>
              </div>
            </div>

            <div className={styles.flexRow}>
              {/* <div className={styles.carID}>
            <label>Tracker Key</label>
            <input
              type="password"
              name="trackerKey"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.trackerKey}
              id="trackerKey"
              placeholder="Tesla"
            />

            <p className="red">
              {errors.trackerKey &&
                touched.trackerKey &&
                errors.trackerKey}
            </p>
          </div> */}

              {/* <div className={styles.carID}>
                <label>Price per hour (USD)</label>
                <input
                  type="text"
                  name="seatingCapacity"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.seatingCapacity}
                  id="seatCapacity"
                  placeholder="7 Seater"
                  disabled
                />
                <p className="red">
                  {errors.fuelType && touched.fuelType && errors.fuelType}
                </p>
              </div> */}

              {/* <div className={styles.carID}>
                <label>Enter miles per hour</label>
                <input
                  type="text"
                  name="seatingCapacity"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.seatingCapacity}
                  id="seatCapacity"
                  placeholder="7 Seater"
                />

                <p className="red">
                  {errors.seatingCapacity &&
                    touched.seatingCapacity &&
                    errors.seatingCapacity}
                </p>
              </div> */}
            </div>
            <div className={styles.carID}>
              <label>Category</label>
              {/* <input
            type="text"
            name="category"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.category}
            id="category"
            placeholder="SUV/Sedan"
          /> */}

              <Select
                placeholder={values.category}
                marginTop="10px"
                marginBottom="10px"
                background={"#f3f2f3"}
                height="56px"
                id="category"
                value={values.category}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {availableCategories.map((item) => (
                  <option value={item.name}>{item.name}</option>
                ))}
              </Select>

              <p className="red">
                {errors.category && touched.category && errors.category}
              </p>
            </div>
            <div className={styles.carID}>
              <label>Color</label>
              <input
                type="text"
                name="color"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.color}
                id="color"
                placeholder="Color"
              />

              <p className="red">
                {errors.color && touched.color && errors.color}
              </p>
            </div>

            {/* 
        <p>Last updated : {selectedCarObject[0]?.updatedAt}</p>

        <p>Last updated : {selectedCarObject[0]?.updatedAt}</p> */}

            {/* <div className={styles.flexRow}>
          <div className={styles.carID}>
            <label>Price per hour (USD)</label>
            <input
              type="number"
              name="pricePerHour"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.pricePerHour}
              id="trackerKey"
              placeholder="25"
            />

            <p className="red">
              {errors.pricePerHour &&
                touched.pricePerHour &&
                errors.pricePerHour}
            </p>
          </div>

          <div className={styles.carID}>
            <label>Enter miles per hour</label>
            <input
              type="carModel"
              name="milesPerHour"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.milesPerHour}
              id="carModel"
              placeholder="10"
              disabled
            />

            <p className="red">
              {errors.milesPerHour &&
                touched.milesPerHour &&
                errors.milesPerHour}
            </p>
          </div>
        </div> */}
            {/* <div className={styles.flexRow}>
          <div className={styles.carID}>
            <label>Tracker Key</label>
            <input
              type="text"
              name="trackerKey"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.trackerKey}
              id="trackerKey"
              placeholder="White"
            />

            <p className="red">
              {errors.trackerKey &&
                touched.trackerKey &&
                errors.trackerKey}
            </p>
          </div>

          <div className={styles.carID}>
            <label>Select working zone</label>
            <input
              type="carModel"
              name="workingZone"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.workingZone}
              id="carModel"
              placeholder="Select working zone of car"
            />

            <p className="red">
              {errors.workingZone &&
                touched.workingZone &&
                errors.workingZone}
            </p>
          </div>
        </div> */}
            <div className={styles.actionButtons}>
              {selectedCarObject[0]?.status === "inactive" &&
                selectedCarObject[0]?.isDamaged === true && (
                  <button type="submit" className="btn-revoke center">
                    Update
                  </button>
                )}
              {selectedCarObject[0]?.status === "inactive" &&
                selectedCarObject[0]?.isDamaged === false && (
                  <button type="submit" className="btn-revoke center red2">
                    Test & activate
                  </button>
                )}

              {selectedCarObject[0]?.status === "active" && (
                // <button type="submit" className="btn-revoke center">
                //   Update
                // </button>

                <Button
                  width="189.95px"
                  height="52.19px"
                  background="#031569"
                  colorScheme="blue"
                  size="md"
                  variant="solid"
                  borderRadius="80px"
                  type="submit"
                  isLoading={updateLoading}
                  fontWeight="600"
                >
                  Update
                </Button>
              )}
              <button className="btn-reset" onClick={() => navigate(-1)}>
                Cancel
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );

  const checkRefresh = () => {
    if (selectedCarObject?.length > 0) {
      return dataBlock;
    }

    if (!selectedCarObject?.length) {
      if (fetchCarStatus === "loading") {
        return <BarLoader color="#031569" />;
      }

      if (fetchCarStatus === "succeeded") {
        return dataBlock;
      }
    }
  };

  return (
    <div className="containerSubRight">
      <ViewQNR
        viewDoc={showQNR}
        setViewDoc={setShowQNR}
        doc={QNR}
        qnrCode={selectedCarObject[0]?.qnr}
      />

      <CreateSubscriptionModal
        openModal={openModal}
        setOpenModal={setOpenModal}
      />

      <ConfirmMarkDamage
        openModal={openMarkDamage}
        setOpenModal={setOpenMarkDamage}
        onMarkDamage={onMarkDamage}
        deleteCatLoading={markDamageLoad}
      />

      <ToastContainer />
      <div className="subRight-boundary">
        <div className="heading-container border-bottom2">
          <div className="back-button">
            <img onClick={() => navigate(-1)} src={chev} />
            <h1>Listed Vehicle</h1>
          </div>

          <div className="listed-buttons">
            {/* <button className="btn-revoke" onClick={() => setOpenModal(true)}>
            <img src={add} alt="Add" />
            Create Subscription
          </button> */}
            <button
              className="btn-suspend"
              onClick={() => setOpenMarkDamage(true)}
            >
              <img src={markDamage} alt="Add" />
              Mark Damage
            </button>
          </div>
        </div>
        <div className={styles.listedContainer}>
          {/* <div className={styles.accordion}>
          <h2 onClick={() => setSelectedTab("Listed")}>Listed Vehicles</h2>
          <h2 onClick={() => setSelectedTab("Subscription")}>Subscription</h2>
        </div> */}
          {checkRefresh()}
        </div>
      </div>
    </div>
  );
};

export default ListedVehicle;
