import React, { useEffect } from "react";
import ReportedRideTable from "../../components/Ride Management/Reported Ride/table";
import { BarLoader } from "react-spinners";
import animate from "../../assets/images/animate.gif";
import { useSelector, useDispatch } from "react-redux";
import { fetchReportedRides } from "../../store/rideManagement";

const ReportedRide = () => {
  const status = useSelector((state) => state.rides.fetchReportedRideStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchReportedRides());
  }, [dispatch]);

  return (
    <>
      {status === "loading" ? (
        <div className="loader-container">
          <img alt="loading" src={animate} />
        </div>
      ) : (
        <ReportedRideTable />
      )}
    </>
  );
};

export default ReportedRide;
