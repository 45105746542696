import React, { useState } from "react";
import Modal from "react-modal";
import confirmRemove from "../../assets/icons/crossNew.png";
import styles from "./newCustomer.module.css";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button } from "@chakra-ui/react";

const RejectModal = ({
  openRejectModal,
  setOpenRejectModal,
  onRejectClick,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
    },
  };

  const schema = Yup.object().shape({
    reasonForReject: Yup.string().required("Reason for rejection  is required"),
    // comment: Yup.string().required("Comment is required"),
  });

  const bg = {
    overlay: {
      background: "rgb(48,48,48,0.9)",
    },
  };

  const [loading, setLoading] = useState(false);

  const rejectClick = (values) => {
    setLoading(true);
    setInterval(() => {
      setLoading(false);
    }, 5000);

    onRejectClick(values);
  };

  return (
    <Modal
      className={` ${customStyles}`}
      isOpen={openRejectModal}
      shouldCloseOnEsc={true}
      onRequestClose={() => setOpenRejectModal(false)}
      style={bg}
    >
      <div className={styles.modalContainer}>
        <div className={styles.createSubscription}>
          <div className={styles.heading2}>
            <div className={styles.headingModal}>
              <h1 className={styles.rejectUser}>Reject User</h1>
              <h2 className={styles.about}>
                Please provide a reason for rejection.
              </h2>
            </div>
            <img
              src={confirmRemove}
              alt="X"
              onClick={() => setOpenRejectModal(false)}
            />
          </div>
          <div className={styles.form}>
            <Formik
              validationSchema={schema}
              initialValues={{
                reasonForReject: "",
                // comment: "",
                // categoryName: selectedCategoryObject[0].title,
                // description: selectedCategoryObject[0].desc,
              }}
              onSubmit={(values) => {
                rejectClick(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <div className={styles.carID}>
                    {/* <label>Reason for rejection</label> */}
                    <input
                      type="text"
                      name="reasonForReject"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.reasonForReject}
                      id="reasonForReject"
                      placeholder="Provide a reason to reject"
                    />

                    <p className="red">
                      {errors.reasonForReject &&
                        touched.reasonForReject &&
                        errors.reasonForReject}
                    </p>
                  </div>
                  {/* <div className={styles.carID}>
                    <label>Comment</label>
                    <textarea
                      name="comment"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.comment}
                      id="comment"
                      placeholder="Add Comment"
                    />
                    <p className="red">
                      {errors.comment && touched.comment && errors.comment}
                    </p>
                  </div> */}

                  <div className={styles.actionButtons}>
                    <button
                      onClick={() => setOpenRejectModal(false)}
                      className="btn-reset center"
                    >
                      {" "}
                      Cancel
                    </button>

                    <Button
                      width="174.81px"
                      height="52.19px"
                      background="#031569"
                      colorScheme="blue"
                      size="md"
                      variant="solid"
                      borderRadius="80px"
                      type="submit"
                      isLoading={loading}
                      // onClick={() => approve()}
                      disabled={loading}
                    >
                      Reject
                    </Button>

                    {/* <button
                      type="submit"
                      className="btn-revoke center"
                      // disabled={loading}
                      disabled
                    >
                      {" "}
                      Reject
                    </button> */}
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RejectModal;
