import React from "react";
import { useDispatch, useSelector } from "react-redux";
import animate from "../../assets/images/animate.gif";
import MapComponent2 from "./BirdEye";
import { getCarPosition } from "../../store/carManagement";

const BirdEyeContainer = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getCarPosition());
  }, [dispatch]);

  React.useEffect(() => {
    setInterval(() => {
      dispatch(getCarPosition());
    }, 180000);
  }, [dispatch]);

  const allCars = useSelector((state) => state.cars.carPosition);

  const status = useSelector((state) => state.cars.getCarPositionStatus);

  return (
    <div className="containerSubRight">
      <div className="heading-container">
        <h1>Bird's eye view</h1>
      </div>

      <>
        {status === "loading" ? (
          <div className="loader-container">
            <img alt="loading" src={animate} />
          </div>
        ) : (
          <MapComponent2 allCars={allCars} />
        )}
      </>
    </div>
  );
};

export default BirdEyeContainer;
