import React, { useState } from "react";
import styles from "./newCustomer.module.css";
import chev from "../../assets/icons/chevBlue.png";
import userAlt from "../../assets/images/user.svg";
import imageAlt from "../../assets/images/gallery.svg";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import ConfirmDelete from "./ConfirmDelete";
import { fetchAllUsers, fetchUserById } from "../../store/users";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "glamor";
import { useNavigate, useParams } from "react-router-dom";
import ViewDocModal from "./ViewDocModal";
import { fetchAllRidesbyUser } from "../../store/rideManagement";
import { useEffect } from "react";
import UserRides from "../../components/customers/User Rides/table";
import animate from "../../assets/images/animate.gif";
import { refreshToken } from "../../functions";
import { BarLoader } from "react-spinners";
import Cookies from "js-cookie";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

const ActiveCustomer = () => {
  const selectedUserObject = useSelector(
    (state) => state.users.selectedUserObject
  );

  const [selectedTab, setSelectedTab] = useState("Previous Rides");

  const status = useSelector((state) => state.rides.fetchAllRidesByUserStatus);

  const id = useParams();
  const userID = String(id.userId).slice(1);

  const onBlackClick = () => {
    navigate("/users/activeUsers");
  };

  const getUserStatus = useSelector((state) => state.users.fetchByIdStatus);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const token = Cookies.get("token");
  const [deleteUserLoading, setDeleteUserLoading] = useState(false);
  const [viewDoc, setViewDoc] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState("");

  const [suspensionReason, setSuspensionReason] = useState("Prefer not to say");

  useEffect(() => {
    if (!selectedUserObject.length) {
      dispatch(fetchUserById(userID));
    }
  }, [dispatch, selectedUserObject.length, userID]);

  useEffect(() => {
    dispatch(fetchAllRidesbyUser(userID));
  }, [dispatch, userID]);

  const notifySuccess = () =>
    toast.success("User suspended", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const slides = [
    {
      src: selectedDoc,
    },
  ];

  const onDeleteUser = async () => {
    setDeleteUserLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("token", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      reason: suspensionReason,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const deleteResponse = await fetch(
      `https://api.symboldrive.com/user/${userID}/suspend`,
      requestOptions
    );

    const response2 = await deleteResponse.json();
    if (response2.status === true) {
      setDeleteUserLoading(false);
      notifySuccess();
      dispatch(fetchAllUsers());
      setTimeout(() => {
        navigate("/users/activeUsers");
      }, 500);
    }

    if (
      response2.status === "false" &&
      response2.message === "Please pass firebase auth token "
    ) {
      setDeleteUserLoading(false);
      refreshToken();
    }
  };

  const openDoc = (doc) => {
    setSelectedDoc(doc);
    setViewDoc(true);
  };

  const onImageError = (ev) => {
    ev.target.src = userAlt;
  };
  const onImageError2 = (ev) => {
    ev.target.src = imageAlt;
  };

  function capitalizeName(name) {
    return name?.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  const dataBlock = (
    <>
      <div className={styles.infoAndDocuments}>
        <div className={styles.cuInfo}>
          <h1>User Information</h1>
          <div className={styles.row}>
            <h3>User Name:</h3>
            <h3>{capitalizeName(selectedUserObject[0]?.name)}</h3>
          </div>
          <div className={styles.row}>
            <h3>Date of birth:</h3>
            <h3>{moment(selectedUserObject[0]?.dob).format("MM-DD-YYYY")}</h3>
          </div>
          <div className={styles.row}>
            <h3>Mobile number:</h3>
            <h3>{selectedUserObject[0]?.phone}</h3>
          </div>
          <div className={styles.row}>
            <h3>Email ID:</h3>
            <h3>{selectedUserObject[0]?.email}</h3>
          </div>
          <div className={styles.row}>
            <h3>Address:</h3>
            <h3>
              {selectedUserObject[0]?.address
                ? selectedUserObject[0]?.address
                : "N/A"}
            </h3>
          </div>
        </div>

        <div className={styles.docInfo}>
          <h1>Document Information</h1>
          <div className={styles.row}>
            <h3>Driving Licence No.:</h3>
            <h3>{selectedUserObject[0]?.dl.licenceNumber}</h3>
          </div>
          <div className={styles.row}>
            <h3>Expiry Date:</h3>
            <h3>
              {moment(selectedUserObject[0]?.dl.validTill).format("MM-DD-YYYY")}
            </h3>
          </div>
          <div className={styles.row}>
            <h3>Mobile number:</h3>
            <h3>{selectedUserObject[0]?.phone}</h3>
          </div>
          <div className={styles.row}>
            <h3>Insurance Number:</h3>
            <h3>
              {selectedUserObject[0]?.insurance?.insuranceNumber
                ? selectedUserObject[0]?.insurance?.insuranceNumber
                : "N/A"}
            </h3>
          </div>
          <div className={styles.row}>
            <h3>Valid Upto:</h3>
            <h3>
              {moment(selectedUserObject[0]?.insurance.validTills).format(
                "MM-DD-YYYY"
              )}
            </h3>
          </div>
        </div>
      </div>
    </>
  );

  const renderDetails = () => {
    if (selectedUserObject.length > 0) {
      return dataBlock;
    }

    if (!selectedUserObject.length) {
      if (getUserStatus === "loading") {
        return (
          <div className="refreshLoader">
            <BarLoader color="#031569" />
          </div>
        );
      }

      if (getUserStatus === "succeeded") {
        return dataBlock;
      }
    }
  };

  return (
    <>
      <div className={styles.newCustomer}>
        <ConfirmDelete
          openModal={openDeleteModal}
          setOpenModal={setOpenDeleteModal}
          deleteUser={onDeleteUser}
          deleteUserLoading={deleteUserLoading}
          setSuspensionReason={setSuspensionReason}
        />

        <ToastContainer />

        <Lightbox
          open={viewDoc}
          close={() => setViewDoc(false)}
          slides={slides}
          plugins={[Zoom]}
        />

        <div className={`${styles.heading} ${styles.spaceBetween}`}>
          <div className={styles.fRow}>
            <img
              onClick={onBlackClick}
              className={styles.marginRight}
              src={chev}
              alt="N/A"
            />
            <h2>Active User</h2>
          </div>
          <button
            onClick={() => setOpenDeleteModal(true)}
            className="btn-suspend"
          >
            Suspend User
          </button>
        </div>

        <img
          onError={onImageError}
          className="cuImage"
          src={selectedUserObject[0]?.image}
          alt="N/A"
        />

        {renderDetails()}

        <div className={styles.accordion}>
          <div className={styles.buttons}>
            <button
              className={`${styles.tab} ${
                selectedTab === "Previous Rides"
                  ? `${styles.selectedTab}`
                  : `${styles.normalTab}`
              }`}
              onClick={() => setSelectedTab("Previous Rides")}
            >
              Previous Rides
            </button>

            <button
              className={` ${styles.tab} ${
                selectedTab === "Uploaded Docs"
                  ? `${styles.selectedTab}`
                  : `${styles.normalTab}`
              }`}
              onClick={() => setSelectedTab("Uploaded Docs")}
            >
              Uploaded Documents
            </button>
          </div>

          <div>
            {selectedTab === "Previous Rides" ? (
              <div className={styles.previousRideTable}>
                <>
                  {status === "loading" ? (
                    <div className="loader-container2">
                      <img alt="loading" src={animate} />
                    </div>
                  ) : (
                    <UserRides />
                  )}
                </>
              </div>
            ) : (
              <div className={styles.docImages}>
                {/* <h1>Uploaded Documents</h1> */}
                <div className={styles.downloadContainers}>
                  <div className={styles.licence}>
                    <h3>Drivers Licence</h3>
                    <img
                      onError={onImageError2}
                      src={selectedUserObject[0]?.dl?.image}
                      alt="Licence"
                      onClick={() => openDoc(selectedUserObject[0]?.dl?.image)}
                    />
                  </div>

                  <div className={styles.insurance}>
                    <h3>Insurance Document</h3>
                    {selectedUserObject[0]?.insurance?.image && (
                      <img
                        onError={onImageError2}
                        src={selectedUserObject[0]?.insurance?.image}
                        alt="Licence"
                        onClick={() =>
                          openDoc(selectedUserObject[0]?.insurance?.image)
                        }
                      />
                    )}

                    {!selectedUserObject[0]?.insurance?.image && (
                      <>
                        <img
                          alt="loading"
                          onError={onImageError2}
                          src={imageAlt}
                        />
                        Not uploaded
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveCustomer;
