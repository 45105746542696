import React, { useEffect, useState } from "react";
import ActiveCustomerTable from "../../components/customers/active customers/ActiveCustomers";
import { fetchAllUsers } from "../../store/users";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDelete from "./ConfirmDelete";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import animate from "../../assets/images/animate.gif";
import { refreshToken } from "../../functions";
import Cookies from "js-cookie";
import HardDeleteUser from "./HardDeleteUser";

const ActiveCustomers = () => {
  const dispatch = useDispatch();

  const selectedUserId = useSelector((state) => state.users.selectedUserId);

  const status = useSelector((state) => state.users.userFetchStatus);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchAllUsers());
    }
  }, [dispatch]);

  const [deleteUserLoading, setDeleteUserLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const notifySuccess = () =>
    toast.success("User suspended", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const [suspensionReason, setSuspensionReason] = useState("Prefer not to say");

  const onDeleteUser = async () => {
    setDeleteUserLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    // var raw = JSON.stringify({
    //   suspensionReason: suspensionReason,
    //   isSuspended: true,
    //   isApproved: false,
    // });

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      // body: raw,
      redirect: "follow",
    };

    // notifySuccess();

    const deleteUserResponse = await fetch(
      `https://api.symboldrive.com/user/${selectedUserId}`,
      requestOptions
    );

    const data = await deleteUserResponse.json();

    if (data.status === true) {
      setDeleteUserLoading(false);
      notifySuccess();
      dispatch(fetchAllUsers());
      setOpenDeleteModal(false);
    }

    if (data.status === "error") {
      setDeleteUserLoading(false);
      refreshToken();
    }
  };

  return (
    <>
      {status === "loading" ? (
        <div className="containerSubRight">
          <div className="loader-container">
            <img alt="loading" src={animate} />
          </div>
        </div>
      ) : (
        <>
          <ToastContainer />

          <ActiveCustomerTable
            openModal={openDeleteModal}
            setOpenModal={setOpenDeleteModal}
          />
          <HardDeleteUser
            openModal={openDeleteModal}
            setOpenModal={setOpenDeleteModal}
            deleteUser={onDeleteUser}
            deleteUserLoading={deleteUserLoading}
            setSuspensionReason={setSuspensionReason}
          />
        </>
      )}
    </>
  );
};

export default ActiveCustomers;
