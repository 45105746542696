import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { refreshToken } from "../functions";

const intialUserState = {
  allUsers: [],
  newUsers: [],
  activeUsers: [],
  suspendedUsers: [],
  userFetchStatus: "idle",
  selectedUserId: "",
  selectedUserObject: {},
  fetchByIdStatus: "idle",
  singleUserData: {},
};

export const fetchAllUsers = createAsyncThunk("users/fetchUsers", async () => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  return fetch(
    "https://api.symboldrive.com/user/all-users?sort=-updatedAt",
    requestOptions
  ).then((response) => response.json());
});

export const fetchUserById = createAsyncThunk(
  "users/fetchUserById",
  async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));

    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch(`https://api.symboldrive.com/user/${id}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.status === true) {
          return res;
        }

        if (
          res.status === "error" ||
          res.error.code === "auth/id-token-expired"
        ) {
          refreshToken();
        }
      });
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: intialUserState,
  reducers: {
    setAllUsers(state, action) {
      state.allUsers = action.payload;
    },
    setSelectedUserId(state, action) {
      state.selectedUserId = action.payload;
      state.selectedUserObject = state.allUsers.filter(
        (item) => item._id === action.payload
      );
    },
  },
  extraReducers: {
    [fetchAllUsers.pending]: (state, action) => {
      state.userFetchStatus = "loading";
    },
    [fetchAllUsers.fulfilled]: (state, { payload }) => {
      state.userFetchStatus = "succeeded";
      state.allUsers = payload.users;
      state.activeUsers = payload.users.filter(
        (item) =>
          item.isSuspended === false &&
          item.isApproved === true &&
          item.role !== "admin"
      );

      // state.suspendedUsers = payload.users.filter(
      //   (item) =>
      //     item.isSuspended === true && item.role !== "admin" && !item.isApproved
      // );

      const suspendedUsers2 = payload.users.filter(
        (item) =>
          item.isSuspended ||
          (item.role !== "admin" && item.isRejected && !item.isApproved)
      );

      state.suspendedUsers = suspendedUsers2.map((item) => {
        return {
          ...item,
          rejected: item.isRejected ? "rejected" : "suspended",
        };
      });

      state.newUsers = payload.users.filter(
        (item) =>
          item.isApproved === false &&
          item.isSuspended === false &&
          item.role !== "admin" &&
          item.isRejected === false
      );
    },
    [fetchAllUsers.failed]: (state, action) => {
      state.userFetchStatus = "failed";
    },
    [fetchUserById.pending]: (state, action) => {
      state.fetchByIdStatus = "loading";
    },
    [fetchUserById.fulfilled]: (state, action) => {
      state.fetchByIdStatus = "succeeded";
      state.selectedUserObject = [action.payload.user];
    },
    [fetchUserById.failed]: (state, action) => {
      state.fetchByIdStatus = "failed";
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice;
