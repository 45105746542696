import React, { useState } from "react";
import Modal from "react-modal";
import styles from "../Car Management/carManage.module.css";
import { Button } from "@chakra-ui/react";
import close from "../../assets/icons/crossNew.png";

const ApproveModal = ({
  openApproveModal,
  setOpenApproveModal,
  onApproveClick,
  approveLoading,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      height: "fit-content",
      backgroundColor: "red",
    },
  };

  const bg = {
    overlay: {
      background: "rgb(48,48,48,0.9)",
    },
  };

  const [loading, setLoading] = useState(false);

  const approve = () => {
    setLoading(true);

    onApproveClick();
  };

  return (
    <Modal
      className={` ${customStyles}`}
      isOpen={openApproveModal}
      shouldCloseOnEsc={true}
      onRequestClose={() => setOpenApproveModal(false)}
      style={bg}
    >
      <div className={styles.confirmDeleteModal}>
        <div className={styles.modal}>
          <div className={styles.cross}>
            <img
              className="suspend"
              src={close}
              onClick={() => setOpenApproveModal(false)}
              alt="N/A"
            />
          </div>
          <h1 className="modalText">
            Are you sure you want to approve this user?
          </h1>

          <div className={styles.actionButtons2}>
            <Button
              width="174.81px"
              height="52.19px"
              background="#031569"
              colorScheme="blue"
              size="md"
              variant="solid"
              borderRadius="80px"
              type="submit"
              isLoading={approveLoading}
              onClick={() => approve()}
              disabled={loading}
            >
              Approve
            </Button>

            <button
              className="btn-reset center"
              type="submit"
              onClick={() => setOpenApproveModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ApproveModal;
