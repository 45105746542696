import React, { useEffect, useState } from "react";
import markDamage from "../../assets/icons/markDamage.png";
import styles from "./carManage.module.css";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import CreateSubscriptionModal from "./CreateSubscription";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchAllCars } from "../../store/carManagement";
import { Select } from "@chakra-ui/react";
import { css } from "glamor";
import { fetchAllCategories } from "../../store/carManagement";
import ConfirmMarkDamage from "./ConfirmMarkDamage";
import { refreshToken } from "../../functions";
import chev from "../../assets/icons/chevBlue.png";
import Cookies from "js-cookie";
import QRCode from "react-qr-code";
import ViewQNR from "./ViewQRCode";

const ListedVehicle = () => {
  function capitalizeName(name) {
    return name?.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }
  const [openMarkDamage, setOpenMarkDamage] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [markDamageLoad, setMarkDamageLoad] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedCarId = useSelector((state) => state.cars.selectedListedCar);

  const allVehicles = useSelector((state) => state.cars.allVehicles);

  const selectedCarObject = allVehicles?.filter(
    (item) => item._id === selectedCarId
  );

  useEffect(() => {
    dispatch(fetchAllCategories);
  }, [dispatch]);

  const allCategories = useSelector((state) => state.cars.allCategories);

  const availableCategories = allCategories?.filter(
    (item) => item?.name !== selectedCarObject?.category
  );

  const schema = Yup.object().shape({
    carID: Yup.string().required("Vehicle ID is required"),
    carBrand: Yup.string().required("Vehicle Brand is required"),
    carModel: Yup.string().required("Vehicle Model is required"),
    seatingCapacity: Yup.string().required("Seating Capacity is required"),
    color: Yup.string().required("Vehicle Brand is required"),
    category: Yup.string().required("Category is required"),
  });

  const notifySuccess = () =>
    toast.success("Vehicle Updated", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const UpdateVehicle = (values) => {
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      brand: values.values.carBrand,
      color: values.values.color,
      model: values.values.carModel,
      seatCapacity: values.values.seatingCapacity,
      category: values.values.category,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`https://api.symboldrive.com/car/${selectedCarId}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (
          res.status === "false" &&
          res.message === "Please pass firebase auth token"
        ) {
          setMarkDamageLoad(false);
          refreshToken();
        }

        if (res.status === true) {
          notifySuccess();
          dispatch(fetchAllCars());
          setTimeout(() => {
            navigate("/carManagement/listedVehicles");
          }, 500);
        }
      });
  };

  const onMarkDamage = () => {
    setMarkDamageLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      isDamaged: false,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`https://api.symboldrive.com/car/${selectedCarId}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (
          res.status === "false" &&
          res.message === "Please pass firebase auth token"
        ) {
          setMarkDamageLoad(false);
          refreshToken();
        }
        if (res.status === true) {
          notifySuccess();
          dispatch(fetchAllCars());
          setTimeout(() => {
            navigate("/carManagement/listedVehicles");
          }, 500);
          setMarkDamageLoad(false);
        }
      });
  };

  const [uploadedFileURL, setUploadedFileUrl] = useState([]);

  const onFileDrop = (files) => {
    if (files[0] === undefined) {
      return;
    }

    var formdata = new FormData();
    formdata.append("type", "dl");
    formdata.append("file", files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.symboldrive.com/file-upload", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setUploadedFileUrl(result.urls);
        // setImageUploadLoader(false);
      });
  };

  const [showQNR, setShowQNR] = useState(false);
  const QNR = selectedCarObject[0]?.qrCodeImage;

  return (
    <div className="containerSubRight">
      <ViewQNR
        viewDoc={showQNR}
        setViewDoc={setShowQNR}
        doc={QNR}
        qnrCode={selectedCarObject[0]?.qnr}
      />
      <CreateSubscriptionModal
        openModal={openModal}
        setOpenModal={setOpenModal}
      />

      <ConfirmMarkDamage
        openModal={openMarkDamage}
        setOpenModal={setOpenMarkDamage}
        onMarkDamage={onMarkDamage}
        deleteCatLoading={markDamageLoad}
      />

      <ToastContainer />
      <div className="subRight-boundary ">
        <div className="heading-container border-bottom2">
          <div className="back-button">
            <img onClick={() => navigate(-1)} src={chev} />
            <h1>Damaged Vehicle</h1>
          </div>
          <div className="listed-buttons">
            {/* <button className="btn-revoke" onClick={() => setOpenModal(true)}>
            <img src={add} alt="Add" />
            Create Subscription
          </button> */}
            <button
              className="btn-suspend"
              onClick={() => setOpenMarkDamage(true)}
            >
              <img src={markDamage} alt="Add" />
              Revoke damage
            </button>
          </div>
        </div>
        <div className={styles.listedContainer}>
          {/* <div className={styles.accordion}>
          <h2 onClick={() => setSelectedTab("Listed")}>Listed Vehicles</h2>
          <h2 onClick={() => setSelectedTab("Subscription")}>Subscription</h2>
        </div> */}

          <div className={styles.listedVehicles}>
            <Formik
              validationSchema={schema}
              initialValues={{
                carID: selectedCarObject[0]?.qnr,
                carBrand: selectedCarObject[0]?.brand,
                carModel: selectedCarObject[0]?.model,
                seatingCapacity: selectedCarObject[0]?.seatCapacity,
                color: selectedCarObject[0]?.color,
                category: selectedCarObject[0]?.category,
              }}
              onSubmit={(values) => {
                UpdateVehicle({ values });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <div className={styles.carImageAndQNR}>
                    <div className={styles.carImageContainer}>
                      <img
                        className="carImage"
                        src={selectedCarObject[0]?.images[0]}
                      />
                    </div>
                    <div className={styles.dragAndDrop}>
                      <QRCode
                        style={{
                          height: "auto",
                          maxWidth: "50%",
                          width: "100%",
                        }}
                        value={selectedCarObject[0]?.qnr}
                        onClick={() => setShowQNR(true)}
                      />
                      {/* <img src={QNR} /> */}
                      <h4 className="blueText">
                        Symboldrive QNR : {selectedCarObject[0]?.qnr}{" "}
                      </h4>
                    </div>
                  </div>

                  <div className={styles.otherCarDetails}>
                    <div className={styles.column}>
                      <div className={styles.detail}>
                        <p className="grayText">Booking Status</p>
                        <p className="blackText">
                          {selectedCarObject[0]?.bookingStatus === "booked"
                            ? "Booked"
                            : "Available for booking"}
                        </p>
                      </div>
                      <div className={styles.detail}>
                        <p className="grayText">Fuel Level</p>
                        <p className="blackText">
                          {selectedCarObject[0]?.fuel_level}%
                        </p>
                      </div>
                      <div className={styles.detail}>
                        <p className="grayText">Position</p>
                        <p className="blackText">
                          {selectedCarObject[0]?.position?.coordinates}
                        </p>
                      </div>
                      <div className={styles.detail}>
                        <p className="grayText">Alarm Input</p>
                        <p className="blackText">
                          {selectedCarObject[0]?.alarm_input === "off"
                            ? "False"
                            : "True"}
                        </p>
                      </div>
                      <div className={styles.detail}>
                        <p className="grayText">Alarm Input 2</p>
                        <p className="blackText">
                          {selectedCarObject[0]?.alarm_input_2 === "off"
                            ? "Off"
                            : "On"}
                        </p>
                      </div>
                      <div className={styles.detail}>
                        <p className="grayText">Low level battery alarm</p>
                        <p className="blackText">
                          {selectedCarObject[0]?.low_battery_level_alarm
                            ? "On"
                            : "Off"}
                        </p>
                      </div>
                      <div className={styles.detail}>
                        <p className="grayText">Relay Value</p>
                        <p className="blackText">
                          {selectedCarObject[0]?.relay_value}
                        </p>
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.detail}>
                        <p className="grayText">Bluetooth Connection</p>
                        <p className="blackText">
                          {capitalizeName(
                            selectedCarObject[0]?.bluetooth_connection
                          )}
                        </p>
                      </div>
                      <div className={styles.detail}>
                        <p className="grayText">Board Voltage</p>
                        <p className="blackText">
                          {capitalizeName(
                            selectedCarObject[0]?.bluetooth_connection
                          )}
                        </p>
                      </div>
                      <div className={styles.detail}>
                        <p className="grayText">Central Lock</p>
                        <p className="blackText">
                          {capitalizeName(selectedCarObject[0]?.central_lock)}
                        </p>
                      </div>
                      <div className={styles.detail}>
                        <p className="grayText">Central Lock ( Last Command)</p>
                        <p className="blackText">
                          {capitalizeName(
                            selectedCarObject[0]?.central_lock_last_command
                          )}
                        </p>
                      </div>
                      <div className={styles.detail}>
                        <p className="grayText">Immobilizer</p>
                        <p className="blackText">
                          {capitalizeName(selectedCarObject[0]?.immobilizer)}
                        </p>
                      </div>
                      <div className={styles.detail}>
                        <p className="grayText">Mileage</p>
                        <p className="blackText">
                          {selectedCarObject[0]?.mileage}
                        </p>
                      </div>
                      <div className={styles.detail}>
                        <p className="grayText">
                          Mileage Since immobilizer unlock
                        </p>
                        <p className="blackText">
                          {
                            selectedCarObject[0]
                              ?.mileage_since_immobilizer_unlock
                          }
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className={styles.carID}>
                    <label>Vehicle ID</label>
                    <input
                      type="carID"
                      name="carID"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={selectedCarObject[0]?._id}
                      id="carID"
                      placeholder="SASDSAF-YA1246"
                      disabled
                    />

                    <p className="red">
                      {errors.carID && touched.carID && errors.carID}
                    </p>
                  </div>
                  <div className={styles.flexRow}>
                    <div className={styles.carID}>
                      <label>Vehicle Brand</label>
                      <input
                        type="carBrand"
                        name="carBrand"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.carBrand}
                        id="carBrand"
                        placeholder="Tesla"
                      />

                      <p className="red">
                        {errors.carBrand && touched.carBrand && errors.carBrand}
                      </p>
                    </div>

                    <div className={styles.carID}>
                      <label>Vehicle Model</label>
                      <input
                        type="carModel"
                        name="carModel"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.carModel}
                        id="carModel"
                        placeholder="Tesla"
                      />

                      <p className="red">
                        {errors.carModel && touched.carModel && errors.carModel}
                      </p>
                    </div>
                  </div>

                  <div className={styles.flexRow}>
                    {/* <div className={styles.carID}>
                      <label>Tracker Key</label>
                      <input
                        type="password"
                        name="trackerKey"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.trackerKey}
                        id="trackerKey"
                        placeholder="Tesla"
                      />

                      <p className="red">
                        {errors.trackerKey &&
                          touched.trackerKey &&
                          errors.trackerKey}
                      </p>
                    </div> */}

                    <div className={styles.carID}>
                      <label>Tracker Key</label>
                      <input type="password" value={"None"} disabled />

                      <p className="red">
                        {errors.fuelType && touched.fuelType && errors.fuelType}
                      </p>
                    </div>

                    <div className={styles.carID}>
                      <label>Seating Capacity</label>
                      <input
                        type="text"
                        name="seatingCapacity"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.seatingCapacity}
                        id="seatCapacity"
                        placeholder="7 Seater"
                      />

                      <p className="red">
                        {errors.seatingCapacity &&
                          touched.seatingCapacity &&
                          errors.seatingCapacity}
                      </p>
                    </div>
                  </div>

                  <div className={styles.flexRow}>
                    {/* <div className={styles.carID}>
                      <label>Tracker Key</label>
                      <input
                        type="password"
                        name="trackerKey"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.trackerKey}
                        id="trackerKey"
                        placeholder="Tesla"
                      />

                      <p className="red">
                        {errors.trackerKey &&
                          touched.trackerKey &&
                          errors.trackerKey}
                      </p>
                    </div> */}

                    <div className={styles.carID}>
                      <label>Price per hour (USD)</label>
                      <input
                        type="text"
                        name="seatingCapacity"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.seatingCapacity}
                        id="seatCapacity"
                        placeholder="7 Seater"
                        disabled
                      />
                      <p className="red">
                        {errors.fuelType && touched.fuelType && errors.fuelType}
                      </p>
                    </div>

                    <div className={styles.carID}>
                      <label>Enter miles per hour</label>
                      <input
                        type="text"
                        name="seatingCapacity"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.seatingCapacity}
                        id="seatCapacity"
                        placeholder="7 Seater"
                      />

                      <p className="red">
                        {errors.seatingCapacity &&
                          touched.seatingCapacity &&
                          errors.seatingCapacity}
                      </p>
                    </div>
                  </div>
                  <div className={styles.carID}>
                    <label>Category</label>
                    {/* <input
                      type="text"
                      name="category"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.category}
                      id="category"
                      placeholder="SUV/Sedan"
                    /> */}

                    <Select
                      placeholder={values.category}
                      marginTop="10px"
                      marginBottom="10px"
                      background={"#f3f2f3"}
                      height="56px"
                      id="category"
                      value={values.category}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {availableCategories.map((item) => (
                        <option value={item.name}>{item.name}</option>
                      ))}
                    </Select>

                    <p className="red">
                      {errors.category && touched.category && errors.category}
                    </p>
                  </div>
                  <div className={styles.carID}>
                    <label>Color</label>
                    <input
                      type="text"
                      name="color"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.color}
                      id="color"
                      placeholder="Color"
                    />

                    <p className="red">
                      {errors.color && touched.color && errors.color}
                    </p>
                  </div>

                  {/* <div className={styles.carID}>
                    <label>Device ID (Invers)</label>
                    <input
                      type="carID"
                      name="carID"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.carID}
                      id="carID"
                      placeholder="SASDSAF-YA1246"
                    />

                    <p className="red">
                      {errors.carID && touched.carID && errors.carID}
                    </p>
                  </div>

                  <div className={styles.flexRow}>
                    <div className={styles.carID}>
                      <label>Car Brand</label>
                      <input
                        type="carBrand"
                        name="carBrand"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.carBrand}
                        id="carBrand"
                        placeholder="Tesla"
                      />

                      <p className="red">
                        {errors.carBrand && touched.carBrand && errors.carBrand}
                      </p>
                    </div>

                    <div className={styles.carID}>
                      <label>Car Model</label>
                      <input
                        type="carModel"
                        name="carModel"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.carModel}
                        id="carModel"
                        placeholder="Tesla"
                      />

                      <p className="red">
                        {errors.carModel && touched.carModel && errors.carModel}
                      </p>
                    </div>
                  </div> */}
                  {/* 
                  <div className={styles.carID}>
                    <label>Category</label> */}
                  {/* <input
                      type="text"
                      name="category"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.category}
                      id="category"
                      placeholder="SUV/Sedan"
                    /> */}
                  {/* 
                    <Select
                      placeholder={values.category}
                      marginTop="10px"
                      marginBottom="10px"
                      background={"#f3f2f3"}
                      height="56px"
                      id="category"
                      value={values.category}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {availableCategories.map((item) => (
                        <option value={item.name}>{item.name}</option>
                      ))}
                    </Select> */}

                  {/* <p className="red">
                      {errors.category && touched.category && errors.category}
                    </p>
                  </div> */}

                  {/* <div className={styles.carID}>
                    <label>Color</label>
                    <input
                      type="text"
                      name="color"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.color}
                      id="color"
                      placeholder="Color"
                    />

                    <p className="red">
                      {errors.color && touched.color && errors.color}
                    </p>
                  </div> */}

                  {/* <div className={styles.flexRow}> */}
                  {/* <div className={styles.carID}>
                      <label>Tracker Key</label>
                      <input
                        type="password"
                        name="trackerKey"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.trackerKey}
                        id="trackerKey"
                        placeholder="Tesla"
                      />

                      <p className="red">
                        {errors.trackerKey &&
                          touched.trackerKey &&
                          errors.trackerKey}
                      </p>
                    </div> */}

                  {/* <div className={styles.carID}>
                      <label>Fuel Type</label>
                      <input
                        type="text"
                        name="fuelType"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.fuelType}
                        id="fuelType"
                        placeholder="Electric/Fuel"
                      />

                      <p className="red">
                        {errors.fuelType && touched.fuelType && errors.fuelType}
                      </p>
                    </div> */}
                  {/* 
                    <div className={styles.carID}>
                      <label>Seating Capacity</label>
                      <input
                        type="text"
                        name="seatingCapacity"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.seatingCapacity}
                        id="seatCapacity"
                        placeholder="7 Seater"
                      />

                      <p className="red">
                        {errors.seatingCapacity &&
                          touched.seatingCapacity &&
                          errors.seatingCapacity}
                      </p>
                    </div>
                  </div> */}

                  {/* <div className={styles.flexRow}>
                    <div className={styles.carID}>
                      <label>Price per hour (USD)</label>
                      <input
                        type="number"
                        name="pricePerHour"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pricePerHour}
                        id="trackerKey"
                        placeholder="25"
                      />

                      <p className="red">
                        {errors.pricePerHour &&
                          touched.pricePerHour &&
                          errors.pricePerHour}
                      </p>
                    </div>

                    <div className={styles.carID}>
                      <label>Enter miles per hour</label>
                      <input
                        type="carModel"
                        name="milesPerHour"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.milesPerHour}
                        id="carModel"
                        placeholder="10"
                        disabled
                      />

                      <p className="red">
                        {errors.milesPerHour &&
                          touched.milesPerHour &&
                          errors.milesPerHour}
                      </p>
                    </div>
                  </div> */}

                  {/* <div className={styles.flexRow}>
                    <div className={styles.carID}>
                      <label>Tracker Key</label>
                      <input
                        type="text"
                        name="trackerKey"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.trackerKey}
                        id="trackerKey"
                        placeholder="White"
                      />

                      <p className="red">
                        {errors.trackerKey &&
                          touched.trackerKey &&
                          errors.trackerKey}
                      </p>
                    </div>

                    <div className={styles.carID}>
                      <label>Select working zone</label>
                      <input
                        type="carModel"
                        name="workingZone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.workingZone}
                        id="carModel"
                        placeholder="Select working zone of car"
                      />

                      <p className="red">
                        {errors.workingZone &&
                          touched.workingZone &&
                          errors.workingZone}
                      </p>
                    </div>
                  </div> */}

                  <div className={styles.actionButtons}>
                    <button type="submit" className="btn-revoke center">
                      Update
                    </button>

                    <button onClick={() => navigate(-1)}>Cancel</button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListedVehicle;
