import React, { useEffect, useState } from "react";
import DamagedVehiclesTable from "../../components/Car management/Damaged Vehicles/table";
import { BarLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import "react-toastify/dist/ReactToastify.css";
import HardDeleteVehicle from "./HardDeleteVehicle";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { fetchAllCars } from "../../store/carManagement";
import { refreshToken } from "../../functions";
import { useParams } from "react-router-dom";

const DamagedVehicles = () => {
  const status = useSelector((state) => state.cars.status);
  const dispatch = useDispatch();
  const [id, setId] = useState("");

  const filterParams = useParams();
  const filter = String(filterParams.filter).slice(1);

  useEffect(() => {
    if (filter !== "ndefined") {
      dispatch(fetchAllCars(filter));
    } else {
    }
  }, [dispatch]);

  const [openModal, setOpenModal] = useState(false);
  const [deleteUserLoading, setDeleteUserLoading] = useState(false);

  const notifySuccess = () =>
    toast.success("Vehicle deleted.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const onDeleteVehicle = () => {
    setDeleteUserLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      isDeleted: true,
    });

    var route = "/carManagement/listedVehicles";

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(`https://api.symboldrive.com/car/${id}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (
          res.status === "false" &&
          res.message === "Please pass firebase auth token"
        ) {
          setDeleteUserLoading(false);
          refreshToken(route);
        }
        if (res.status === true) {
          setDeleteUserLoading(false);
          notifySuccess();
          dispatch(fetchAllCars());
          setOpenModal(false);
        }
      });
  };

  return (
    <>
      {status === "loading" ? (
        <div className="containerSubRight">
          <div className="loader-container">
            <BarLoader color="#031569" />
          </div>
        </div>
      ) : (
        <>
          <ToastContainer />

          <HardDeleteVehicle
            openModal={openModal}
            setOpenModal={setOpenModal}
            onDeleteVehicle={onDeleteVehicle}
            deleteLoading={deleteUserLoading}
            // deleteCatLoading={deleteCatLoading}
          />

          <DamagedVehiclesTable setOpenModal={setOpenModal} setId={setId} />
        </>
      )}
    </>
  );
};

export default DamagedVehicles;
