import React, { useMemo } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import chevRightRed from "../../../assets/icons/chevRightBlue.png";
import chevLeftRed from "../../../assets/icons/chevLeftBlue.png";
import eye from "../../../assets/icons/eye2.png";
import suspend from "../../../assets/icons/reject.png";
import styles from "./style.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { carActions } from "../../../store/carManagement";
import moment from "moment";
import { GlobalFilter } from "./GlobalFilter";

const ActiveCustomerTable = ({ setOpenModal, setId }) => {
  const damagedV = useSelector((state) => state.cars.damagedVehicles);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkEmptySpace = (string) => {
    if (string === "") {
      return "--";
    } else {
      return string;
    }
  };

  const data = useMemo(() => damagedV, []);

  const onViewClick = (id) => {
    dispatch(carActions.setListedCar(id));
    navigate(`/carManagement/damagedVehicle/:${id}`);
  };

  const onSuspendClick = (id) => {
    setId(id);
    setOpenModal(true);
  };

  function capitalizeName(name) {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  const COLUMNS_NEWCUSTOMER = [
    {
      Header: () => <p className={styles.headerID}>Date</p>,
      accessor: "date",
      Cell: (tableProps) => (
        <p className={styles.id}>
          {moment(tableProps.row.original.date).format("MM-DD-YYYY")}
        </p>
      ),
    },
    {
      Header: "Vehicle ID ",
      accessor: "carID",
      Cell: (tableProps) => (
        <p>{String(tableProps.row.original.carID).slice(-4)}</p>
      ),
    },

    {
      Header: "Invers(QNR) ",
      accessor: "qnr",
      Cell: (tableProps) => (
        <p>{checkEmptySpace(tableProps.row.original.qnr)}</p>
      ),
    },
    {
      Header: "Model",
      accessor: "brand",
      Cell: (tableProps) => (
        <p>
          {checkEmptySpace(tableProps.row.original.brand)}{" "}
          {checkEmptySpace(tableProps.row.original.model)}
        </p>
      ),
    },
    // {
    //   Header: "Fuel level",
    //   accessor: "fuelLevel",
    // },

    {
      Header: "Category",
      accessor: "category",
      Cell: (tableProps) => (
        <p className="">{tableProps.row.original.category}</p>
      ),
    },
    // {
    //   Header: "Seating Capacity",
    //   accessor: "seatingCapacity",
    // },
    // {
    //   Header: "Mileage",Mileage
    //   accessor: "mileage",
    // },
    {
      Header: "Booking status",
      accessor: "bookingStatus",
      Cell: (tableProps) => (
        <p>{capitalizeName(tableProps.row.original.status)}</p>
      ),
    },

    {
      Header: "Status",
      accessor: "status",
      Cell: (tableProps) => (
        <p>{capitalizeName(tableProps.row.original.status)}</p>
      ),
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: (tableProps) => (
        <div className="actionsCategories">
          <button
            className="btn-edit"
            onClick={() => onViewClick(tableProps.row.original.carID)}
          >
            <img src={eye} alt="View" />
          </button>

          <button
            className="btn-edit"
            onClick={() => onSuspendClick(tableProps.row.original.carID)}
          >
            <img className="suspend" src={suspend} alt="Suspend" />
          </button>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS_NEWCUSTOMER, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  return (
    <div className="containerSubRight">
      <div className="heading-container">
        <h1>Damaged Cars</h1>
        {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
        {/* <ToastContainer />

        <ConfirmDeleteAdModal
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          id={selectedID}
          notifySuccess={notifySuccess}
          notifyError={notifyError}
        /> */}

        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>

      {damagedV.length === 0 ? (
        <h1 className="emptyData">No damaged vehicles.</h1>
      ) : (
        <>
          <div className="tableCont">
            <table {...getTableProps()}>
              <thead className="tableHeader">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="table-row" {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <>
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}{" "}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="tableFooters">
            {/* <div className="pageInfo">
            {"\u00a0"} Go to page number : {"\u00a0"}
            <input
              type="number"
              default={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
            />
          </div> */}
            <div className="tableButtons">
              {/* <button
              className="jumpButton"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {" "}
              Go to first page
            </button> */}
              <button
                className="tableNav"
                disabled={!canPreviousPage}
                onClick={() => previousPage()}
              >
                <img src={chevLeftRed} />
              </button>
              <p className="pageNumber">
                {" "}
                {"\u00a0"}
                Showing page {pageIndex + 1}
                {"\u00a0"}of {pageOptions.length}
              </p>
              <button
                className="tableNav"
                disabled={!canNextPage}
                onClick={() => nextPage()}
              >
                <img src={chevRightRed} />
              </button>
              {/* <button
              className="jumpButton"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              Go to last page
            </button> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ActiveCustomerTable;
