import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { refreshToken } from "../functions";
import Cookies from "js-cookie";

const initialDashboardState = {
  allStats: {},
  userCount: 0,
  vehicleCount: 0,
  rideCount: 0,
  revenue: 0,
  fetchStatus: "idle",
};

export const fetchAllStats = createAsyncThunk(
  "dashboard/data-count",
  async () => {
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(
      "https://api.symboldrive.com/dashboard/data-count",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.status === true) {
          return res;
        }

        if (
          res.status === "error" ||
          res.error.code === "auth/id-token-expired"
        ) {
          refreshToken();
        }
      });
  }
);

const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState: initialDashboardState,
  reducers: {},
  extraReducers: {
    [fetchAllStats.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchAllStats.fulfilled]: (state, { payload }) => {
      state.fetchStatus = "succeeded";
      state.allStats = payload.data;
    },

    [fetchAllStats.rejected]: (state, action) => {
      state.fetchStatus = "failed";
    },
  },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice;
