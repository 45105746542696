import React, { useEffect, useMemo, useState } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import { GlobalFilter } from "./filter";
import chevRightRed from "../../../assets/icons/chevRightBlue.png";
import plus from "../../../assets/icons/addIcon.png";
import chevLeftRed from "../../../assets/icons/chevLeftBlue.png";
import eye from "../../../assets/icons/eye2.png";
import suspend from "../../../assets/icons/reject.png";
import styles from "./style.css";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { carActions } from "../../../store/carManagement";
import Cookies from "js-cookie";
import { BarLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "glamor";
import { fetchAllCars } from "../../../store/carManagement";

const FilteredTable = ({ setOpenModal, setId, filter }) => {
  const listedV = useSelector((state) => state.cars.filteredCars);

  const checkType = (filter) => {
    if (filter === "isDamaged=true") {
      return "Damaged vehicles";
    }
    if (filter === "bookingStatus=booked&isDamage=true") {
      return "Booked vehicles ";
    }
    if (filter === "bookingStatus=idle") {
      return "Idle vehicles ";
    }
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const data = useMemo(() => listedV, []);

  const onViewClick = (id) => {
    dispatch(carActions.setListedCar(id));
    navigate(`/carManagement/listedVehicles/:${id}`);
  };

  const onSuspendClick = (id) => {
    setId(id);
    setOpenModal(true);
  };

  function capitalizeName(name) {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  const checkEmptySpace = (string) => {
    if (string === "") {
      return "--";
    } else {
      return string;
    }
  };

  const COLUMNS_NEWCUSTOMER = [
    {
      Header: () => <p className={styles.headerID}>Car ID</p>,
      accessor: "carID",
      Cell: (tableProps) => (
        <p className={styles.id}>
          {String(tableProps.row.original.carID).slice(-4)}
        </p>
      ),
    },

    {
      Header: "Invers(QNR) ",
      accessor: "qnr",
      Cell: (tableProps) => <p>{tableProps.row.original.qnr}</p>,
    },
    {
      Header: "Brand",
      accessor: "brand",
      Cell: (tableProps) => (
        <p>{checkEmptySpace(tableProps.row.original.brand)}</p>
      ),
    },
    {
      Header: "Fuel level",
      accessor: "fuelLevel",
      Cell: (tableProps) => (
        <p>{checkEmptySpace(tableProps.row.original.fuelLevel)}</p>
      ),
    },

    {
      Header: "Ignition",
      accessor: "ignition",
      Cell: (tableProps) => (
        <p className="">{checkEmptySpace(tableProps.row.original.ignition)}</p>
      ),
    },
    // {
    //   Header: "Seating Capacity",
    //   accessor: "seatingCapacity",
    // },
    {
      Header: "Mileage",
      accessor: "mileage",
      Cell: (tableProps) => (
        <p>
          {tableProps.row.original.mileage !== undefined
            ? tableProps.row.original.mileage
            : "--"}
        </p>
      ),
    },
    {
      Header: "Booking status",
      accessor: "bookingStatus",
      Cell: (tableProps) => (
        <p>{capitalizeName(tableProps.row.original.bookingStatus)}</p>
      ),
    },

    {
      Header: "Damage",
      accessor: "isDamaged",
      Cell: (tableProps) => (
        <p>{tableProps.row.original.isDamaged ? "Yes" : "No"}</p>
      ),
    },

    {
      Header: "Status",
      accessor: "status",
      Cell: (tableProps) => (
        <p>{capitalizeName(tableProps.row.original.status)}</p>
      ),
    },

    {
      Header: "Actions",
      accessor: "actions",
      Cell: (tableProps) => (
        <div className="actionsCategories">
          <button
            className="btn-edit"
            onClick={() => onViewClick(tableProps.row.original.carID)}
          >
            <img src={eye} alt="View" />
          </button>

          <button
            className="btn-edit"
            onClick={() => onSuspendClick(tableProps.row.original.carID)}
          >
            <img className="suspend" src={suspend} alt="Suspend" />
          </button>
        </div>
      ),
    },
  ];

  const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const [onInverseLoad, setOnInverseLoad] = useState(false);

  const inverseRefresh = () => {
    setOnInverseLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://api.symboldrive.com/car/refresh", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.status === true) {
          setOnInverseLoad(false);
          if (res.carsAdded > 0) {
            notifySuccess(`${res.carsAdded} cars added`);
            dispatch(fetchAllCars());
          }
          if (res.carsAdded === 0) {
            notifySuccess(`No new cars in Invers to add.`);
          }
        }
        if (res.status === "error") {
          setOnInverseLoad(false);
        }
      });
  };

  const columns = useMemo(() => COLUMNS_NEWCUSTOMER, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  return (
    <div className="containerSubRight">
      <div className="heading-container">
        <h1>{checkType(filter)}</h1>

        <div className="addButtonAndSearch">
          {/* <NavLink to="" */}
          {/* <button
            onClick={() => navigate("/carManagement/addVehicle")}
            className="btn-addVehicle"
            style={{ marginRight: "10px" }}
          >
            <img src={plus} alt="add" />
            Add Vehicle
          </button> */}

          {/* <button
            // onClick={() => navigate("/carManagement/addVehicle")}
            onClick={() => inverseRefresh()}
            className="btn-addVehicle"
            style={{ marginRight: "10px" }}
          >
            <img src={plus} alt="add" />
            {onInverseLoad ? <BarLoader color="white" /> : "Invers refresh"}
          </button> */}

          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
        {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
        {/* <ToastContainer />

        <ConfirmDeleteAdModal
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          id={selectedID}
          notifySuccess={notifySuccess}
          notifyError={notifyError}
        /> */}
      </div>

      <ToastContainer />

      {listedV.length === 0 ? (
        <h1 className="emptyData">No vehicles to display.</h1>
      ) : (
        <>
          <div className="tableCont">
            <table {...getTableProps()}>
              <thead className="tableHeader">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="table-row" {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <>
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}{" "}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="tableFooters">
            {/* <div className="pageInfo">
            {"\u00a0"} Go to page number : {"\u00a0"}
            <input
              type="number"
              default={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
            />
          </div> */}
            <div className="tableButtons">
              {/* <button
              className="jumpButton"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {" "}
              Go to first page
            </button> */}
              <button
                className="tableNav"
                disabled={!canPreviousPage}
                onClick={() => previousPage()}
              >
                <img src={chevLeftRed} />
              </button>
              <p className="pageNumber">
                {" "}
                {"\u00a0"}
                Showing page {pageIndex + 1}
                {"\u00a0"}of {pageOptions.length}
              </p>
              <button
                className="tableNav"
                disabled={!canNextPage}
                onClick={() => nextPage()}
              >
                <img src={chevRightRed} />
              </button>
              {/* <button
              className="jumpButton"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              Go to last page
            </button> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FilteredTable;
