import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { refreshToken } from "../functions";
import Cookies from "js-cookie";

const initialNotificationState = {
  allNotifications: [],
  notificationFetchStatus: "idle",
  selectedNotificationId: "",
  selectedNotificationObject: [],
};

export const fetchAllNotifications = createAsyncThunk(
  "notifications/fetchNotifications",
  async () => {
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));

    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    return fetch(
      "https://api.symboldrive.com/notification?receiver=admin",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.status === true) {
          return res;
        }
        if (
          res.status === "false" ||
          res.error.code === "auth/id-token-expired"
        ) {
          refreshToken();
        }
      });
  }
);

const notificationsSlice = createSlice({
  name: "notificationsSlice",
  initialState: initialNotificationState,
  reducers: {
    setAllNotifications(state, action) {
      state.allNotifications = action.payload;
    },
    setSelectedNotificationId(state, action) {
      state.selectedNotificationId = action.payload;
      state.selectedNotificationObject = state.allNotifications.filter(
        (item) => item._id === action.payload
      );
    },
  },
  extraReducers: {
    [fetchAllNotifications.pending]: (state, action) => {
      state.notificationFetchStatus = "loading";
    },
    [fetchAllNotifications.fulfilled]: (state, action) => {
      state.notificationFetchStatus = "succeeded";
      state.allNotifications = action.payload.notifications;
    },
    [fetchAllNotifications.rejected]: (state, action) => {
      state.notificationFetchStatus = "failed";
    },
  },
});

export const notificationsActions = notificationsSlice.actions;

export default notificationsSlice;
