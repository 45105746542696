import React, { useState, useEffect } from "react";
import styles from "./newCustomer.module.css";
import chev from "../../assets/icons/chevBlue.png";
import warning from "../../assets/icons/warning.png";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import ConfirmRevokeUser from "./ConfirmRevokeUser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "glamor";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAllUsers, fetchUserById } from "../../store/users";
import userAlt from "../../assets/images/user.svg";
import imageAlt from "../../assets/images/gallery.svg";
import ViewDocModal from "./ViewDocModal";
import { fetchAllRidesbyUser } from "../../store/rideManagement";
import UserRides from "../../components/customers/User Rides/table";
import animate from "../../assets/images/animate.gif";
import { refreshToken } from "../../functions";
import { BarLoader } from "react-spinners";
import Cookies from "js-cookie";
import ApproveModal from "./ConfirmApprove";

import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import ConfirmDelete from "./ConfirmRevokeSuspension";

const SuspendedCustomer = () => {
  const selectedUserObject = useSelector(
    (state) => state.users.selectedUserObject
  );

  const [selectedTab, setSelectedTab] = useState("Previous Rides");

  const getUserStatus = useSelector((state) => state.users.fetchByIdStatus);

  const id = useParams();
  const userID = String(id.userId).slice(1);
  const dispatch = useDispatch();

  const status = useSelector((state) => state.rides.fetchAllRidesByUserStatus);

  useEffect(() => {
    if (!selectedUserObject.length) {
      dispatch(fetchUserById(userID));
    }
  }, [dispatch, selectedUserObject.length, userID]);

  useEffect(() => {
    dispatch(fetchAllRidesbyUser(userID));
  }, [dispatch, userID]);

  const navigate = useNavigate();

  const notifySuccess = () =>
    toast.success("Successfully revoked", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  function capitalizeName(name) {
    return name?.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  const onImageError = (ev) => {
    ev.target.src = userAlt;
  };

  const onImageError2 = (ev) => {
    ev.target.src = imageAlt;
  };

  const token = Cookies.get("token");

  const [openRevokeModel, setOpenRevokeModel] = useState(false);
  const [openApproveModel, setOpenApproveModel] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);

  const [revokeSuspensionLoading, setRevokeSuspensionLoading] = useState(false);

  const onRevokeSuspensionClick = () => {
    setRevokeSuspensionLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("token", token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`https://api.symboldrive.com/user/${userID}/revoke`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.status === true) {
          setRevokeSuspensionLoading(false);
          notifySuccess();
          dispatch(fetchAllUsers());
          setTimeout(() => {
            navigate(-1);
          }, 500);
        }
        if (
          res.status === "error" ||
          res.error.code === "auth/id-token-expired"
        ) {
          setRevokeSuspensionLoading(false);
          refreshToken();
        }
      });
  };

  const onRevokeClick = () => {
    setApproveLoading(true);
    // setRevokeSuspensionLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("token", token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://api.symboldrive.com/user/${userID}/revoke-rejection`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.status === true) {
          setApproveLoading(false);
          notifySuccess();
          dispatch(fetchAllUsers());
          setTimeout(() => {
            navigate(-1);
          }, 500);
        }
        if (
          res.status === "error" ||
          res.error.code === "auth/id-token-expired"
        ) {
          setApproveLoading(false);
          refreshToken();
        }
      });
  };
  const [viewDoc, setViewDoc] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState("");

  const openDoc = (doc) => {
    setSelectedDoc(doc);
    setViewDoc(true);
  };

  const slides = [
    {
      src: selectedDoc,
    },
  ];

  const dataBlock = (
    <>
      <div className={styles.infoAndDocuments}>
        <div className={styles.cuInfo}>
          <h1>User Information</h1>
          <div className={styles.row}>
            <h3>User Name:</h3>
            <h3>{capitalizeName(selectedUserObject[0]?.name)}</h3>
          </div>
          <div className={styles.row}>
            <h3>Date of birth:</h3>
            <h3>{moment(selectedUserObject[0]?.dob).format("MM-DD-YYYY")}</h3>
          </div>
          <div className={styles.row}>
            <h3>Mobile number:</h3>
            <h3>{selectedUserObject[0]?.phone}</h3>
          </div>
          <div className={styles.row}>
            <h3>Email ID:</h3>
            <h3>{selectedUserObject[0]?.email}</h3>
          </div>
          <div className={styles.row}>
            <h3>Address:</h3>
            <h3>
              {selectedUserObject[0]?.address
                ? selectedUserObject[0]?.address
                : "N/A"}
            </h3>
          </div>
        </div>

        <div className={styles.docInfo}>
          <h1>Document Information</h1>
          <div className={styles.row}>
            <h3>Driving Licence No.:</h3>
            <h3>{selectedUserObject[0]?.dl.licenceNumber}</h3>
          </div>
          <div className={styles.row}>
            <h3>Expiry Date:</h3>
            <h3>
              {moment(selectedUserObject[0]?.dl.validTill).format("MM-DD-YYYY")}
            </h3>
          </div>
          <div className={styles.row}>
            <h3>Mobile number:</h3>
            <h3>{selectedUserObject[0]?.phone}</h3>
          </div>
          <div className={styles.row}>
            <h3>Insurance Number:</h3>
            <h3>
              {selectedUserObject[0]?.insurance?.insuranceNumber
                ? selectedUserObject[0]?.insurance?.insuranceNumber
                : "N/A"}
            </h3>
          </div>
          <div className={styles.row}>
            <h3>Valid Upto:</h3>
            <h3>
              {moment(selectedUserObject[0]?.insurance.validTills).format(
                "MM-DD-YYYY"
              )}
            </h3>
          </div>
        </div>
      </div>
    </>
  );

  const renderDetails = () => {
    if (selectedUserObject.length > 0) {
      return dataBlock;
    }

    if (!selectedUserObject.length) {
      if (getUserStatus === "loading") {
        return (
          <div className="refreshLoader">
            <BarLoader color="#031569" />
          </div>
        );
      }

      if (getUserStatus === "succeeded") {
        return dataBlock;
      }
    }
  };

  //     isApproved: "true",
  //     isSuspended: "false",

  // const onApproveClick = () => {
  //   setApproveLoading(true);
  //   var myHeaders = new Headers();
  //   myHeaders.append("token", token);
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     isApproved: "true",
  //     isSuspended: "false",
  //   });

  //   var requestOptions = {
  //     method: "PATCH",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch(`https://api.symboldrive.com/user/${userID}`, requestOptions)
  //     .then((response) => response.json())
  //     .then((res) => {
  //       if (
  //         res.status === "error" &&
  //         res.error.code === "auth/id-token-expired"
  //       ) {
  //         setApproveLoading(false);
  //         refreshToken();
  //       }
  //       if (res.status === true) {
  //         setApproveLoading(false);
  //         notifySuccess("User Approved");
  //         dispatch(fetchAllUsers());
  //         setTimeout(() => {
  //           navigate(-1);
  //         }, 500);
  //       }
  //     });
  // };

  return (
    <>
      <div className={styles.newCustomer}>
        <ApproveModal
          openApproveModal={openApproveModel}
          setOpenApproveModal={setOpenApproveModel}
          onApproveClick={onRevokeClick}
          approveLoading={approveLoading}
        />

        <ConfirmRevokeUser
          openModal={openRevokeModel}
          setOpenModal={setOpenRevokeModel}
          revokeRejection={onRevokeSuspensionClick}
          revokeSuspensionLoading={revokeSuspensionLoading}
        />

        <Lightbox
          open={viewDoc}
          close={() => setViewDoc(false)}
          slides={slides}
          plugins={[Zoom]}
        />

        <ToastContainer />

        <div className={`${styles.heading} ${styles.spaceBetween}`}>
          <div className={styles.fRow}>
            <img
              onClick={() => navigate(-1)}
              className={styles.marginRight}
              src={chev}
              alt="back"
            />
            <h2>
              {selectedUserObject[0]?.isRejected ? "Rejected" : "Suspended"}{" "}
              customer
            </h2>
          </div>
          {!selectedUserObject[0]?.isRejected && (
            <button
              onClick={() => setOpenRevokeModel(true)}
              className="btn-revoke long"
            >
              Revoke suspension
            </button>
          )}

          {selectedUserObject[0]?.isRejected && (
            <button
              onClick={() => setOpenApproveModel(true)}
              className="btn-revoke long"
            >
              Revoke Rejection
            </button>
          )}
        </div>
        <div className="suspensionNotice">
          <img onImageError={onImageError} src={warning} alt="notice" />
          <p>
            This account was{" "}
            {!selectedUserObject[0]?.isRejected ? "suspended" : "rejected"} on{" "}
            {moment(selectedUserObject[0]?.updatedAt).format("MM-DD-YYYY")}.
            Admin remark: {selectedUserObject[0]?.suspensionReason}
          </p>
        </div>
        <img
          alt="userImage"
          className="cuImage"
          src={selectedUserObject[0]?.image}
        />

        {renderDetails()}

        <div className={styles.accordion}>
          <div className={styles.buttons}>
            <button
              className={`${styles.tab} ${
                selectedTab === "Previous Rides"
                  ? `${styles.selectedTab}`
                  : `${styles.normalTab}`
              }`}
              onClick={() => setSelectedTab("Previous Rides")}
            >
              Previous Rides
            </button>

            <button
              className={` ${styles.tab} ${
                selectedTab === "Uploaded Docs"
                  ? `${styles.selectedTab}`
                  : `${styles.normalTab}`
              }`}
              onClick={() => setSelectedTab("Uploaded Docs")}
            >
              Uploaded Documents
            </button>
          </div>

          <div>
            {selectedTab === "Previous Rides" ? (
              <div className={styles.previousRideTable}>
                <>
                  {status === "loading" ? (
                    <div className="loader-container2">
                      <img alt="loading" src={animate} />
                    </div>
                  ) : (
                    <UserRides />
                  )}
                </>
              </div>
            ) : (
              <div className={styles.docImages}>
                {/* <h1>Uploaded Documents</h1> */}
                <div className={styles.downloadContainers}>
                  <div className={styles.licence}>
                    <h3>Drivers Licence</h3>
                    <img
                      onError={onImageError2}
                      src={selectedUserObject[0]?.dl?.image}
                      alt="Licence"
                      onClick={() => openDoc(selectedUserObject[0]?.dl?.image)}
                    />
                  </div>

                  <div className={styles.insurance}>
                    <h3>Insurance Document</h3>
                    {selectedUserObject[0]?.insurance?.image && (
                      <img
                        onError={onImageError2}
                        src={selectedUserObject[0]?.insurance?.image}
                        alt="Licence"
                        onClick={() =>
                          openDoc(selectedUserObject[0]?.insurance?.image)
                        }
                      />
                    )}

                    {!selectedUserObject[0]?.insurance?.image && (
                      <>
                        <img alt="Ins" onError={onImageError2} src={imageAlt} />
                        Not uploaded
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SuspendedCustomer;
