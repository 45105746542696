import React, { useMemo, useState } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import { GlobalFilter } from "./GlobalFilter";
import chevRightRed from "../../../assets/icons/chevRightBlue.png";
import chevLeftRed from "../../../assets/icons/chevLeftBlue.png";
import styles from "./style.css";
import { useNavigate } from "react-router-dom";
import userAlt from "../../../assets/images/user.svg";

import eye from "../../../assets/icons/eye2.png";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../../store/users";
import moment from "moment";

const SuspendedCustomerTable = () => {
  const suspendedUsers = useSelector((state) => state.users.suspendedUsers);
  const dispatch = useDispatch();

  const onViewClick = (id) => {
    dispatch(userActions.setSelectedUserId(id));

    navigate(`/users/suspendedUsers/:${id}`);
  };

  const navigate = useNavigate();
  const data = useMemo(() => suspendedUsers, []);
  const onImageError = (ev) => {
    ev.target.src = userAlt;
  };

  function capitalizeName(name) {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  const COLUMNS_NEWCUSTOMER = [
    {
      Header: () => <p className={styles.headerID}>User Id</p>,
      accessor: "_id",
      Cell: (tableProps) => (
        <p className={styles.id}>
          {String(tableProps.row.original._id).slice(-4)}
        </p>
      ),
    },
    {
      Header: "User name",
      accessor: "name",
      Cell: (tableProps) => (
        <div className="nameAndImage">
          <img onError={onImageError} src={tableProps.row.original.image} />
          <p
            className="nameBox"
            // onClick={() => onRowClick(tableProps.row.original.name)}
          >
            {capitalizeName(tableProps.row.original.name)}
          </p>
        </div>
      ),
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: (tableProps) => (
        <p className={styles.id}>
          {tableProps.row.original.phone
            ? tableProps.row.original.phone
            : "Not provided"}
        </p>
      ),
    },

    {
      Header: "Date of suspension",
      accessor: "dateOfSuspension",
      Cell: (tableProps) => (
        <p className="red">
          {moment(tableProps.row.original.updatedAt).format("MM-DD-YYYY")}
        </p>
      ),
    },
    {
      Header: "Reason of suspension",
      accessor: "reason",
      Cell: (tableProps) => (
        <p className="red">{tableProps.row.original.suspensionReason}</p>
      ),
    },

    {
      Header: "Status",
      accessor: "rejected",
      Cell: (tableProps) => (
        <p className="">
          {tableProps.row.original.isRejected ? "Rejected" : "Suspended"}
        </p>
      ),
    },

    {
      Header: "Actions",
      accessor: "actions",
      Cell: (tableProps) => (
        <div className="actionsCategories">
          <button
            className="btn-edit"
            onClick={() => onViewClick(tableProps.row.original._id)}
          >
            <img src={eye} alt="View" />
          </button>

          {/* <button
              className="btn-delete"
              onClick={() => onDeleteAdClick(tableProps.row.original.id)}
            >
              <img src={trash} alt="delete" />
            </button> */}
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS_NEWCUSTOMER, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  return (
    <div className="containerSubRight">
      <div className="heading-container">
        <h1>Suspended Users</h1>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        {/* <ToastContainer />

        <ConfirmDeleteAdModal
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          id={selectedID}
          notifySuccess={notifySuccess}
          notifyError={notifyError}
        /> */}
      </div>

      <>
        <div className="tableCont">
          <table {...getTableProps()}>
            <thead className="tableHeader">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr className="table-row" {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <>
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}{" "}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="tableFooters">
          {/* <div className="pageInfo">
            {"\u00a0"} Go to page number : {"\u00a0"}
            <input
              type="number"
              default={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
            />
          </div> */}
          <div className="tableButtons">
            {/* <button
              className="jumpButton"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {" "}
              Go to first page
            </button> */}
            <button
              className="tableNav"
              disabled={!canPreviousPage}
              onClick={() => previousPage()}
            >
              <img src={chevLeftRed} />
            </button>
            <p className="pageNumber">
              {" "}
              {"\u00a0"}
              Showing page {pageIndex + 1}
              {"\u00a0"}of {pageOptions.length}
            </p>
            <button
              className="tableNav"
              disabled={!canNextPage}
              onClick={() => nextPage()}
            >
              <img src={chevRightRed} />
            </button>
            {/* <button
              className="jumpButton"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              Go to last page
            </button> */}
          </div>
        </div>
      </>
    </div>
  );
};

export default SuspendedCustomerTable;
