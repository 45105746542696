import React from "react";
import Modal from "react-modal";
import styles from "../Car Management/carManage.module.css";
import { Button } from "@chakra-ui/react";
import close from "../../assets/icons/crossNew.png";

const ConfirmDelete = ({
  openModal,
  setOpenModal,
  deleteUser,
  deleteUserLoading,
  setSuspensionReason,
}) => {
  const customStyles = {
    content: {
      top: "50%;",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      height: "fit-content",
    },
  };

  const bg = {
    overlay: {
      background: "rgb(48,48,48,0.9)",
    },
  };

  return (
    <Modal
      className={` ${customStyles}`}
      isOpen={openModal}
      shouldCloseOnEsc={true}
      onRequestClose={() => setOpenModal(false)}
      style={bg}
    >
      <div className={styles.confirmDeleteModal}>
        <div className={styles.modal}>
          <div className={styles.cross}>
            <img
              alt="N/A"
              className="suspend"
              src={close}
              onClick={() => setOpenModal(false)}
            />
          </div>
          <h1 className={styles.modalHeading}>
            Please type a reason to suspend this user.
          </h1>

          <textarea
            type="text"
            onChange={(e) => setSuspensionReason(e.target.value)}
            className="suspensionTextarea"
          />

          <div className={styles.actionButtons2}>
            <Button
              width="174.81px"
              height="52.19px"
              background="#031569"
              colorScheme="blue"
              size="md"
              variant="solid"
              borderRadius="80px"
              type="submit"
              isLoading={deleteUserLoading}
              onClick={() => deleteUser()}
            >
              Confirm
            </Button>

            <button
              className="btn-reset center"
              type="submit"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDelete;
