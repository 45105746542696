import React, { useEffect, useState } from "react";
import styles from "./login.module.css";
import banner from "../../assets/icons/banner.png";
import * as Yup from "yup";
import { Formik } from "formik";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSessionToken, authActions } from "../../store/auth";
import { BarLoader } from "react-spinners";
import topRight from "../../assets/images/Top-right.png";
import bottomLeft from "../../assets/images/bottom-left.png";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth1 } from "../../firebase";

const Login = () => {
  const [loginLoad, setLoginLoad] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const schema = Yup.object().shape({
    userID: Yup.string().required("User ID is required"),
    password: Yup.string().required("Password is required"),
  });

  const [showError, setShowError] = useState(false);

  const login2 = async (values) => {
    setLoginLoad(true);
    try {
      setShowError(false);
      const response = await signInWithEmailAndPassword(
        auth1,
        values.userID,
        values.password
      );

      var myHeaders = new Headers();
      myHeaders.append("token", response.user.accessToken);
      var raw = "";
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const loginResponse = await fetch(
        "https://api.symboldrive.com/user/login",
        requestOptions
      );

      const data = await loginResponse.json();

      if (data.status && data.user.role === "admin") {
        dispatch(authActions.setAuthentication(true));
        dispatch(getSessionToken(data.user.firebaseUid));
        navigate("/");
      } else if (data.user.role !== "admin") {
        setShowError(true);
        setLoginLoad(false);
      }
    } catch (error) {
      setShowError(true);
      setLoginLoad(false);
    }
  };
  return (
    <div className={styles.loginContainer}>
      <div className={styles.image}>
        <div className="imgCont">
          <img className="topRight" src={topRight}></img>
        </div>
        <img className="banner" src={banner} />
        <div className="imgCont">
          <img className="bottomLeft" src={bottomLeft}></img>
        </div>
      </div>

      <div className={styles.loginBox}>
        <Formik
          validationSchema={schema}
          initialValues={{
            userID: "",
            password: "",
          }}
          onSubmit={(values) => login2(values)}
          // dispatch(
          //   authActions.login({
          //     email: values.userID,
          //     password: values.password,
          //     setLoginLoad,
          //   })
          // );
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div className={styles.text}>
                <h1>Login</h1>
                <p>Hi, Welcome back 👋</p>
              </div>
              <div className={styles.carID}>
                <label>User ID</label>
                <input
                  type="text"
                  name="userID"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.userID}
                  id="userID"
                  placeholder="Enter User ID"
                />

                <p className="red">
                  {errors.userID && touched.userID && errors.userID}
                </p>
              </div>

              <div className={styles.carID}>
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  id="password"
                  placeholder="Enter Password"
                />

                <p className="red">
                  {errors.password && touched.password && errors.password}
                </p>
              </div>

              {showError && <p className="red">Invalid Access</p>}

              <button type="submit" className="btn-login">
                {loginLoad ? <BarLoader color="white" /> : "Login"}
              </button>
            </form>
          )}
        </Formik>
        <p className="copyRight">Copyright 2023</p>
      </div>
    </div>
  );
};

export default Login;
