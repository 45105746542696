import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AllRidesTable from "../../components/Ride Management/All Rides/table";
import { fetchAllRides } from "../../store/rideManagement";
import { ToastContainer } from "react-toastify";
import animate from "../../assets/images/animate.gif";
import { refreshToken } from "../../functions";
import { useParams } from "react-router-dom";

const AllRides = () => {
  const status = useSelector((state) => state.rides.fecthAllRideStatus);
  const dispatch = useDispatch();

  const id = useParams();
  const filter = String(id.filter).slice(1);

  useEffect(() => {
    if (filter !== undefined) {
      dispatch(fetchAllRides(filter));
    } else {
      dispatch(fetchAllRides());
    }

    // dispatch(fetchAllRides());
  }, [dispatch]);

  useEffect(() => {
    if (status?.error?.code === "auth/id-token-expired") {
      refreshToken();
    }
  }, []);

  return (
    <>
      <ToastContainer />

      {status === "loading" ? (
        <div className="loader-container">
          <img alt="loading" src={animate} />
        </div>
      ) : (
        <AllRidesTable filter={filter} />
      )}
    </>
  );
};

export default AllRides;
