import React, { useRef, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import abc from "../../assets/images/carIcon11.png";
const libraries = ["places", "drawing"];

const MapComponent2 = ({ allCars }) => {
  const mapRef = useRef();

  const coords = allCars?.map((item) => [
    {
      lat: item?.position?.coordinates[1],
      lng: item?.position?.coordinates[0],
      name: item.brand + " " + item.model,
    },
  ]);

  const filteredCoords = coords.filter(
    (item) => item[0].lat !== undefined && item[0].lng !== undefined
  );

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const defaultCenterCar = allCars.filter((item) => item?.model === "Prius");

  const defaultCente = defaultCenterCar[0]?.position?.coordinates;
  const defaultCenter = defaultCente && {
    lat: defaultCente[1],
    lng: defaultCente[0],
  };

  const [center, setCenter] = useState(defaultCenter);

  const containerStyle = {
    width: "100%",
    height: "600px",
  };

  const onLoadMap = (map) => {
    mapRef.current = map;
    return <p>"Loading, Please wait"</p>;
  };

  const onCarClick = () => {
    // console.log("Clicked!");
  };

  return isLoaded ? (
    <>
      <div className="map-container" style={{ position: "relative" }}>
        <GoogleMap
          zoom={10}
          center={center}
          onLoad={onLoadMap}
          mapContainerStyle={containerStyle}
          // onTilesLoaded={() => setCenter(null)}
        >
          {filteredCoords?.map((item) => {
            return (
              <>
                <Marker
                  icon={abc}
                  position={item[0]}
                  onClick={() => onCarClick()}
                  name={item.name}
                />
                {/* <h1> {item.name}</h1> */}
              </>
            );
          })}
        </GoogleMap>
      </div>
    </>
  ) : null;
};

export default MapComponent2;
