import React, { useEffect } from "react";
import animate from "../../assets/images/animate.gif";
import NewCustomerTable from "../../components/customers/new customers/table";
import { useSelector } from "react-redux";
import { fetchAllUsers } from "../../store/users";

import { useDispatch } from "react-redux";

const NewCustomers = () => {
  const status = useSelector((state) => state.users.userFetchStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);

  return (
    <>
      {status === "loading" ? (
        <div className="containerSubRight">
          <div className="loader-container">
            <img alt="loading" src={animate} />
          </div>
        </div>
      ) : (
        <NewCustomerTable />
      )}
    </>
  );
};

export default NewCustomers;
