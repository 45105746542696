import React, { useState, useEffect } from "react";
import styles from "./sidebar.module.css";
import notif from "../../assets/icons/notifictions.png";
import dash from "../../assets/icons/dashboard.png";
import user1 from "../../assets/icons/user.png";
import car from "../../assets/icons/car.png";
import ride from "../../assets/icons/ride.png";
import payments from "../../assets/icons/payments.png";
import geo from "../../assets/icons/geo.png";
import { useNavigate, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import logouticon from "../../assets/icons/logOutIcon.png";
import { auth1, logout } from "../../firebase";
import NavBar from "../navbar/NavBar";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import Cookies from "js-cookie";

const Sidebar = (props) => {
  const [openUserSubTab, setOpenUserSubTab] = useState(false);
  const [openCarSubTab, setOpenCarAddSubTab] = useState(false);
  const [openRideSubTab, setOpenRideSubTab] = useState(false);
  const [openPaymentSubTab, setOpenPaymentSubTab] = useState(false);
  const [openGeoSubTab, setOpenGeoSubTab] = useState(false);
  const [user, loading, error] = useAuthState(auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const onLogOut = () => {
    logout(auth1);
    dispatch(authActions.setAuthentication(false));
    dispatch(authActions.loginUser(null));
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, loading]);

  const [notificationCount, setNotificationCount] = useState("");

  const fetchNotificationCount = async () => {
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const data = await fetch(
      `https://api.symboldrive.com/notification/unread/count`,
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
        // const notificationResponse = data.wait();
        // console.log(notificationResponse, "nOTIF");

        setNotificationCount(result.unreadNotificationCount);
      });
  };

  useEffect(() => {
    fetchNotificationCount();
  }, []);

  return (
    <div className="container">
      <NavBar />
      <div className="dashboardView">
        <div className={`${styles.sidebar}`}>
          <div className={`${styles.menu}`}>
            <NavLink
              onClick={() => {
                setOpenUserSubTab(false);
                setOpenCarAddSubTab(false);
                setOpenRideSubTab(false);
                setOpenPaymentSubTab(false);
                setOpenGeoSubTab(false);
              }}
              style={{ textDecoration: "none" }}
              to={"/"}
              className={styles.link}
              activeclassname="active"
            >
              <img alt="logo" className={styles.icon} src={dash} />
              Dashboard
            </NavLink>
            <NavLink
              onClick={() => {
                setOpenUserSubTab(!openUserSubTab);
                setOpenCarAddSubTab(false);
                setOpenRideSubTab(false);
                setOpenPaymentSubTab(false);
                setOpenGeoSubTab(false);
              }}
              // style={{ textDecoration: "none" }}
              to={"/users"}
              className={styles.link}
              activeclassname="active"
            >
              <img alt="logo" className={styles.icon} src={user1} />
              User Management
            </NavLink>

            {openUserSubTab && (
              <div className={styles.userSubCategory}>
                <button
                  onClick={() => {
                    navigate("/users/newUsers");
                  }}
                  className={
                    location.pathname === "/users/newUsers" ||
                    location.pathname === "/users" ||
                    location.pathname.includes("/users/newUsers")
                      ? "selectedTab"
                      : "normalTab"
                  }
                >
                  New Users
                </button>

                <button
                  onClick={() => {
                    navigate("/users/activeUsers");
                  }}
                  className={
                    location.pathname === "/users/activeUsers" ||
                    location.pathname.includes("/users/activeUsers")
                      ? "selectedTab"
                      : "normalTab"
                  }
                >
                  Active Users
                </button>

                <button
                  onClick={() => {
                    navigate("/users/suspendedUsers");
                  }}
                  className={
                    location.pathname === "/users/suspendedUsers" ||
                    location.pathname.includes("/users/suspendedUsers")
                      ? "selectedTab"
                      : "normalTab"
                  }
                >
                  Suspended Users
                </button>
              </div>
            )}
            <NavLink
              onClick={() => {
                setOpenUserSubTab(false);
                setOpenCarAddSubTab(!openCarSubTab);
                setOpenRideSubTab(false);
                setOpenPaymentSubTab(false);
                setOpenGeoSubTab(false);
              }}
              style={{ textDecoration: "none" }}
              to={"/carManagement"}
              className={styles.link}
              activeclassname="active"
            >
              <img alt="logo" className={styles.icon2} src={car} />
              Vehicle Management
            </NavLink>

            {openCarSubTab && (
              <div className={styles.userSubCategory}>
                <button
                  onClick={() => {
                    navigate("/carManagement/addCategory");
                  }}
                  className={
                    location.pathname === "/carManagement/addCategory" ||
                    location.pathname === "/carManagement"
                      ? "selectedTab"
                      : "normalTab"
                  }
                >
                  Categories
                </button>

                <button
                  onClick={() => {
                    navigate("/carManagement/listedVehicles");
                  }}
                  className={
                    location.pathname === "/carManagement/listedVehicles" ||
                    location.pathname.includes("/carManagement/listedVehicles")
                      ? "selectedTab"
                      : "normalTab"
                  }
                >
                  Listed vehicles
                </button>

                <button
                  onClick={() => {
                    navigate("/carManagement/damagedVehicles");
                  }}
                  className={
                    location.pathname === "/carManagement/damagedVehicles" ||
                    location.pathname.includes("/carManagement/damagedVehicle")
                      ? "selectedTab"
                      : "normalTab"
                  }
                >
                  Damaged vehicles
                </button>
              </div>
            )}
            <NavLink
              onClick={() => {
                setOpenUserSubTab(false);
                setOpenCarAddSubTab(false);
                setOpenRideSubTab(!openRideSubTab);
                setOpenPaymentSubTab(false);
                setOpenGeoSubTab(false);
              }}
              style={{ textDecoration: "none" }}
              to={"/rideManagement"}
              className={styles.link}
              activeclassname={styles.active}
            >
              <img alt="logo" className={styles.icon} src={ride} />
              Ride Management
            </NavLink>

            {openRideSubTab && (
              <div className={styles.userSubCategory}>
                <button
                  onClick={() => {
                    navigate("/rideManagement");
                  }}
                  className={
                    location.pathname === "/rideManagement/allRides" ||
                    location.pathname === "/rideManagement" ||
                    location.pathname.includes("/rideManagement/allRides")
                      ? "selectedTab"
                      : "normalTab"
                  }
                >
                  All Rides
                </button>

                <button
                  onClick={() => {
                    navigate("/rideManagement/ongoingRides");
                  }}
                  className={
                    location.pathname === "/rideManagement/ongoingride" ||
                    location.pathname.includes("/rideManagement/ongoingRide")
                      ? "selectedTab"
                      : "normalTab"
                  }
                >
                  Ongoing Ride
                </button>

                <button
                  onClick={() => {
                    navigate("/rideManagement/completedRide");
                  }}
                  className={
                    location.pathname === "/rideManagement/completedRide" ||
                    location.pathname.includes("/rideManagement/completedRide")
                      ? "selectedTab"
                      : "normalTab"
                  }
                >
                  Completed Rides
                </button>

                <button
                  onClick={() => {
                    navigate("/rideManagement/reportedRide");
                  }}
                  className={
                    location.pathname === "/rideManagement/reportedRide" ||
                    location.pathname.includes("/rideManagement/reportedRide")
                      ? "selectedTab"
                      : "normalTab"
                  }
                >
                  Reported Rides
                </button>
              </div>
            )}

            <NavLink
              onClick={() => {
                setOpenUserSubTab(false);
                setOpenCarAddSubTab(false);
                setOpenRideSubTab(false);
                setOpenPaymentSubTab(!openPaymentSubTab);
                setOpenGeoSubTab(false);
              }}
              style={{ textDecoration: "none" }}
              to={"/payments"}
              className={styles.link}
              activeclassname={styles.active}
            >
              <img alt="logo" className={styles.icon} src={payments} />
              Payments
            </NavLink>

            <NavLink
              onClick={() => {
                setOpenUserSubTab(false);
                setOpenCarAddSubTab(false);
                setOpenRideSubTab(false);
                setOpenPaymentSubTab(false);
                setOpenGeoSubTab(!openGeoSubTab);
              }}
              style={{ textDecoration: "none" }}
              to={"/geoFencing"}
              className={styles.link}
              activeclassname={styles.active}
            >
              <img alt="logo" className={styles.icon} src={geo} />
              Geo Fencing
            </NavLink>

            {openGeoSubTab && (
              <div className={styles.userSubCategory}>
                <button
                  onClick={() => {
                    navigate("/geoFencing/geoFencingList");
                  }}
                  className={
                    location.pathname === "/geoFencing/geoFencingList" ||
                    location.pathname === "/geoFencing"
                      ? "selectedTab"
                      : "normalTab"
                  }
                >
                  Zone listing
                </button>

                <button
                  onClick={() => {
                    navigate("/geoFencing/addParking");
                  }}
                  className={
                    location.pathname === "/geoFencing/addParking"
                      ? "selectedTab"
                      : "normalTab"
                  }
                >
                  Bird's eye view
                </button>

                <button
                  onClick={() => {
                    navigate("/geoFencing/parking");
                  }}
                  className={
                    location.pathname === "/geoFencing/parking"
                      ? "selectedTab"
                      : "normalTab"
                  }
                >
                  Parking
                </button>
              </div>
            )}

            <NavLink
              onClick={() => {
                setOpenUserSubTab(false);
                setOpenCarAddSubTab(false);
                setOpenRideSubTab(false);
                setOpenPaymentSubTab(false);
                setOpenGeoSubTab(false);
              }}
              style={{ textDecoration: "none" }}
              to={"/notifications"}
              className={styles.link}
              activeclassname="active"
            >
              <img alt="logo" className={styles.icon} src={notif} />
              Notifications
              {notificationCount > 0 ? (
                <div className="notification">
                  {" "}
                  <p>{notificationCount}</p>{" "}
                </div>
              ) : (
                ""
              )}
            </NavLink>

            <button onClick={() => onLogOut()} className={styles.logout}>
              <img alt="logo" className={styles.icon2} src={logouticon} />
              <p className="red">Logout</p>
            </button>
          </div>
        </div>
        <main className={styles.rightSide}>{props.children}</main>
      </div>
    </div>
  );
};

export default Sidebar;
