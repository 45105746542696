import styles from "./navbar.module.css";
import React from "react";
import logo from "../../assets/icons/symboldrivelogo.png";
import search from "../../assets/icons/search.png";
import admin1 from "../../assets/images/adminAvatar.jpg";

const NavBar = () => {
  return (
    <div className={styles.navContainer}>
      <div className={`${styles.navbar} d-flex `}>
        <div className={styles.logoAndSearch}>
          <img className="logo" src={logo} alt="Logo" />
          {/* <div className="search d-flex ">
          <img src={search} alt={search} />
          <input placeholder="Search car, customer, booking details etc" />
        </div> */}
        </div>

        <div className="profileOptions">
          <h2>Admin</h2>
          <img className="adminImage" src={admin1} alt="img" />
          {/* <img className="chevDown" src={chevDown} alt="img" /> */}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
