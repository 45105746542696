import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./carManage.module.css";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "glamor";
import { fetchAllCars } from "../../store/carManagement";
import { fetchAllCategories } from "../../store/carManagement";
import { Select } from "@chakra-ui/react";
import { Upload } from "./Uploader";
import Cookies from "js-cookie";
import { refreshToken } from "../../functions";

const AddVehicle = () => {
  const schema = Yup.object().shape({
    carID: Yup.string().required("Vehicle ID is required"),
    carBrand: Yup.string().required("Vehicle Brand is required"),
    carModel: Yup.string().required("Vehicle Model is required"),
    fuelType: Yup.string().required("Fuel type is required"),
    color: Yup.string().required("Color is required"),
    seatingCapacity: Yup.string().required("Seating Capacity is required"),
    category: Yup.string().required("Category is required"),
  });

  const dispatch = useDispatch();

  const allVehicles = useSelector((state) => state.cars.allVehicles);
  const selectedCarId = useSelector((state) => state.cars.selectedListedCar);

  useEffect(() => {
    dispatch(fetchAllCategories);
  }, [dispatch]);

  const selectedCarObject = allVehicles?.filter(
    (item) => item._id === selectedCarId
  );

  const allCategories = useSelector((state) => state.cars.allCategories);

  const availableCategories = allCategories?.filter(
    (item) => item?.name !== selectedCarObject?.category
  );

  const navigate = useNavigate();

  const notifySuccess = () =>
    toast.success("Vehicle Added", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const notifyError = () => {
    toast.error("Session expired, Please log in again to continue", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#ed5565 !important",
      }),
    });
  };

  const [uploadedFileURL, setUploadedFileUrl] = useState("");

  const submitForm = (values) => {
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      qnr: values.carID,
      brand: values.carBrand,
      model: values.carModel,
      seatCapacity: values.seatingCapacity,
      color: values.color,
      carType: values.carType,
      category: "suv",
      isDamaged: false,
      images: uploadedFileURL,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.symboldrive.com/car", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (
          res.status === "false" &&
          res.error.code === "auth/id-token-expired"
        ) {
          refreshToken();
        }
        if (res.status === true) {
          notifySuccess();
          dispatch(fetchAllCars());
          setTimeout(() => {
            navigate("/carManagement/listedVehicles");
          }, 500);
        }

        if (res.status === "error") {
          notifyError();
        }
      });
  };

  const onFileDrop = (files) => {
    var formdata = new FormData();
    formdata.append("type", "dl");
    formdata.append("file", files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.symboldrive.com/file-upload", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setUploadedFileUrl(result.urls);
      });
  };

  return (
    <div className="containerSubRight">
      <div className="heading-container">
        <h1>Add a new vehicle</h1>
      </div>
      <div className={styles.listedContainer}>
        <div className={styles.listedVehicles}>
          <ToastContainer />

          <Formik
            validationSchema={schema}
            initialValues={{
              carID: "",
              carBrand: "",
              carModel: "",
              fuelType: "",
              color: "",
              seatingCapacity: "",
              category: "",
            }}
            onSubmit={(values) => {
              submitForm(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Upload onDrop={(files) => onFileDrop(files)} />

                <div className={styles.carID}>
                  <label>Device ID (Invers QNR)</label>
                  <input
                    type="text"
                    name="carID"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.carID}
                    id="carID"
                    placeholder="Enter device ID"
                  />

                  <p className="red">
                    {errors.carID && touched.carID && errors.carID}
                  </p>
                </div>

                <div className={styles.flexRow}>
                  <div className={styles.carID}>
                    <label>Vehicle Brand</label>
                    <input
                      type="text"
                      name="carBrand"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.carBrand}
                      id="carBrand"
                      placeholder="Tesla"
                    />

                    <p className="red">
                      {errors.carBrand && touched.carBrand && errors.carBrand}
                    </p>
                  </div>

                  <div className={styles.carID}>
                    <label>Vehicle Model</label>
                    <input
                      type="text"
                      name="carModel"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.carModel}
                      id="carModel"
                      placeholder="Tesla"
                    />

                    <p className="red">
                      {errors.carModel && touched.carModel && errors.carModel}
                    </p>
                  </div>
                </div>

                <div className={styles.flexRow}>
                  <div className={styles.carID}>
                    <label>Category</label>

                    <Select
                      placeholder={values.category}
                      marginTop="10px"
                      marginBottom="10px"
                      background={"#f3f2f3"}
                      height="56px"
                      id="category"
                      value={values.category}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {availableCategories.map((item) => (
                        <option value={item.name}>{item.name}</option>
                      ))}
                    </Select>

                    <p className="red">
                      {errors.category && touched.category && errors.category}
                    </p>
                  </div>

                  <div className={styles.carID}>
                    <label>Fuel Type</label>

                    <input
                      // disabled
                      type="text"
                      name="fuelType"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fuelType}
                      id="fuelType"
                      placeholder="Electric/Fuel"
                    />

                    <p className="red">
                      {errors.fuelType && touched.fuelType && errors.fuelType}
                    </p>
                  </div>
                </div>

                <div className={styles.carID}>
                  <label>Color</label>
                  <input
                    type="text"
                    name="color"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.color}
                    id="color"
                    placeholder="Color"
                  />

                  <p className="red">
                    {errors.color && touched.color && errors.color}
                  </p>
                </div>

                <div className={styles.carID}>
                  <label>Seating Capacity</label>
                  <input
                    type="number"
                    name="seatingCapacity"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.seatingCapacity}
                    id="seatingCapacity"
                    placeholder="7 Seater"
                  />

                  <p className="red">
                    {errors.seatingCapacity &&
                      touched.seatingCapacity &&
                      errors.seatingCapacity}
                  </p>
                </div>

                <div className={styles.actionButtons}>
                  <button className="btn-revoke center" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddVehicle;
