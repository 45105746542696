import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllStats } from "../../store/dashboard";
import styles from "./dashboard.module.css";
import animate from "../../assets/images/animate.gif";
import { getCarPosition } from "../../store/carManagement";
import MapComponent2 from "./BirdEye";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const dispatch = useDispatch();

  const stats = useSelector((state) => state.dashboard.allStats);
  const status = useSelector((state) => state.dashboard.fetchStatus);

  useEffect(() => {
    if (status === "succeeded") {
    } else {
      dispatch(fetchAllStats());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCarPosition());
  }, [dispatch]);

  React.useEffect(() => {
    setInterval(() => {
      dispatch(getCarPosition());
      dispatch(fetchAllStats());
    }, 180000);
  }, [dispatch]);

  const allCars = useSelector((state) => state.cars.carPosition);

  const navigate = useNavigate();

  return (
    <>
      {status === "loading" ? (
        <div className="containerSubRight">
          <div className="loader-container">
            <img alt="loading" src={animate} />
          </div>
        </div>
      ) : (
        <>
          <div className={styles.dashBoardContainer}>
            <h1 className={styles.heading}>Overview</h1>

            <div className={styles.cardRow2}>
              <div
                className={styles.dashcard}
                onClick={() => navigate("users/activeUsers")}
              >
                <p className={styles.cardTitle}>Active Users</p>
                <p className={styles.cardStats}> {stats.approvedUserCount}</p>
              </div>
              <div
                className={styles.dashcard}
                onClick={() => navigate("users")}
              >
                <p className={styles.cardTitle}>New Users</p>
                <p className={styles.cardStats}> {stats.unapprovedUserCount}</p>
              </div>

              <div
                className={styles.dashcard}
                onClick={() => navigate("users/suspendedUsers")}
              >
                <p className={styles.cardTitle}>Suspended Users</p>
                <p className={styles.cardStats}> {stats.suspendedUserCount} </p>
              </div>

              <div className={styles.dashcard}>
                <p className={styles.cardTitle}>Total Users</p>
                <p className={styles.cardStats}>
                  {stats.approvedUserCount +
                    stats.unapprovedUserCount +
                    stats.suspendedUserCount}
                </p>
              </div>
            </div>

            <div className={styles.mapContainer}>
              <MapComponent2 allCars={allCars} />
            </div>

            <h1 className={styles.heading}>Ride Booking</h1>

            <div className={styles.cardRow2}>
              <div
                className={styles.dashcard}
                onClick={() => navigate("rideManagement/:ongoing")}
              >
                <p className={styles.cardTitle}>Ongoing</p>
                <p className={styles.cardStats}>{stats.ongoingBookingCount}</p>
              </div>
              <div
                className={styles.dashcard}
                onClick={() => navigate("rideManagement/reportedRide")}
              >
                <p className={styles.cardTitle}>Reported</p>
                <p className={styles.cardStats}> {stats.reportedRideCount}</p>
              </div>
              <div
                className={styles.dashcard}
                onClick={() => navigate("rideManagement/:completed")}
              >
                <p className={styles.cardTitle}>Completed</p>
                <p className={styles.cardStats}>
                  {" "}
                  {stats.completedBookingCount}{" "}
                </p>
              </div>
              <div
                className={styles.dashcard}
                onClick={() => navigate("rideManagement/:cancelled")}
              >
                <p className={styles.cardTitle}>Cancelled</p>
                <p className={styles.cardStats}>
                  {" "}
                  {stats.cancelledBookingCount}{" "}
                </p>
              </div>
            </div>

            <h1 className={styles.heading}>Vehicle Count</h1>

            <div className={styles.cardRow2}>
              <div className={styles.dashcard}>
                <p
                  className={styles.cardTitle}
                  onClick={() => navigate("carManagement")}
                >
                  Total Vehicles
                </p>
                <p className={styles.cardStats}>{stats.carCount}</p>
              </div>
              <div
                className={styles.dashcard}
                onClick={() =>
                  navigate("carManagement/:bookingStatus=booked&isDamage=true")
                }
              >
                <p className={styles.cardTitle}>Booked Vehicles</p>
                <p className={styles.cardStats}> {stats.bookedCarCount}</p>
              </div>
              <div
                className={styles.dashcard}
                onClick={() => navigate("carManagement/:bookingStatus=idle")}
              >
                <p className={styles.cardTitle}>Idle Vehicles</p>
                <p className={styles.cardStats}> {stats.idleCarCount} </p>
              </div>
              <div
                className={styles.dashcard}
                onClick={() => navigate("carManagement/:isDamaged=true")}
              >
                <p className={styles.cardTitle}>Damaged Vehicles</p>
                <p className={styles.cardStats}> {stats.damagedCarCount} </p>
              </div>
            </div>
          </div>
          {/* */}

          {/* <div className={styles.dashboardContainer}>
            <div className={styles.dashboardCard}>
              <h2>Rides Booking</h2>
              <p>Ongoing : {stats.ongoingBookingCount}</p>
              <p>Reported : {stats.reportedRideCount}</p>
              <p>Completed : {stats.completedBookingCount}</p>
              <p>Cancelled : {stats.cancelledBookingCount}</p>
            </div>

            <div className={styles.dashboardCard}>
              <h2>Car Count</h2>
              <p>Total Cars : {stats.carCount}</p>
              <p>Booked : {stats.bookedCarCount}</p>
              <p>Idle: {stats.idleCarCount}</p>
              <p>Damaged: {stats.damagedCarCount}</p>
            </div>

            <div className={styles.dashboardCard}>
              <h2>Users</h2>
              <p>Acive : {stats.approvedUserCount}</p>
              <p>Unapproved : {stats.unapprovedUserCount}</p>
              <p>Suspended : {stats.suspendedUserCount}</p>
            </div>
          </div> */}
        </>
      )}
    </>
  );
};

export default Dashboard;
