import React, { useMemo } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import chevRightRed from "../../assets/icons/chevRightBlue.png";
import chevLeftRed from "../../assets/icons/chevLeftBlue.png";
import eye from "../../assets/icons/eye2.png";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { GlobalFilter } from "./filter";
import { paymentActions } from "../../store/payments";

const CompletedPayments = () => {
  const tableData2 = useSelector((state) => state.payments.allPayments);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const data = useMemo(() => tableData2, []);

  const onViewClick = (id) => {
    dispatch(paymentActions.setSelectedPaymentId(id));
    navigate(`payments/:${id}`);
  };

  function capitalizeName(name) {
    return name?.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  const COLUMNS_NEWCUSTOMER = [
    {
      Header: () => <p>Id</p>,
      accessor: "_id",
      Cell: (tableProps) => (
        <p>{String(tableProps.row.original._id).slice(-4)}</p>
      ),
    },
    {
      Header: "Stripe Id",
      accessor: "stripePaymentDetail.id",
      Cell: (tableProps) => (
        <p>
          {String(tableProps.row.original.stripePaymentDetail.id).slice(-4)}
        </p>
      ),
    },
    {
      Header: "User name",
      accessor: "user.name",
      Cell: (tableProps) => (
        <p>
          {tableProps.row.original.user?.name
            ? capitalizeName(tableProps.row.original.user?.name)
            : "User deleted"}
        </p>
      ),
    },

    {
      Header: "Amount",
      accessor: "amount",
      Cell: (tableProps) => <p>$ {tableProps.row.original.amount}</p>,
    },
    {
      Header: "Date of payment",
      accessor: "createdAt",
      Cell: (tableProps) => (
        <p>{moment(tableProps.row.original.createdAt).format("MM-DD-YYYY")}</p>
      ),
    },

    {
      Header: "Actions",
      accessor: "actions",
      Cell: (tableProps) => (
        <div className="actionsCategories">
          <button
            className="btn-edit"
            onClick={() => onViewClick(tableProps.row.original._id)}
          >
            <img src={eye} alt="View" />
          </button>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS_NEWCUSTOMER, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  return (
    <div className="containerSubRight">
      <div className="heading-container">
        <h1>Payments</h1>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>

      <>
        <div className="tableCont">
          <table {...getTableProps()}>
            <thead className="tableHeader">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr className="table-row" {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <>
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}{" "}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="tableFooters">
          {/* <div className="pageInfo">
            {"\u00a0"} Go to page number : {"\u00a0"}
            <input
              type="number"
              default={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
            />
          </div> */}
          <div className="tableButtons">
            {/* <button
              className="jumpButton"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {" "}
              Go to first page
            </button> */}
            <button
              className="tableNav"
              disabled={!canPreviousPage}
              onClick={() => previousPage()}
            >
              <img src={chevLeftRed} />
            </button>
            <p className="pageNumber">
              {" "}
              {"\u00a0"}
              Showing page {pageIndex + 1}
              {"\u00a0"}of {pageOptions.length}
            </p>
            <button
              className="tableNav"
              disabled={!canNextPage}
              onClick={() => nextPage()}
            >
              <img src={chevRightRed} />
            </button>
            {/* <button
              className="jumpButton"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              Go to last page
            </button> */}
          </div>
        </div>
      </>
    </div>
  );
};

export default CompletedPayments;

// import React from "react";

// const CompletedPaymentsTable = () => {
//   return <div>Completed Payments Table</div>;
// };

// export default CompletedPaymentsTable;
