import React from "react";
import CompletedRideTable from "../../components/Ride Management/Completed Ride/table";
import { useSelector } from "react-redux";
import animate from "../../assets/images/animate.gif";

const CompletedRide = () => {
  const status = useSelector((state) => state.rides.fecthAllRideStatus);

  return (
    <>
      {status === "loading" ? (
        <div className="loader-container">
          <img alt="loading" src={animate} />
        </div>
      ) : (
        <>
          <CompletedRideTable />
        </>
      )}
    </>
  );
};

export default CompletedRide;
