import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { refreshToken } from "../functions";

const initialCarState = {
  allVehicles: [],
  listedVehicles: [],
  bookedAndDamaged: [],
  damagedVehicles: [],
  selectedCarID: "",
  selectedCarObject: {},
  status: "idle",
  categoryStatus: "idle",
  allCategories: [],
  selectedListedCar: "",
  selectedDamagedCar: "",
  selectedCategory: "",
  fetchCarByIdObject: {},
  fetchCarStatus: "idle",
  carPosition: [],
  getCarPositionStatus: "idle",
  getFilteredCarsStatus: "idle",
  filteredCars: [],
};

export const getCarPosition = createAsyncThunk(
  "carManagement/getCarPosition",
  async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch("https://api.symboldrive.com/car", requestOptions)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === true) {
          return res;
        }

        if (
          res.status === "error" ||
          res.error.code === "auth/id-token-expired"
        ) {
          refreshToken();
        }
      });
  }
);

export const getFilteredCars = createAsyncThunk(
  "carManagement/getFilteredCars",
  async (filter) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch("https://api.symboldrive.com/car?" + filter, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === true) {
          return res;
        }
        if (
          res.status === "error" ||
          res.error.code === "auth/id-token-expired"
        ) {
          refreshToken();
        }
      });
  }
);

export const fetchAllCars = createAsyncThunk(
  "carManagement/fetchCars",
  async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch("https://api.symboldrive.com/car", requestOptions)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === true) {
          return res;
        }

        if (
          res.status === "error" ||
          res.error.code === "auth/id-token-expired"
        ) {
          refreshToken();
        }
      });

    // if (filter.toString() !== "ndefined" ) {
    //   console.log("With filter");
    //   return fetch("https://api.symboldrive.com/car?" + filter, requestOptions)
    //     .then((res) => res.json())
    //     .then((res) => {
    //       if (res.status === true) {
    //         return res;
    //       }
    //       if (
    //         res.status === "error" ||
    //         res.error.code === "auth/id-token-expired"
    //       ) {
    //         refreshToken();
    //       }
    //     });

    // }
  }
);

export const fetchCarByid = createAsyncThunk(
  "carManagement/fetchCarsById",
  async (id) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch(`https://api.symboldrive.com/car/${id}`, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === true) {
          return res;
        }

        if (
          res.status === "error" ||
          res.error.code === "auth/id-token-expired"
        ) {
          refreshToken();
        }
      });
  }
);

export const fetchAllCategories = createAsyncThunk(
  "carManagement/fetchAllCategories",
  async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch("https://api.symboldrive.com/category", requestOptions)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === true) {
          return res;
        }

        if (
          res.status === "error" ||
          res.error.code === "auth/id-token-expired"
        ) {
          refreshToken();
        }
      });
  }
);

const carSlice = createSlice({
  name: "carSlice",
  initialState: initialCarState,
  reducers: {
    setAllCars(state, action) {
      state.allVehicles = action.payload;
    },
    setListedVehicles(state, action) {
      state.listedVehicles = action.payload;
    },
    setDamagedVehicles(state, action) {
      state.damagedVehicles = action.payload;
    },
    setListedCar(state, action) {
      state.selectedListedCar = action.payload;
      state.selectedCarObject = state.allVehicles?.filter(
        (item) => item._id === action.payload
      );
    },
    selectedDamagedCar(state, action) {
      state.selectedDamagedCar = action.payload;
    },
    setSelectedCategory(state, action) {
      state.selectedCategory = action.payload;
    },
  },
  extraReducers: {
    [fetchAllCars.pending]: (state, action) => {
      state.status = "loading";
      state.allVehicles = [];
    },
    [fetchAllCars.fulfilled]: (state, { payload }) => {
      state.status = "succeeded";
      state.allVehicles = payload.cars;

      const listed = payload.cars.map((data, index) => {
        if (!data.isDamaged) {
          return {
            qnr: data.qnr,
            index: index + 1,
            carID: data._id,
            brand: data.brand,
            model: data.model,
            color: data.color,
            seatingCapacity: data.seatCapacity,
            price: "$12/Hour",
            category: data.category,
            fuelLevel: data.fuel_level,
            ignition: data.ignition ? data.ignition : "--",
            mileage: data.mileage,
            bookingStatus: data.bookingStatus,
            status: data.status,
          };
        } else return {};
      });

      const bookedAndDamagedCars = payload.cars.map((data, index) => {
        if (data.isDamaged && data.isBooked) {
          return {
            qnr: data.qnr,
            index: index + 1,
            carID: data._id,
            brand: data.brand,
            model: data.model,
            color: data.color,
            seatingCapacity: data.seatCapacity,
            price: "$12/Hour",
            category: data.category,
            fuelLevel: data.fuel_level,
            ignition: data.ignition ? data.ignition : "--",
            mileage: data.mileage,
            bookingStatus: data.bookingStatus,
            status: data.status,
          };
        } else return {};
      });

      state.listedVehicles = listed.filter(
        (value) => Object.keys(value).length !== 0
      );

      state.bookedAndDamaged = bookedAndDamagedCars.filter(
        (value) => Object.keys(value).length !== 0
      );

      const damaged = payload.cars.map((data, index) => {
        if (data.isDamaged) {
          return {
            index: index + 1,
            carID: data._id,
            brand: data.brand,
            model: data.model,
            color: data.color,
            seatingCapacity: data.seatCapacity,
            price: "$12/Hour",
            date: "12 May 2020,12:35 PM",
            custName: "John Doe",
            fuelLevel: data.fuel_level,
            ignition: data.ignition ? data.ignition : "Unavailable",
            mileage: "--",
            bookingStatus: data.bookingStatus,
            status: data.status,
            qnr: data.qnr,
            category: data.category,
          };
        } else return {};
      });

      state.damagedVehicles = damaged.filter(
        (value) => Object.keys(value).length !== 0
      );
    },
    [fetchAllCars.rejected]: (state, action) => {
      state.status = "failed";
      state.allVehicles = [];
    },
    [fetchAllCategories.pending]: (state, action) => {
      state.categoryStatus = "loading";
      state.allCategories = [];
    },
    [fetchAllCategories.fulfilled]: (state, { payload }) => {
      state.categoryStatus = "succeeded";
      state.allCategories = payload.category;
    },

    [fetchAllCategories.rejected]: (state, action) => {
      state.categoryStatus = "failed";
      state.allCategories = [];
    },
    [fetchCarByid.pending]: (state, action) => {
      state.fetchCarStatus = "loading";
      state.fetchCarByIdObject = [];
    },
    [fetchCarByid.fulfilled]: (state, { payload }) => {
      state.fetchCarStatus = "succeeded";
      state.selectedCarObject = [payload.car];
    },

    [fetchCarByid.rejected]: (state, action) => {
      state.fetchCarStatus = "failed";
      state.fetchCarByIdObject = [];
    },

    [getCarPosition.pending]: (state, action) => {
      state.getCarPositionStatus = "loading";
      state.fetchCarByIdObject = [];
    },
    [getCarPosition.fulfilled]: (state, { payload }) => {
      state.getCarPositionStatus = "succeeded";
      state.carPosition = payload.cars;
    },

    [getCarPosition.rejected]: (state, action) => {
      state.getCarPositionStatus = "failed";
      state.fetchCarByIdObject = [];
    },

    [getFilteredCars.pending]: (state, action) => {
      state.getFilteredCarsStatus = "loading";
      // state.filteredCars = [];
    },
    [getFilteredCars.fulfilled]: (state, { payload }) => {
      state.getFilteredCarsStatus = "succeeded";
      // state.filteredCars = payload.cars;

      const filteredCars1 = payload.cars.map((data, index) => {
        return {
          qnr: data.qnr,
          index: index + 1,
          carID: data._id,
          brand: data.brand,
          model: data.model,
          color: data.color,
          seatingCapacity: data.seatCapacity,
          price: "$12/Hour",
          category: data.category,
          fuelLevel: data.fuel_level,
          ignition: data.ignition ? data.ignition : "--",
          mileage: data.mileage,
          bookingStatus: data.bookingStatus,
          status: data.status,
          isDamaged: data.isDamaged,
        };
      });

      state.filteredCars = filteredCars1.filter(
        (value) => Object.keys(value).length !== 0
      );
    },

    [getFilteredCars.rejected]: (state, action) => {
      state.getFilteredCarsStatus = "failed";
      // state.fetchCarByIdObject = [];
    },
  },
});

export const carActions = carSlice.actions;

export default carSlice;
