import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth";
import carSlice from "./carManagement";
import rideSlice from "./rideManagement";
import userSlice from "./users";
import zoneSlice from "./zoneManagement";
import notificationsSlice from "./notifications";
import paymentSlice from "./payments";
import dashboardSlice from "./dashboard";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    cars: carSlice.reducer,
    users: userSlice.reducer,
    rides: rideSlice.reducer,
    zones: zoneSlice.reducer,
    notifications: notificationsSlice.reducer,
    payments: paymentSlice.reducer,
    dashboard: dashboardSlice.reducer,
  },
});

export default store;
