import React, { useRef } from "react";
import Modal from "react-modal";
import styles from "../Car Management/carManage.module.css";
import { Button } from "@chakra-ui/react";
import imageAlt from "../../assets/images/gallery.svg";
import QRCode from "react-qr-code";

import { useReactToPrint } from "react-to-print";

const ViewQNR = ({ viewDoc, setViewDoc, doc, qnrCode }) => {
  const customStyles = {
    content: {
      top: "90%;",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      height: "fit-content",
    },
  };

  const onImageError = (ev) => {
    ev.target.src = imageAlt;
  };
  const bg = {
    overlay: {
      background: "rgb(48,48,48,0.9)",
    },
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Modal
      className={` ${customStyles}`}
      isOpen={viewDoc}
      shouldCloseOnEsc={"true"}
      onRequestClose={() => setViewDoc(false)}
      style={bg}
    >
      <div className={styles.confirmDeleteModal}>
        <div className={`${styles.viewDoc} ${styles.viewQNR}`}>
          <div className={styles.QNR} ref={componentRef}>
            <QRCode
              style={{ height: "auto", maxWidth: "50%", width: "100%" }}
              value={qnrCode}
            />{" "}
            <div className="qnrDetails">
              <p className="blue">Symbol Drive</p>
            </div>
            {/* <h1>CAR QNR</h1> */}
            <h1 className={styles.qnrNumber}>QNR number : {qnrCode}</h1>
          </div>
          <div className={styles.closeButton}>
            <Button
              width="174.81px"
              height="52.19px"
              background="blue"
              color="white"
              margin="10px"
              //   colorScheme="red"
              size="md"
              variant="solid"
              borderRadius="80px"
              type="submit"
              // isLoading={revokeSuspensionLoading}
              onClick={handlePrint}
            >
              Print
            </Button>
            <Button
              width="174.81px"
              height="52.19px"
              background="red"
              color="white"
              margin="10px"
              //   colorScheme="red"
              size="md"
              variant="solid"
              borderRadius="80px"
              type="submit"
              // isLoading={revokeSuspensionLoading}
              onClick={() => setViewDoc(false)}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ViewQNR;
