import React from "react";
import Modal from "react-modal";
import confirmRemove from "../../assets/icons/close.png";
import styles from "./carManage.module.css";
import * as Yup from "yup";
import { Formik } from "formik";

const CreateSubscriptionModal = ({ openModal, setOpenModal }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      // transform: "translate(-50%, -50%)",
      height: "fit-content",
      backgroundColor: "red",
    },
  };

  const schema = Yup.object().shape({
    subName: Yup.string().required("Subscription Name is required"),
    numOfHours: Yup.string().required("Number of hours is required"),
    numOfMiles: Yup.string().required("Number of Miles is required"),
    price: Yup.string().required("Price is required"),
  });

  const createSubscription = (values) => {
    console.log(values);
  };

  return (
    <Modal
      className={` ${customStyles}`}
      isOpen={openModal}
      shouldCloseOnEsc={true}
      onRequestClose={() => setOpenModal(false)}
    >
      <div className={styles.modalContainer}>
        <div className={styles.createSubscription}>
          <div className={styles.heading}>
            <h1>Add Subscription</h1>
            <img
              src={confirmRemove}
              alt="X"
              onClick={() => setOpenModal(false)}
            />
          </div>
          <div className={styles.form}>
            <Formik
              validationSchema={schema}
              initialValues={
                {
                  // categoryName: selectedCategoryObject[0].title,
                  // description: selectedCategoryObject[0].desc,
                }
              }
              onSubmit={(values) => {
                createSubscription({ values });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <div className={styles.carID}>
                    <label>Subscription name</label>
                    <input
                      type="carID"
                      name="carID"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.carID}
                      id="carID"
                      placeholder="Enter Subscription name"
                    />

                    <p className="red">
                      {errors.carID && touched.carID && errors.carID}
                    </p>
                  </div>
                  <div className={styles.carID}>
                    <label>Enter number of hours</label>
                    <input
                      type="carID"
                      name="carID"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.carID}
                      id="carID"
                      placeholder="Enter number of hours"
                    />

                    <p className="red">
                      {errors.carID && touched.carID && errors.carID}
                    </p>
                  </div>
                  <div className={styles.carID}>
                    <label>Enter number of miles</label>
                    <input
                      type="carID"
                      name="carID"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.carID}
                      id="carID"
                      placeholder="Enter number of miles"
                    />

                    <p className="red">
                      {errors.carID && touched.carID && errors.carID}
                    </p>
                  </div>
                  <div className={styles.carID}>
                    <label>Enter price</label>
                    <input
                      type="carID"
                      name="carID"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.carID}
                      id="carID"
                      placeholder="Enter Price"
                    />

                    <p className="red">
                      {errors.carID && touched.carID && errors.carID}
                    </p>
                  </div>

                  <div className={styles.actionButtons}>
                    <button className="btn-revoke center"> Publish</button>
                    <button className="btn-reset center"> Cancel</button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateSubscriptionModal;
