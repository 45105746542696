import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialZoneState = {
  allZones: [],
  zoneFetchStatus: "idle",
  allParking: [],
  parkingFetchStatus: "idle",
};

export const fetchAllZones = createAsyncThunk(
  "zoneManagement/fetchZones",
  async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch(
      "https://api.symboldrive.com/zone/polygon",
      requestOptions
    ).then((res) => res.json());
  }
);

export const fetchAllParking = createAsyncThunk(
  "zoneManagement/fetchParking",
  async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch(
      "https://api.symboldrive.com/zone/parking",
      requestOptions
    ).then((res) => res.json());
  }
);

const zoneSlice = createSlice({
  name: "zoneSlice",
  initialState: initialZoneState,
  reducers: {
    setAllZones(state, action) {
      state.allZones = action.payload;
    },
  },
  extraReducers: {
    [fetchAllZones.pending]: (state, action) => {
      state.zoneFetchStatus = "loading";
    },
    [fetchAllZones.fulfilled]: (state, action) => {
      state.zoneFetchStatus = "succeeded";
      state.allZones = action.payload.data;
    },
    [fetchAllZones.rejected]: (state, action) => {
      state.zoneFetchStatus = "failed";
    },
    [fetchAllParking.pending]: (state, action) => {
      state.parkingFetchStatus = "loading";
    },
    [fetchAllParking.fulfilled]: (state, action) => {
      state.parkingFetchStatus = "succeeded";
      state.allParking = action.payload.data;
    },
    [fetchAllParking.rejected]: (state, action) => {
      state.parkingFetchStatus = "failed";
    },
  },
});

export const zoneActions = zoneSlice.actions;

export default zoneSlice;
