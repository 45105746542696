import { style } from "glamor";
import React from "react";
import Modal from "react-modal";
import styles from "./carManage.module.css";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

const EditCategory = ({ openEditModal, setOpenEditModal, updateCategory }) => {
  const customStyles = {
    content: {
      top: "50%;",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      height: "fit-content",
    },
  };

  const selectedCatName = useSelector((state) => state.cars.selectedCategory);

  const schema = Yup.object().shape({
    category: Yup.string().required("Category is required"),
  });

  return (
    <Modal
      className={` ${customStyles}`}
      isOpen={openEditModal}
      shouldCloseOnEsc={true}
      onRequestClose={() => setOpenEditModal(false)}
    >
      <div className={styles.confirmDeleteModal}>
        <div className={styles.modal}>
          <h1>Update Category</h1>

          <div className={styles.updateCatBody}>
            <Formik
              validationSchema={schema}
              initialValues={{
                category: selectedCatName.name,
              }}
              onSubmit={(values) => {
                updateCategory(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <div className={styles.carID}>
                    <label></label>
                    <input
                      type="text"
                      name="category"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.category}
                      id="category"
                      placeholder="Enter nategory name"
                    />

                    <p className="red">
                      {errors.category && touched.category && errors.category}
                    </p>
                  </div>
                  <div className={styles.actionButtons2}>
                    <button type="submit" className="btn-revoke center">
                      Confirm
                    </button>

                    <button
                      className="btn-reset center"
                      onClick={() => setOpenEditModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditCategory;
