import React, { useMemo } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import chevRightRed from "../../../assets/icons/chevRightBlue.png";
import chevLeftRed from "../../../assets/icons/chevLeftBlue.png";
import eye from "../../../assets/icons/eye2.png";
import styles from "../style.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { rideActions } from "../../../store/rideManagement";
import { GlobalFilter } from "../filter";

import moment from "moment";
const ReportedRideTable = () => {
  const tableData = useSelector((state) => state.rides.reportedRides2);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const data = useMemo(() => tableData, []);

  const onViewClick = (reportId, bookingId) => {
    dispatch(rideActions.setSelectedRideId(bookingId));
    dispatch(rideActions.setSelectedReportedId(reportId));
    navigate(`/rideManagement/reportedRide/${bookingId}`);
  };

  const COLUMNS_NEWCUSTOMER = [
    {
      Header: () => <p>Date</p>,
      accessor: "createdAt",
      Cell: (tableProps) => (
        <p>{moment(tableProps.row.original.createdAt).format("MM-DD-YYYY")}</p>
      ),
    },
    {
      Header: "Customer ID",
      accessor: "user._id",
      Cell: (tableProps) => (
        // <p>{String(tableProps.row.original.user?._id).slice(-4)}</p>
        <p>
          {tableProps.row.original.user?._id
            ? String(tableProps.row.original.user?._id).slice(-4)
            : "User deleted"}
        </p>
      ),
    },
    {
      Header: "Vehicle name",
      accessor: "bookingId.car.model",
      Cell: (tableProps) => (
        <p>{`${
          tableProps.row.original.bookingId?.car?.brand === undefined
            ? "--"
            : tableProps.row.original.bookingId?.car?.brand
        }  ${
          tableProps.row.original.bookingId?.car?.model === undefined
            ? "--"
            : tableProps.row.original.bookingId?.car?.model
        }  ${
          tableProps.row.original.bookingId?.car?.color === undefined
            ? "--"
            : tableProps.row.original.bookingId?.car?.color
        }`}</p>
      ),
    },

    {
      Header: "Reported by",
      accessor: "cancelledBy",
      Cell: (tableProps) => (
        <p>
          {tableProps.row.original.user?.name
            ? tableProps.row.original.user?.name
            : "User deleted"}
        </p>
      ),
    },
    // {
    //   Header: "Last location",
    //   accessor: "dropLocation.address",
    //   Cell: (tableProps) => (
    //     <p>{String(tableProps.row.original.bookingId?.car?.coordinates[0])}</p>
    //   ),
    // },

    {
      Header: "Reported for",
      accessor: "desc",
      Cell: (tableProps) => (
        <p>
          {tableProps.row.original.desc
            ? tableProps.row.original.desc.substring(0, 40) + "..."
            : "Reason was not provided"}
        </p>
      ),
    },

    {
      Header: "Actions",
      accessor: "actions",
      Cell: (tableProps) => (
        <div className="actionsCategories">
          <button
            className="btn-edit"
            onClick={() =>
              onViewClick(
                tableProps.row.original._id,
                tableProps.row.original.bookingId.id
              )
            }
          >
            <img src={eye} alt="View" />
          </button>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS_NEWCUSTOMER, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  return (
    <div className="containerSubRight">
      <div className="heading-container">
        <h1>Reported Rides</h1>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>

      {!tableData.length ? (
        <h1>No rides at the monment.</h1>
      ) : (
        <>
          <div className="tableCont">
            <table {...getTableProps()}>
              <thead className="tableHeader">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="table-row" {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <>
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}{" "}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="tableFooters">
            {/* <div className="pageInfo">
            {"\u00a0"} Go to page number : {"\u00a0"}
            <input
              type="number"
              default={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
            />
          </div> */}
            <div className="tableButtons">
              {/* <button
              className="jumpButton"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {" "}
              Go to first page
            </button> */}
              <button
                className="tableNav"
                disabled={!canPreviousPage}
                onClick={() => previousPage()}
              >
                <img src={chevLeftRed} />
              </button>
              <p className="pageNumber">
                {" "}
                {"\u00a0"}
                Showing page {pageIndex + 1}
                {"\u00a0"}of {pageOptions.length}
              </p>
              <button
                className="tableNav"
                disabled={!canNextPage}
                onClick={() => nextPage()}
              >
                <img src={chevRightRed} />
              </button>
              {/* <button
              className="jumpButton"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              Go to last page
            </button> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ReportedRideTable;
