import React, { useMemo } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import chevRightRed from "../../assets/icons/chevRightBlue.png";
import chevLeftRed from "../../assets/icons/chevLeftBlue.png";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { notificationsActions } from "../../store/notifications";
import { useNavigate } from "react-router-dom";
import eye from "../../assets/icons/eye2.png";

const NotificationsTable = ({}) => {
  const categories = useSelector(
    (state) => state.notifications.allNotifications
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useMemo(() => categories, []);

  const notificationClick = () => {};

  const onViewClick = (id) => {
    dispatch(notificationsActions.setSelectedNotificationId(id));
    navigate(`/notification/:${id}`);
  };

  const COLUMNS_NEWCUSTOMER = [
    {
      Header: "Notification Id",
      accessor: "_id",
      Cell: (tableProps) => (
        <p>{String(tableProps.row.original._id).slice(-4)}</p>
      ),
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Type",
      accessor: "notificationType",
    },
    {
      Header: "Body",
      accessor: "body",
      Cell: (tableProps) => (
        <p>{tableProps.row.original.body.substring(0, 55) + "..."}</p>
      ),
    },

    {
      Header: "Actions",
      accessor: "actions",
      Cell: (tableProps) => (
        <div className="actionsCategories">
          <button
            className="btn-edit"
            onClick={() => onViewClick(tableProps.row.original._id)}
          >
            <img src={eye} alt="View" />
          </button>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS_NEWCUSTOMER, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  return (
    <div className="containerSubRight ">
      <div className="heading-container">
        <h1> Notifications </h1>
        {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
      </div>

      {categories.length === 0 ? (
        <h1>No notifications at the moment </h1>
      ) : (
        <>
          <div className="tableCont">
            <table {...getTableProps()}>
              <thead className="tableHeader">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="table-row" {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <>
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}{" "}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="tableFooters">
            {/* <div className="pageInfo">
            {"\u00a0"} Go to page number : {"\u00a0"}
            <input
              type="number"
              default={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
            />
          </div> */}
            <div className="tableButtons">
              {/* <button
              className="jumpButton"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {" "}
              Go to first page
            </button> */}
              <button
                className="tableNav"
                disabled={!canPreviousPage}
                onClick={() => previousPage()}
              >
                <img src={chevLeftRed} />
              </button>
              <p className="pageNumber">
                {" "}
                {"\u00a0"}
                Showing page {pageIndex + 1}
                {"\u00a0"}of {pageOptions.length}
              </p>
              <button
                className="tableNav"
                disabled={!canNextPage}
                onClick={() => nextPage()}
              >
                <img src={chevRightRed} />
              </button>
              {/* <button
              className="jumpButton"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              Go to last page
            </button> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NotificationsTable;
