import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllPayments } from "../../store/payments";
import CompletedPaymentTable from "../../components/Payments/CompletedPaymentsTable";
import animate from "../../assets/images/animate.gif";

const CompletedPayment = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllPayments());
  }, [dispatch]);

  const status = useSelector((state) => state.payments.fetchAllPaymentResponse);

  return (
    <>
      {status === "loading" ? (
        <div className="loader-container">
          <img alt="loading" src={animate} />
        </div>
      ) : (
        <CompletedPaymentTable />
      )}
    </>
  );
};

export default CompletedPayment;
