import React from "react";
import { fetchAllParking, fetchAllZones } from "../../store/zoneManagement";
import { useDispatch, useSelector } from "react-redux";
import animate from "../../assets/images/animate.gif";
import MapComponent3 from "./ParkingArea";
import drawingTool from "../../assets/images/Screenshot 2023-12-06 134204.png";
import moment from "moment";
import deleteIcon from "../../assets/icons/cancelCross.png";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "glamor";
import { refreshToken } from "../../functions";

const ParkingContainer = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchAllParking());
    dispatch(fetchAllZones());
  }, [dispatch]);

  const parkingsZones = useSelector((state) => state.zones.allParking);
  // const geofencing = useSelector((state) => state.zones.allParking);

  const geofencing = useSelector((state) => state.zones.allZones);

  const status = useSelector((state) => state.zones.parkingFetchStatus);

  const notifySuccess = () =>
    toast.success("Parking zone updated", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const onDeleteZone = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`https://api.symboldrive.com/zone/parking/${id}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.status === true) {
          notifySuccess();
          dispatch(fetchAllParking());
          dispatch(fetchAllZones());
        }

        if (res.status === "error") {
          refreshToken();
        }
      });
  };

  return (
    <div className="containerSubRight">
      <div className="heading-container">
        <h1>Parking</h1>
      </div>
      <ToastContainer />

      <>
        {status === "loading" ? (
          <div className="loader-container">
            <img alt="loading" src={animate} />
          </div>
        ) : (
          <MapComponent3 zones={parkingsZones} geoFence={geofencing} />
        )}
      </>

      <p className="d-flex-center">
        To create a new parking area click on drawing tool{" "}
        <img src={drawingTool} alt="drawing tool" />
        and click on save once you complete drawing.{" "}
      </p>

      <div className="heading-container">
        <h1>Parking List</h1>
      </div>
      <p>
        In case you want to update a existing zone, please delete it and create
        a new one. Exisitng zones cannot be editted.
      </p>

      {parkingsZones.map((item, index) => (
        <div className="parkingListing">
          {index + 1} : Created at{" "}
          {moment(item.createdAt).format("MM-DD-YYYY: h:mm:a")}
          <button className="" onClick={() => onDeleteZone(item._id)}>
            <img src={deleteIcon} />
          </button>
        </div>
      ))}
    </div>
  );
};

export default ParkingContainer;
