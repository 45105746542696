import React from "react";
import search from "../../../assets/icons/search.png";

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <>
      <div className="searchContainerQues categories">
        <div className="magnify">
          <img src={search} alt="img" />
        </div>
        <input
          value={filter || ""}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Search by name"
        />
      </div>
    </>
  );
};
