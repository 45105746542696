import React, { useEffect, useState } from "react";
import chev from "../../assets/icons/chevBlue.png";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./rideManagement.module.css";
import ConfirmEndRide from "./ConfirmEndRide";
import {
  fetchAllRides,
  fetchRideById,
  fetchTransactionDetails,
  rideActions,
} from "../../store/rideManagement";
import { css } from "glamor";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import userAlt from "../../assets/images/user.svg";
import { fetchBeforeImages } from "../../store/rideManagement";
import ViewDocModal from "../User Management/ViewDocModal.js";
import { BarLoader } from "react-spinners";
import {
  convertMinToHours,
  convertSecToMins,
  dateConversion,
  dateConversion2,
  refreshToken,
} from "../../functions";
import RideInfoMap from "./RideInfoMap";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Lightbox from "yet-another-react-lightbox";

const CompletedRideDetails = () => {
  const selectedRide = useSelector((state) => state.rides.selectedRideObject);
  const selectedRideId = useSelector((state) => state.rides.selectedRideId);
  const selectedRideBeforeImages = useSelector(
    (state) => state.rides.rideImagesBefore
  );

  const id = useParams();

  const path = selectedRide[0]?.path?.map((item) => {
    return {
      lat: Number(item[0]),
      lng: Number(item[1]),
    };
  });

  const rideId = id.rideId;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedRide.length) {
      dispatch(fetchRideById(rideId));
    }
  }, [dispatch]);

  const beforeImagesStatus = useSelector(
    (state) => state.rides.fetchBeforeImagesStatus
  );

  const navigate = useNavigate();

  const [openEndRide, setOpenEndRide] = React.useState(false);
  const [endLoad, setEndLoad] = React.useState(false);

  function capitalizeName(name) {
    return name?.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  const transactionDetailsAll = useSelector(
    (state) => state.rides.rideTransactionDetails
  );

  const transactionDetails = useSelector(
    (state) => state.rides.rideTransactionDetails[0]
  );

  useEffect(() => {
    if (selectedRide[0]?.user) {
      dispatch(fetchBeforeImages(selectedRide[0]?.user?._id));
    }
    // dispatch(fetchBeforeImages(selectedRide[0]?.user?._id));
    dispatch(fetchTransactionDetails(rideId));
  }, [selectedRide[0]]);

  const notifySuccess = () =>
    toast.success("Ride updated", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const currentImagesObect = selectedRideBeforeImages?.filter(
    (item) => item?.inspections?.booking === rideId
  );

  const endRide = () => {
    setEndLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      dropLocation: {
        type: "Point",
        coordinates: [80.9229409563887, 26.842241990735474],
        address: "Lucknow",
      },
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `https://api.symboldrive.com/booking/${selectedRideId}/end-ride`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (
          res.status === "false" &&
          res.error.code === "auth/id-token-expired"
        ) {
          setEndLoad(false);
          refreshToken();
        }
        if (res.status === true) {
          notifySuccess();
          dispatch(fetchAllRides());
          setTimeout(() => {
            navigate("/rideManagement/ongoingride");
          }, 500);
          setEndLoad(false);
        }
      });
  };

  const onImageError = (ev) => {
    ev.target.src = userAlt;
  };

  const [viewDoc, setViewDoc] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState("");

  const dataBlock = (
    <>
      <div className={styles.onGoingRideContainer}>
        <RideInfoMap path={path} />
        <div className={styles.otherInfo}>
          <div className={styles.cuInfo}>
            <div className="border-bottom">
              <h2>Trip Details</h2>
              <div className={styles.infoRow}>
                <p>Booking date and time:</p>
                {/* <p>22 May 2022, 12:35PM</p> */}
                {/* <p>
                    {moment(selectedRide[0]?.createdAt).format(
                      "MM-DD-YYYY h:mm a "
                    )}
                  </p> */}

                <p>{dateConversion2(selectedRide[0]?.createdAt)}</p>
              </div>
              <div className={styles.infoRow}>
                <p>Booking ID:</p>
                <p>{selectedRide[0]?._id}</p>
              </div>
              <div className={styles.infoRow}>
                <p>Pick up date and time</p>
                {/* <p>Tesla Model Y (Green)</p> */}
                {/* <p>
                    {moment(selectedRide[0]?.pickupTime).format(
                      "MM-DD-YYYY h:mm a "
                    )}
                  </p> */}
                <>
                  {selectedRide[0]?.pickupTime ? (
                    <p>{dateConversion2(selectedRide[0]?.pickupTime)}</p>
                  ) : (
                    <p className="text-red">Pick up time unavailable.</p>
                  )}
                </>{" "}
              </div>
              <div className={styles.infoRow}>
                <p>Pick up location</p>
                <p>
                  {selectedRide[0]?.pickupLocation?.address
                    ? selectedRide[0]?.pickupLocation?.address
                    : "--"}
                </p>
              </div>

              <div className={styles.infoRow}>
                <p>Drop time and date:</p>
                <>
                  {selectedRide[0]?.dropTime ? (
                    <p>{dateConversion2(selectedRide[0]?.dropTime)}</p>
                  ) : (
                    <p className="text-red">Drop time unavailable.</p>
                  )}
                </>{" "}
              </div>
              <div className={styles.infoRow}>
                <p>Drop location </p>
                {/* <p>2972 Westheimer Rd. Santa Ana, Illinois 85486 </p> */}
                <p>
                  {" "}
                  {selectedRide[0]?.dropLocation?.address
                    ? selectedRide[0]?.dropLocation?.address
                    : "--"}
                </p>
              </div>
              <div className={styles.infoRow}>
                <p>Waiting time </p>
                {/* <p>2972 Westheimer Rd. Santa Ana, Illinois 85486 </p> */}

                <p>{convertSecToMins(selectedRide[0]?.waitingTime)}</p>
              </div>

              <div className={styles.infoRow}>
                <p>Additional waiting time </p>
                <p>
                  {convertSecToMins(selectedRide[0]?.additionalWaitingTime)}
                </p>

                {/* <p>{selectedRide[0]?.} min</p> */}
              </div>

              <div className={styles.infoRow}>
                <p>Trip time </p>

                <p>{convertSecToMins(selectedRide[0]?.tripTime)}</p>
              </div>
            </div>

            <h2 className={styles.uploadedHeading}>Uploaded photos</h2>

            <div className={styles.uploadedVideo}>
              <div>
                <p className={styles.dark}>Before</p>
                <div className={styles.images}>
                  {beforeImagesStatus === "loading" ? (
                    <div className="margin-top">
                      <BarLoader color="#031569" />
                    </div>
                  ) : (
                    <>
                      {currentImagesObect[0]?.inspections?.carImagesBeforeRide?.map(
                        (item) => (
                          <div className="imageAndName">
                            {" "}
                            <img
                              onClick={() => {
                                setViewDoc(true);
                                setSelectedDoc(item.image);
                              }}
                              className={styles.imagesInsp}
                              src={item.image}
                            />
                            <p>{item.respectiveSide}</p>
                          </div>
                        )
                      )}
                    </>
                  )}

                  {beforeImagesStatus === "succeeded" &&
                    !currentImagesObect[0]?.inspections?.carImagesBeforeRide
                      .length && <p>No images.</p>}
                </div>
                <p className={styles.faded}>{selectedRide[0]?.pickUpTime}</p>
              </div>

              <div className={styles.afterImages}>
                <p className={styles.dark}>After</p>
                <div className={styles.images}>
                  {beforeImagesStatus === "loading" ? (
                    <div className="margin-top">
                      <BarLoader color="#031569" />
                    </div>
                  ) : (
                    <>
                      {currentImagesObect[0]?.inspections?.carImagesAfterRide?.map(
                        (item) => (
                          <div className="imageAndName">
                            {" "}
                            <img
                              onClick={() => {
                                setViewDoc(true);
                                setSelectedDoc(item.image);
                              }}
                              className={styles.imagesInsp}
                              src={item.image}
                            />
                            <p>{item.respectiveSide}</p>
                          </div>
                        )
                      )}
                    </>
                  )}

                  {beforeImagesStatus === "succeeded" &&
                    !currentImagesObect[0]?.inspections?.carImagesAfterRide
                      .length && <p>No images.</p>}
                </div>
                <p className={styles.faded}>{selectedRide[0]?.pickUpTime}</p>
              </div>
            </div>
          </div>

          <div className={styles.cuInfo}>
            <div className="border-bottom">
              <h2>Vehicle Information</h2>
              <div className={styles.infoRow}>
                <p>Vehicle</p>
                {/* <p>Tesla Model Y (Green)</p> */}
                <p>
                  {`${
                    selectedRide[0]?.car?.brand === undefined
                      ? "--"
                      : selectedRide[0]?.car?.brand
                  } 
                  ${
                    selectedRide[0]?.car?.model === undefined
                      ? "--"
                      : selectedRide[0]?.car?.model
                  } 
                  (${
                    selectedRide[0]?.car?.color === undefined
                      ? "--"
                      : selectedRide[0]?.car?.color
                  })`}
                </p>
              </div>
              <div className={styles.infoRow}>
                <p>Vehicle ID:</p>
                <p>
                  {!selectedRide[0]?.car?._id
                    ? "--"
                    : selectedRide[0]?.car?._id}
                </p>
              </div>

              <div className={styles.infoRow}>
                <p>Miles covered:</p>
                <p>{transactionDetails?.bookingPaymentObject?.mile} miles</p>
              </div>
            </div>

            {/* <div className={styles.infoRow}>
              <p>Pickup Time:</p>
              <p>
                {moment(selectedRide[0]?.pickupTime).format(
                  "MM-DD-YYYY h:mm a "
                )}
              </p>
            </div> */}
            {/* <div className={styles.infoRow}>
              <p>Pickup Location:</p>
              <p>{selectedRide[0]?.pickupLocation?.address}</p>
            </div> */}
            {!selectedRide[0]?.user && (
              <div className={styles.infoRow}>
                <p>User not found</p>
              </div>
            )}
            {selectedRide[0]?.user && (
              <div className="border-bottom">
                <h2 className={styles.tripDetails}>Customer Information</h2>

                <div className={styles.infoRow}>
                  <p>Customer ID:</p>
                  <p>{selectedRide[0]?.user?._id}</p>
                </div>

                <div className={styles.infoRow}>
                  <p>Customer Name:</p>
                  <p>{capitalizeName(selectedRide[0]?.user?.name)}</p>
                </div>

                <div className={styles.infoRow}>
                  <p>Mobile number:</p>
                  <p>{selectedRide[0]?.user?.phone}</p>
                </div>

                <div className={styles.infoRow}>
                  <p>Email ID:</p>
                  <p>{selectedRide[0]?.user?.email}</p>
                </div>

                <div className={styles.infoRow}>
                  <p>Address:</p>
                  <p>{selectedRide[0]?.user?.address}</p>
                </div>
              </div>
            )}

            <div className="border-bottom">
              <h2 className={styles.transactions}>Transaction Detail</h2>

              <div className={styles.infoRow}>
                <p>Stripe Transaction ID</p>
                <p>{transactionDetails?.stripePaymentDetail?.id}</p>
              </div>

              {transactionDetailsAll[1] && (
                <div className={styles.infoRow}>
                  <p>Basic payment</p>
                  <p>$ {transactionDetailsAll[1]?.amount}</p>
                </div>
              )}

              <div className={styles.infoRow}>
                <p>Booking Plan</p>
                <p>{transactionDetails?.bookingPlan}</p>
              </div>
              {/* <div className={styles.infoRow}>
                <p>Initial Payment</p>
                <p>--</p>
              </div> */}
              <div className={styles.infoRow}>
                <p>Total amount paid by customer </p>

                {transactionDetailsAll[1] ? (
                  <p>
                    ${" "}
                    {transactionDetailsAll[1]?.amount +
                      transactionDetails?.amount}{" "}
                  </p>
                ) : (
                  <p>$ {transactionDetails?.amount}</p>
                )}
                {/* <p>$ {transactionDetails?.amount}</p> */}
              </div>
              <div className={styles.infoRow}>
                <p>Payment Mode</p>
                <p>{transactionDetails?.stripePaymentDetail?.payment_method}</p>
              </div>
              {/* <div className={styles.infoRow}>
                <p>Initial Payment made by customer</p>
                <p>--</p>
              </div> */}
            </div>

            <h2 className={styles.transactions}>Final Invoice</h2>

            <div className={styles.totalFareBox}>
              <div className={styles.infoRow}>
                <p>Range </p>
                <p>{transactionDetails?.bookingPaymentObject?.mile} km</p>
              </div>
              <div className={styles.infoRowBlueBackground}>
                <p>Total fare </p>
                <p className={styles.green}>$ {transactionDetails?.amount}</p>
              </div>
              <div className={styles.infoRow}>
                <p>Tax</p>
                <p className={styles.green}>
                  {transactionDetails?.bookingPaymentObject?.tax}
                </p>
              </div>
              <div className={styles.infoRow}>
                <p>Discount</p>
                <p className={styles.green}>--</p>
              </div>
              <div className={styles.infoRowBlueBackground}>
                <p className={styles.dark}>Total </p>
                <p className={styles.greenLarge}>
                  {transactionDetailsAll[1] ? (
                    <p>
                      ${" "}
                      {transactionDetailsAll[1]?.amount +
                        transactionDetails?.amount}{" "}
                    </p>
                  ) : (
                    <p>$ {transactionDetails?.amount}</p>
                  )}
                  {/* $ {transactionDetails?.amount} */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const getRideFetchStatus = useSelector(
    (state) => state.rides.fetchRideByIdStatus
  );

  const renderDetails = () => {
    if (selectedRide.length > 0) {
      return dataBlock;
    }

    if (!selectedRide.length) {
      if (getRideFetchStatus === "loading") {
        return <BarLoader color="#031569" />;
      }

      if (getRideFetchStatus === "succeeded") {
        return dataBlock;
      }
    }
  };

  const slides = [
    {
      src: selectedDoc,
    },
  ];

  return (
    <div className="containerSubRight">
      <ConfirmEndRide
        openModal={openEndRide}
        setOpenModal={setOpenEndRide}
        endRide={endRide}
        endRideLoading={endLoad}
      />
      <ToastContainer />
      {/* 
      <ViewDocModal
        viewDoc={viewDoc}
        setViewDoc={setViewDoc}
        doc={selectedDoc}
      /> */}

      <Lightbox
        open={viewDoc}
        close={() => setViewDoc(false)}
        slides={slides}
        plugins={[Zoom]}
        src={selectedDoc}
      />
      <div className="subRight-boundary">
        <div className="heading-container border-bottom2 ">
          <div className="chevAndTitle">
            <img className="chev" src={chev} onClick={() => navigate(-1)} />
            <h1>Completed Ride </h1>
          </div>
          <div className="listed-buttons">
            {/* <button
              className="btn-suspend"
              onClick={() => setOpenEndRide(true)}
            >
              <img onError={onImageError} src={markDamage} alt="Add" />
              End Ride
            </button> */}
          </div>
        </div>
        {renderDetails()}
      </div>
    </div>
  );
};

export default CompletedRideDetails;
