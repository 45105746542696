import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

const initialAuthState = {
  isAuthenticated: false,
  firebaseToken: "",
  sessionToken: "",
  userId: "",
  loginError: false,
  user: null,
  tokenError: false,
  isLoading: true,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    setFirebaseToken(state, action) {
      state.firebaseToken = action.payload;
      Cookies.set("token", action.payload);
    },
    setTokenError(state, action) {
      state.tokenError = action.payload;
    },
    logout(state) {
      state.isAuthenticated = false;
    },
    setSessionToken(state, action) {
      state.sessionToken = action.payload;
      Cookies.set("token", action.payload);
    },
    setUserId(state, action) {
      state.userId = action.payload;
    },
    setLoginError(state, action) {
      state.loginError = action.payload;
    },
    setAuthentication(state, action) {
      state.isAuthenticated = action.payload;
    },

    loginUser: (state, action) => {
      state.user = action.payload;
    },
    logOut: (state) => {
      state.user = null;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});
export const authActions = authSlice.actions;

export const getSessionToken = (id) => {
  return async (dispatch) => {
    const data = await axios.post(
      `https://api.symboldrive.com/user/generate-token/${id}`
    );

    dispatch(authActions.setSessionToken(data.data.token));
  };
};

export default authSlice;
