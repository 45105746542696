import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import styles from "./carManage.module.css";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "glamor";
import CategoryTable from "../../components/Car management/Categories/table";
import { fetchAllCategories } from "../../store/carManagement";
import ConfirmDelete from "./ConfirmDelete";
import EditCategory from "./EditCategory";
import { Button } from "@chakra-ui/react";
import animate from "../../assets/images/animate.gif";
import { refreshToken } from "../../functions";
import Cookies from "js-cookie";

const AddCategory = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [addCatLoading, setAddCatLoading] = useState(false);
  const [updateCatLoading, setUpdateCatLoading] = useState(false);
  const [deleteCatLoading, setDeleteCatLoading] = useState(false);

  const selectedCategoryToEdit = useSelector(
    (state) => state.cars.selectedCategory
  );

  const [categoryToDelete, setCategoryToDelete] = useState("");
  const schema = Yup.object().shape({
    carCategory: Yup.string().required("Car Category is required"),
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllCategories());
  }, [dispatch]);

  const deleteCategory = () => {
    setDeleteCatLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
      headers: myHeaders,
    };

    fetch(
      `https://api.symboldrive.com/category/${categoryToDelete}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (
          res.status === "false" &&
          res.error.code === "auth/id-token-expired"
        ) {
          setDeleteCatLoading(false);
          refreshToken();
        }
        if (res.status === true) {
          setDeleteCatLoading(false);
          notifySuccess2();
          dispatch(fetchAllCategories());
          setOpenModal(false);
        }
      });
  };

  const updateCategory = (values) => {
    setUpdateCatLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: values.category,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `https://api.symboldrive.com/category/${selectedCategoryToEdit._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (
          res.status === "false" &&
          res.error.code === "auth/id-token-expired"
        ) {
          setUpdateCatLoading(false);
          refreshToken();
        }
        if (res.status === true) {
          setUpdateCatLoading(false);

          notifySuccess3();
          dispatch(fetchAllCategories());
          setOpenEditModal(false);
        }
      });
  };

  const status = useSelector((state) => state.cars.categoryStatus);

  const notifySuccess2 = () =>
    toast.success("Category Deleted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const notifySuccess3 = () =>
    toast.success("Category Updated", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const notifySuccess = () =>
    toast.success("Category Added", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const notifyError = () => {
    toast.error("Session expired, Please log in again to continue", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#ed5565 !important",
      }),
    });
  };

  const sessionToken = Cookies.get("token");

  const AddCategory = (values) => {
    setAddCatLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("token", sessionToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: values.carCategory,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.symboldrive.com/category", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (
          res.status === "false" &&
          res.error.code === "auth/id-token-expired"
        ) {
          setAddCatLoading(false);
          refreshToken();
        }
        if (res.status === true) {
          setAddCatLoading(false);
          notifySuccess();
          dispatch(fetchAllCategories());
        }
      });
  };
  return (
    <div className="containerSubRight">
      <ToastContainer />
      <ConfirmDelete
        openModal={openModal}
        setOpenModal={setOpenModal}
        deleteCategory={deleteCategory}
        deleteCatLoading={deleteCatLoading}
      />
      <EditCategory
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        updateCategory={updateCategory}
        updateCatLoading={updateCatLoading}
      />
      <div className={styles.addCategoryBody}>
        <div className="heading-container">
          <h1>Add Category</h1>
        </div>

        <div className={styles.addCategory}>
          <Formik
            validationSchema={schema}
            initialValues={{
              carCategory: "",
            }}
            onSubmit={(values) => {
              AddCategory(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form
                className={styles.addCategoryForm}
                noValidate
                onSubmit={handleSubmit}
              >
                <div className={styles.carID}>
                  <label>Enter car category name</label>
                  <input
                    type="text"
                    name="carCategory"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.carCategory}
                    id="carCategory"
                    placeholder="Enter category name"
                    className="categoryInput"
                  />

                  <p className="red">
                    {errors.carCategory &&
                      touched.carCategory &&
                      errors.carCategory}
                  </p>
                </div>
                <div className={styles.actionButtons2}>
                  {/* <button
                    className="btn-revoke margin-top center"
                    style={{ marginTop: "60" }}
                  >
                    Add1
                  </button> */}

                  <Button
                    width="174.81px"
                    height="42.19px"
                    background="#031569"
                    colorScheme="blue"
                    size="md"
                    variant="solid"
                    borderRadius="80px"
                    type="submit"
                    isLoading={addCatLoading}
                  >
                    Add
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>

      <div className={styles.tableContainer}>
        {status === "loading" ? (
          <div className="loader-container">
            <img alt="loading" src={animate} />
          </div>
        ) : (
          <CategoryTable
            openDeleteModal={openModal}
            setOpenDeleteModal={setOpenModal}
            categoryToDelete={categoryToDelete}
            setCategoryToDelete={setCategoryToDelete}
            openEditModel={openEditModal}
            setOpenEditModel={setOpenEditModal}
          />
        )}
      </div>
    </div>
  );
};

export default AddCategory;
