import React, { useMemo } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import chevRightRed from "../../../assets/icons/chevRightBlue.png";
import chevLeftRed from "../../../assets/icons/chevLeftBlue.png";
import Edit from "../../../assets/icons/Edit.png";

import suspend from "../../../assets/icons/cancelCross.png";
import { useSelector, useDispatch } from "react-redux";
import { GlobalFilter } from "./filter";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "glamor";
import { carActions } from "../../../store/carManagement";

const CategoryTable = ({
  setOpenDeleteModal,
  setCategoryToDelete,
  setOpenEditModel,
}) => {
  const dispatch = useDispatch();

  const categories = useSelector((state) => state.cars.allCategories);

  const data = useMemo(() => categories, []);

  const onSuspendClick = (id) => {
    setOpenDeleteModal(true);
    setCategoryToDelete(id);
  };

  const onEditClick = (id) => {
    setOpenEditModel(true);
    dispatch(carActions.setSelectedCategory(id));
  };

  const COLUMNS_NEWCUSTOMER = [
    {
      Header: "Category Name",
      accessor: "name",
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: (tableProps) => (
        <div className="actionsCategories">
          <button
            className="btn-edit"
            onClick={() => onEditClick(tableProps.row.original)}
          >
            <img
              style={{ width: "20px", height: "20px" }}
              src={Edit}
              alt="View"
            />
          </button>

          <button
            className="btn-edit2"
            onClick={() => onSuspendClick(tableProps.row.original._id)}
          >
            <img className="suspend12" src={suspend} alt="Suspend" />
          </button>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS_NEWCUSTOMER, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,

    prepareRow,
    state,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  return (
    <div className="containerSubRight ">
      <div className="heading-container">
        <h1> Categories </h1>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>

      <>
        <div className="tableCont">
          <table {...getTableProps()}>
            <thead className="tableHeader">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr className="table-row" {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <>
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}{" "}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="tableFooters">
          <div className="tableButtons">
            <button
              className="tableNav"
              disabled={!canPreviousPage}
              onClick={() => previousPage()}
            >
              <img src={chevLeftRed} />
            </button>
            <p className="pageNumber">
              {" "}
              {"\u00a0"}
              Showing page {pageIndex + 1}
              {"\u00a0"}of {pageOptions.length}
            </p>
            <button
              className="tableNav"
              disabled={!canNextPage}
              onClick={() => nextPage()}
            >
              <img src={chevRightRed} />
            </button>
          </div>
        </div>
      </>
    </div>
  );
};

export default CategoryTable;
