import React from "react";
import MapComponent from "./GeoFencingListing";
import { fetchAllZones } from "../../store/zoneManagement";
import { useDispatch, useSelector } from "react-redux";
import animate from "../../assets/images/animate.gif";

const GeoFencingContainer = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchAllZones());
  }, [dispatch]);

  const zones = useSelector((state) => state.zones.allZones);
  const status = useSelector((state) => state.zones.zoneFetchStatus);

  return (
    <div className="containerSubRight">
      <div className="heading-container">
        <h1>Zone Management</h1>
      </div>

      <p>
        To edit a zone, please click on one of the polygon dots, drag it to
        desired place and click on save changes button
      </p>

      <>
        {status === "loading" ? (
          <div className="loader-container">
            <img alt="loading" src={animate} />
          </div>
        ) : (
          <MapComponent zones={zones} />
        )}
      </>
    </div>
  );
};

export default GeoFencingContainer;
