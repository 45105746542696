import React, { useRef, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import startingPointIcon from "../../assets/images/startingPoint.png";
import carIcon from "../../assets/images/carIc.png";

const libraries = ["places", "drawing"];
const RideInfoMap = ({ path }) => {
  const mapRef = useRef();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAra6kB0Dv1xOQzyf91uk2vY4X1ejhQiAs",
    libraries,
  });

  const defaultCenter = !path?.length
    ? { lat: 38.57373003141664, lng: -121.48549312332109 }
    : path[1];

  const [center, setCenter] = useState(defaultCenter);

  const containerStyle = {
    width: "100%",
    height: "470px",
  };

  const onLoadMap = (map) => {
    mapRef.current = map;
    return <p>"Loading, Please wait"</p>;
  };

  const onLoad = (polyline) => {
    // console.log("polyline: ", polyline);
  };

  const options = {
    strokeColor: "#031569",
    strokeOpacity: 0.8,
    strokeWeight: 5,
    fillColor: "#031569",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  return isLoaded ? (
    <>
      <div className="map-container" style={{ position: "relative" }}>
        <GoogleMap
          zoom={10}
          center={center}
          onLoad={onLoadMap}
          mapContainerStyle={containerStyle}
          // onTilesLoaded={() => setCenter(null)}
        >
          {path !== undefined && (
            <>
              {" "}
              <Marker
                icon={startingPointIcon}
                position={path[path?.length - 1]}
              />
              <Marker icon={startingPointIcon} position={path[0]} />{" "}
            </>
          )}

          <Polyline onLoad={onLoad} path={path} options={options} />
        </GoogleMap>
        {!path?.length && <p className="red">Ride coordinates unavailable.</p>}
      </div>
    </>
  ) : null;
};

export default RideInfoMap;
