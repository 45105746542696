import React, { useEffect } from "react";
import styles from "../Car Management/carManage.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import chev from "../../assets/icons/chevBlue.png";

import moment from "moment";

const NotificationIndividual = () => {
  const navigate = useNavigate();

  const selectedNotificationObject = useSelector(
    (state) => state.notifications.selectedNotificationObject
  );

  console.log(selectedNotificationObject, "obj");

  useEffect(() => {
    if (!selectedNotificationObject.length) {
      navigate("/notifications");
    }
  }, []);

  const dataBlock = (
    <div className={styles.listedVehicles}>
      <div className={styles.otherCarDetails}>
        <div className={styles.column1}>
          <div className={styles.detail}>
            <p className="blackText"></p>
          </div>

          <div className={styles.detail}>
            <p className="grayText">Created at</p>
            <p className="blackText">
              {moment(selectedNotificationObject[0]?.createdAt).format(
                "MM-DD-YYYY hh:mm a"
              )}
            </p>
          </div>

          <div className={styles.detail}>
            <p className="grayText">Title </p>
            <p className="blackText">{selectedNotificationObject[0]?.title}</p>
          </div>

          <div className={styles.detail}>
            <p className="grayText">Body </p>
            <p className="blackText">{selectedNotificationObject[0]?.body}</p>
          </div>
          <div className={styles.detail}>
            <p className="grayText">Notification Type</p>
            <p className="blackText">
              {selectedNotificationObject[0]?.notificationType}
            </p>
          </div>

          <div className={styles.detail}>
            <p className="grayText">Concerned booking Id </p>
            <p className="blackText">
              {selectedNotificationObject[0]?.notificationTypeId}
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="containerSubRight">
      <div className="subRight-boundary">
        <div className="heading-container border-bottom2">
          <div className="back-button">
            <img onClick={() => navigate(-1)} src={chev} />
            <h1>Notification Details</h1>
          </div>

          <div className="listed-buttons">
            {/* <button className="btn-revoke" onClick={() => setOpenModal(true)}>
            <img src={add} alt="Add" />
            Create Subscription
          </button> */}
            {/* <button
              className="btn-suspend"
            //   onClick={() => setOpenMarkDamage(true)}
            >
              <img src={markDamage} alt="Add" />
              Mark Damage
            </button> */}
          </div>
        </div>
        <div className={styles.listedContainer}>
          {/* <div className={styles.accordion}>
          <h2 onClick={() => setSelectedTab("Listed")}>Listed Vehicles</h2>
          <h2 onClick={() => setSelectedTab("Subscription")}>Subscription</h2>
        </div> */}
          {dataBlock}
        </div>
      </div>
    </div>
  );
};

export default NotificationIndividual;
