import React, { useRef, useState } from "react";
import {
  Autocomplete,
  DrawingManager,
  GoogleMap,
  Polygon,
  useJsApiLoader,
} from "@react-google-maps/api";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { fetchAllZones } from "../../store/zoneManagement";
import { refreshToken } from "../../functions";

const libraries = ["places", "drawing"];

const MapComponent = ({ zones }) => {
  const mapRef = useRef();
  const polygonRefs = useRef([]);
  const activePolygonIndex = useRef();
  const autocompleteRef = useRef();
  const drawingManagerRef = useRef();

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const dispatch = useDispatch();

  const zonesArray = zones[0]?.polygons.coordinates[0];

  const formattedCoords = zonesArray?.map((item) => ({
    lat: item[0],
    lng: item[1],
  }));

  const zoneId = zones[0]?._id;

  const [polygons, setPolygons] = useState([[formattedCoords]]);

  const defaultCenter = {
    lat: 38.60667517697476,
    lng: -121.49648953838184,
  };
  const [center, setCenter] = useState(defaultCenter);

  const containerStyle = {
    width: "100%",
    height: "600px",
  };

  const autocompleteStyle = {
    boxSizing: "border-box",
    border: "1px solid transparent",
    width: "240px",
    height: "38px",
    padding: "0 12px",
    borderRadius: "3px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
    fontSize: "14px",
    outline: "none",
    textOverflow: "ellipses",
    position: "absolute",
    right: "8%",
    top: "11px",
    marginLeft: "-120px",
  };

  const deleteIconStyle = {
    cursor: "pointer",
    backgroundColor: "red",
    padding: "7px 10px",
    color: "white",
    marginTop: "5px",
    position: "absolute",
    top: "08px",
    left: "18%",
    zIndex: 99999,
    borderRadius: "10px",
  };

  const deleteIconStyle2 = {
    cursor: "pointer",
    backgroundColor: "green",
    padding: "7px 10px",
    color: "white",
    marginTop: "5px",
    position: "absolute",
    top: "08px",
    left: "26%",
    zIndex: 99999,
    borderRadius: "10px",
  };

  const [saveLoad, setSaveLoad] = useState("Save changes");

  const polygonOptions = {
    fillOpacity: 0.3,
    fillColor: "#3FFF00",
    strokeColor: "#008000",
    strokeWeight: 2,
    draggable: false,
    editable: true,
  };

  const drawingManagerOptions = {
    polygonOptions: polygonOptions,
    drawingControl: true,
    drawingControlOptions: {
      position: window.google?.maps?.ControlPosition?.TOP_CENTER,
      drawingModes: [window.google?.maps?.drawing?.OverlayType?.POLYGON],
    },
  };

  const onLoadMap = (map) => {
    mapRef.current = map;
    return <p>"Loading, Please wait"</p>;
  };

  const onLoadPolygon = (polygon, index) => {
    polygonRefs.current[index] = polygon;
  };

  const onClickPolygon = (index) => {
    activePolygonIndex.current = index;
  };

  const onLoadAutocomplete = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = () => {
    const { geometry } = autocompleteRef.current.getPlace();
    const bounds = new window.google.maps.LatLngBounds();
    if (geometry.viewport) {
      bounds.union(geometry.viewport);
    } else {
      bounds.extend(geometry.location);
    }
    mapRef.current.fitBounds(bounds);
  };

  const onLoadDrawingManager = (drawingManager) => {
    drawingManagerRef.current = drawingManager;
  };

  const onOverlayComplete = ($overlayEvent) => {
    drawingManagerRef.current.setDrawingMode(null);
    if ($overlayEvent.type === window.google.maps.drawing.OverlayType.POLYGON) {
      const newPolygon = $overlayEvent.overlay
        .getPath()
        .getArray()
        .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

      // start and end point should be same for valid geojson
      const startPoint = newPolygon[0];
      newPolygon.push(startPoint);
      $overlayEvent.overlay?.setMap(null);
      setPolygons([...polygons, newPolygon]);
    }
  };

  const onDeleteDrawing = () => {
    const filtered = polygons.filter(
      (polygon, index) => index !== activePolygonIndex.current
    );
    setPolygons(filtered);
  };

  const [enableEdit, setEnableEdit] = useState(false);

  const onEditPolygon = (index) => {
    setEnableEdit(true);
    const polygonRef = polygonRefs.current[index];
    if (polygonRef) {
      const coordinates = polygonRef
        .getPath()
        .getArray()
        .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

      const allPolygons = [...polygons];
      allPolygons[index] = coordinates;
      setPolygons(allPolygons);
    }
  };

  const onSaveCoordinatess = () => {
    setSaveLoad("Saving..");
    const filtered = polygons.filter((item) => item.length !== 0);

    // var apiData;

    const apiData = filtered[0]?.map((item) => [item.lat, item.lng]);

    const raw = JSON.stringify({
      polygons: {
        type: "MultiPolygon",
        coordinates: apiData,
      },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", Cookies.get("token"));

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(
      `https://api.symboldrive.com/zone/polygon/${zoneId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setSaveLoad("Save successful");
          setInterval(() => {
            setEnableEdit(false);
          }, 2000);
          dispatch(fetchAllZones());
        }

        if (result.status === "error") {
          refreshToken();
        }
      });
  };

  return isLoaded ? (
    <>
      <div className="map-container" style={{ position: "relative" }}>
        {drawingManagerRef.current && (
          <>
            {enableEdit && (
              <>
                <button
                  onClick={onDeleteDrawing}
                  title="Delete shape"
                  style={deleteIconStyle}
                >
                  Delete
                </button>

                <button
                  onClick={onSaveCoordinatess}
                  title="Delete shape"
                  style={deleteIconStyle2}
                >
                  {saveLoad}
                </button>
              </>
            )}
          </>
        )}

        <GoogleMap
          zoom={10}
          center={center}
          onLoad={onLoadMap}
          mapContainerStyle={containerStyle}
          // onTilesLoaded={() => setCenter(null)}
        >
          <DrawingManager
            onLoad={onLoadDrawingManager}
            onOverlayComplete={onOverlayComplete}
            options={drawingManagerOptions}
          />
          {polygons.map((iterator, index) => (
            <>
              <Polygon
                key={index}
                onLoad={(event) => onLoadPolygon(event, index)}
                onMouseDown={() => onClickPolygon(index)}
                onMouseUp={() => onEditPolygon(index)}
                onDragEnd={() => onEditPolygon(index)}
                options={polygonOptions}
                paths={iterator}
                // draggable
                editable
              />
            </>
          ))}
          <Autocomplete
            onLoad={onLoadAutocomplete}
            onPlaceChanged={onPlaceChanged}
          >
            <input
              type="text"
              placeholder="Search Location"
              style={autocompleteStyle}
            />
          </Autocomplete>
        </GoogleMap>
      </div>
    </>
  ) : null;
};

export default MapComponent;
