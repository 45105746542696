import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCars, getFilteredCars } from "../../store/carManagement";
import { toast } from "react-toastify";
import { css } from "glamor";
import HardDeleteVehicle from "./HardDeleteVehicle";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import animate from "../../assets/images/animate.gif";
import { refreshToken } from "../../functions";
import { useParams } from "react-router-dom";
import FilteredTable from "../../components/Car management/Listed Vehicles/FilteredTable";

const FilteredCars = () => {
  const dispatch = useDispatch();
  const [id, setId] = useState("");

  const filterParams = useParams();
  const filter = String(filterParams.filter).slice(1);

  useEffect(() => {
    dispatch(getFilteredCars(filter));
  }, [dispatch]);

  const notifySuccess = () =>
    toast.success("Vehicle deleted.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const [openModal, setOpenModal] = useState(false);
  const [deleteUserLoading, setDeleteUserLoading] = useState(false);

  const onDeleteVehicle = () => {
    var route = "/carManagement/listedVehicles";

    setDeleteUserLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      isDeleted: true,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(`https://api.symboldrive.com/car/${id}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (
          res.status === "false" &&
          res.error.code === "auth/id-token-expired"
        ) {
          setDeleteUserLoading(false);
          refreshToken(route);
        }
        if (res.status === true) {
          setDeleteUserLoading(false);
          notifySuccess();
          dispatch(fetchAllCars());
          setOpenModal(false);
        }
      });
  };
  const status = useSelector((state) => state.cars.getFilteredCarsStatus);

  return (
    <>
      {status === "loading" ? (
        <div className="containerSubRight">
          <div className="loader-container">
            <img alt="loading" src={animate} />
          </div>
        </div>
      ) : (
        <>
          <HardDeleteVehicle
            openModal={openModal}
            setOpenModal={setOpenModal}
            onDeleteVehicle={onDeleteVehicle}
            deleteLoading={deleteUserLoading}
          />

          <FilteredTable
            setOpenModal={setOpenModal}
            setId={setId}
            filter={filter}
          />
        </>
      )}
    </>
  );
};

export default FilteredCars;
