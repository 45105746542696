import Cookies from "js-cookie";
import axios from "axios";
import moment from "moment";

const generateToken = async () => {
  const data2 = await axios.post(
    `https://api.symboldrive.com/user/generate-token/${Cookies.get("uid")}`
  );
  Cookies.set("token", data2.data.token);
  window.location.reload(false);
};

export const refreshToken = async () => {
  generateToken();
};

export const dateConversion2 = (dateRaw) => {
  const dateToTime = (date) =>
    date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });

  const dateString = dateRaw;
  const userOffset = new Date().getTimezoneOffset() * 60 * 1000;

  const a = new Date();
  const localDate = new Date(dateString);
  const utcDate = new Date(localDate.getTime() + userOffset);

  return `${moment(dateRaw).format("MM-DD-YYYY")} ${dateToTime(
    utcDate
  )} (${dateToTime(localDate)} in local time)`;
};

export const dateConversion = (dateRaw) => {
  const dateToTime = (date) =>
    date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });

  const dateString = dateRaw;
  const userOffset = new Date().getTimezoneOffset() * 60 * 1000;

  const a = new Date();
  const localDate = new Date(dateString);
  const utcDate = new Date(localDate.getTime() + userOffset);

  return `${dateToTime(utcDate)} (${dateToTime(localDate)} in local time)`;
};

export const convertMinToHours = (totalMinutes) => {
  if (totalMinutes > 0) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours}h ${minutes}m`;
  }

  if (totalMinutes === 0) {
    return "--";
  }
};

export const convertSecToMins = (totalSeconds) => {
  if (totalSeconds > 0) {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds - minutes * 60;

    return `${minutes}m ${seconds}s`;
  }

  if (totalSeconds === 0) {
    return "--";
  }
};
