import React, { useMemo, useState } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import chevRightRed from "../../../assets/icons/chevRightBlue.png";
import chevLeftRed from "../../../assets/icons/chevLeftBlue.png";
import eye from "../../../assets/icons/eye2.png";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { rideActions } from "../../../store/rideManagement";
import Cookies from "js-cookie";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
// import { GlobalFilter } from "../filter";

const UserRides = () => {
  const tableData2 = useSelector((state) => state.rides.userRides);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const data = useMemo(() => tableData2, []);

  const [loadingView, setLoadingView] = useState(false);

  const onViewClick = async (id) => {
    setLoadingView(true);

    dispatch(rideActions.setSelectedRideId(id));
    var myHeaders = new Headers();
    myHeaders.append("token", Cookies.get("token"));
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `https://api.symboldrive.com/booking/${id}`,
        requestOptions
      );
      const data = await response.json();
      dispatch(rideActions.setSelectedRideObject([data.data]));
      setTimeout(() => {
        navigate(`/rideManagement/rideDetails/${id}`);
      }, 500);
      setLoadingView(false);
    } catch (error) {}
  };

  function capitalizeName(name) {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  const getClass = (status) => {
    if (status === "ongoing") {
      return <p className="onGoingRide tab">On going</p>;
    }

    if (status === "inprogress") {
      return <p className="inprogress tab">Processing</p>;
    }

    if (status === "completed") {
      return <p className="completedRide tab">Completed</p>;
    }

    if (status === "cancelled") {
      return <p className="cancelledRide tab">Cancelled</p>;
    }
  };

  const COLUMNS_NEWCUSTOMER = [
    {
      Header: () => <p>Date</p>,
      accessor: "createdAt",
      Cell: (tableProps) => (
        <p>{moment(tableProps.row.original.createdAt).format("MM-DD-YYYY")}</p>
      ),
    },
    {
      Header: "Booking ID ",
      accessor: "id",
      Cell: (tableProps) => (
        <p>{String(tableProps.row.original._id).slice(-4)}</p>
      ),
    },
    {
      Header: "Vehicle name",
      accessor: "model",
      Cell: (tableProps) => (
        <p>{`${
          tableProps.row.original.car?.brand === undefined
            ? "--"
            : tableProps.row.original.car?.brand
        }  ${
          tableProps.row.original.car?.model === undefined
            ? "--"
            : tableProps.row.original.car?.model
        }  ${
          tableProps.row.original.car?.color === undefined
            ? "--"
            : tableProps.row.original.car?.color
        }`}</p>
      ),
    },

    {
      Header: "Trip price",
      accessor: "totalBill",
      Cell: (tableProps) => <p> $ {tableProps.row.original.totalAmount}</p>,
    },

    // {
    //   Header: "Customer name",
    //   accessor: "user.name",
    //   Cell: (tableProps) => (
    //     <p>{capitalizeName(tableProps.row.original.user.name)}</p>
    //   ),
    // },
    // {
    //   Header: "Pickup location",
    //   accessor: "pickupLocation.address",
    //   Cell: (tableProps) => (
    //     <p>
    //       {String(tableProps.row.original.pickupLocation.address).slice(0, 20)}
    //       ...
    //     </p>
    //   ),
    // },
    {
      Header: "Status",
      accessor: "status",
      Cell: (tableProps) => getClass(tableProps.row.original.status),
    },
    // {
    //   Header: "Current location",
    //   accessor: "currentLocation",
    //   Cell: (tableProps) => (
    //     <div
    //       onClick={() => onViewClick(tableProps.row.original.id)}
    //       className={styles.trackContainer}
    //     >
    //       <img src={track} alt="Track" />
    //       <p>Track</p>
    //     </div>
    //   ),
    // },

    {
      Header: "Actions",
      accessor: "actions",
      Cell: (tableProps) => (
        <div className="actionsCategories">
          <button
            className="btn-edit"
            onClick={() => onViewClick(tableProps.row.original._id)}
          >
            {!loadingView ? <img src={eye} alt="View" /> : "Loading"}
          </button>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS_NEWCUSTOMER, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  return (
    <div className="containerSubRight">
      <div className="heading-container">
        {/* <h1>Booked rides</h1> */}
        {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
      </div>
      {!tableData2.length ? (
        <h1 className="emptyState">No previous rides</h1>
      ) : (
        <>
          <div className="tableCont">
            <table {...getTableProps()}>
              <thead className="tableHeader">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="table-row" {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <>
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}{" "}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="tableFooters">
            {/* <div className="pageInfo">
            {"\u00a0"} Go to page number : {"\u00a0"}
            <input
              type="number"
              default={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
            />
          </div> */}
            <div className="tableButtons">
              {/* <button
              className="jumpButton"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {" "}
              Go to first page
            </button> */}
              <button
                className="tableNav"
                disabled={!canPreviousPage}
                onClick={() => previousPage()}
              >
                <img src={chevLeftRed} />
              </button>
              <p className="pageNumber">
                {" "}
                {"\u00a0"}
                Showing page {pageIndex + 1}
                {"\u00a0"}of {pageOptions.length}
              </p>
              <button
                className="tableNav"
                disabled={!canNextPage}
                onClick={() => nextPage()}
              >
                <img src={chevRightRed} />
              </button>
              {/* <button
              className="jumpButton"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              Go to last page
            </button> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserRides;
