import React, { useEffect, useState } from "react";
import styles from "./newCustomer.module.css";
import chev from "../../assets/icons/chevBlue.png";
import approve from "../../assets/icons/approve.png";
import reject from "../../assets/icons/reject.png";
import RejectModal from "./RejectModal";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchAllUsers, fetchUserById } from "../../store/users";
import { css } from "glamor";
import moment from "moment";
import ApproveModal from "./ConfirmApprove";
import userAlt from "../../assets/images/user.svg";
import imageAlt from "../../assets/images/gallery.svg";
import { refreshToken } from "../../functions";
import { BarLoader } from "react-spinners";
import Cookies from "js-cookie";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

const NewCustomer = () => {
  const [openRejectModal, setOpenRejectModal] = useState(false);

  const id = useParams();
  const userID = String(id.userId).slice(1);

  const selectedUserObject = useSelector(
    (state) => state.users.selectedUserObject
  );

  const status = useSelector((state) => state.users.fetchByIdStatus);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const token = Cookies.get("token");
  // const [deleteUserLoading, setDeleteUserLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [viewDoc, setViewDoc] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState("");

  const slides = [
    {
      src: selectedDoc,
    },
  ];

  useEffect(() => {
    if (!selectedUserObject.length) {
      dispatch(fetchUserById(userID));
    }
  }, [dispatch, userID, selectedUserObject.length]);

  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const onRejectClick = (values) => {
    // setDeleteUserLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("token", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      reason: values.reasonForReject,
      // isSuspended: true,
      // isApproved: false,
      // isRejected: true,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    console.log(values);

    fetch(`https://api.symboldrive.com/user/${userID}/reject`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (
          res.status === "error" ||
          res.message === "Please pass firebase auth token"
        ) {
          // setDeleteUserLoading(false);
          refreshToken();
        }
        if (res.status === true) {
          notifySuccess("User rejected");
          dispatch(fetchAllUsers());
          setTimeout(() => {
            navigate(-1);
          }, 500);
        }
      });
  };

  const onApproveClick = () => {
    setApproveLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("token", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      isApproved: "true",
      isSuspended: "false",
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`https://api.symboldrive.com/user/${userID}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (
          res.status === "error" &&
          res.error.code === "auth/id-token-expired"
        ) {
          setApproveLoading(false);
          refreshToken();
        }
        if (res.status === true) {
          setApproveLoading(false);
          notifySuccess("User Approved");
          dispatch(fetchAllUsers());
          setTimeout(() => {
            navigate("/users/activeUsers");
          }, 500);
        }

        // if (res.status === "error") {
        //   setApproveLoading(false);
        //   notifyError();
        // }
      });
  };

  const onBlackClick = () => {
    navigate("/users/newUsers");
  };

  function capitalizeName(name) {
    return name?.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  const onImageError = (ev) => {
    ev.target.src = userAlt;
  };

  const onImageError2 = (ev) => {
    ev.target.src = imageAlt;
  };

  const openDoc = (doc) => {
    setSelectedDoc(doc);
    setViewDoc(true);
  };

  const dataBlock = (
    <>
      <div className={styles.infoAndDocuments}>
        <div className={styles.cuInfo}>
          <h1>User Information</h1>
          <div className={styles.row}>
            <h3>User Name:</h3>
            <h3>{capitalizeName(selectedUserObject[0]?.name)}</h3>
          </div>
          <div className={styles.row}>
            <h3>Date of birth:</h3>
            <h3>{moment(selectedUserObject[0]?.dob).format("MM-DD-YYYY")}</h3>
          </div>
          <div className={styles.row}>
            <h3>Mobile number:</h3>
            <h3>{selectedUserObject[0]?.phone}</h3>
          </div>
          <div className={styles.row}>
            <h3>Email ID:</h3>
            <h3>{selectedUserObject[0]?.email}</h3>
          </div>
          <div className={styles.row}>
            <h3>Address:</h3>
            <h3>
              {selectedUserObject[0]?.address
                ? selectedUserObject[0]?.address
                : "N/A"}
            </h3>
          </div>
        </div>

        <div className={styles.docInfo}>
          <h1>Document Information</h1>
          <div className={styles.row}>
            <h3>Driving Licence No.:</h3>
            <h3>{selectedUserObject[0]?.dl.licenceNumber}</h3>
          </div>
          <div className={styles.row}>
            <h3>Expiry Date:</h3>
            <h3>
              {moment(selectedUserObject[0]?.dl.validTill).format("MM-DD-YYYY")}
            </h3>
          </div>
          <div className={styles.row}>
            <h3>Mobile number:</h3>
            <h3>{selectedUserObject[0]?.phone}</h3>
          </div>
          <div className={styles.row}>
            <h3>Insurance Number:</h3>
            <h3>
              {selectedUserObject[0]?.insurance?.insuranceNumber
                ? selectedUserObject[0]?.insurance?.insuranceNumber
                : "N/A"}
            </h3>
          </div>
          <div className={styles.row}>
            <h3>Valid Upto:</h3>
            <h3>
              {moment(selectedUserObject[0]?.insurance.validTills).format(
                "MM-DD-YYYY"
              )}
            </h3>
          </div>
        </div>
      </div>

      <div className={styles.docImages}>
        <h1>Uploaded Documents</h1>
        <div className={styles.downloadContainers}>
          <div className={styles.licence}>
            <h3>Drivers Licence</h3>
            <img
              onError={onImageError2}
              src={selectedUserObject[0]?.dl?.image}
              alt="Licence"
              onClick={() => openDoc(selectedUserObject[0]?.dl?.image)}
            />
            {/* <div
              className={styles.download}
              onClick={() => onDownloadClick("licence")}
            >
              <h3>Download</h3>
              <img src={download} alt="download" />
            </div> */}
          </div>

          <div className={styles.insurance}>
            <h3>Insurance Document</h3>
            {selectedUserObject[0]?.insurance?.image && (
              <img
                onError={onImageError2}
                src={selectedUserObject[0]?.insurance?.image}
                alt="Licence"
                onClick={() => openDoc(selectedUserObject[0]?.insurance?.image)}
              />
            )}

            {!selectedUserObject[0]?.insurance?.image && (
              <>
                <img alt="N/A" onError={onImageError2} src={imageAlt} />
                Not uploaded
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );

  const renderDetails = () => {
    if (selectedUserObject.length > 0) {
      return dataBlock;
    }

    if (!selectedUserObject.length) {
      if (status === "loading") {
        return (
          <div className="refreshLoader">
            <BarLoader color="#031569" />
          </div>
        );
      }

      if (status === "succeeded") {
        return dataBlock;
      }
    }
  };

  return (
    <div className="containerSubRight">
      <div className={styles.newCustomer}>
        <ToastContainer />

        <RejectModal
          openRejectModal={openRejectModal}
          setOpenRejectModal={setOpenRejectModal}
          onRejectClick={onRejectClick}
        />

        <Lightbox
          open={viewDoc}
          close={() => setViewDoc(false)}
          slides={slides}
          plugins={[Zoom]}
        />

        <ApproveModal
          openApproveModal={openApproveModal}
          setOpenApproveModal={setOpenApproveModal}
          onApproveClick={onApproveClick}
          approveLoading={approveLoading}
        />
        <div className={styles.heading}>
          <img alt="N/A" onClick={() => onBlackClick()} src={chev} />
          <h2>New User</h2>
        </div>
        <img
          alt="N/A"
          onError={onImageError}
          className="cuImage"
          src={selectedUserObject[0]?.image}
        />

        {renderDetails()}

        <div className="actionButtons margint-20">
          <button
            className="btn-approve"
            onClick={() => setOpenApproveModal(true)}
          >
            <img alt="N/A" src={approve} />
            Approve
          </button>
          <button
            className="btn-reject"
            onClick={() => setOpenRejectModal(true)}
          >
            <img alt="reject" src={reject} />
            Reject
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewCustomer;
